.ip-competition {
	width: 100%;
	background: #ffffff;
	min-height: 88vh;
}
.ip-competition-empty {
	width: 100%;
	min-height: 55px;
	background: #ffffff;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ip-competition__table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	background-color: #fff;
}
.ip-competition__table .td {
	position: relative;
	padding: 0 5px;
}
.ip-competition__table .td--border:before {
	width: 1px;
	height: 28px;
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: rgba(194, 211, 212, 0.5);
}
.ip-competition__table .tr {
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ip-competition__table .tr--title:after,
.ip-competition__table .tr--subtitle:after {
	content: '';
	display: table-cell;
	width: 65px;
}
.ip-competition__table .tr--title .td,
.ip-competition__table .tr--subtitle .td {
	height: 38px;
	width: 20%;
}
.ip-competition__table .tr--title .td:first-child,
.ip-competition__table .tr--subtitle .td:first-child {
	width: auto;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.ip-competition__table .tr--title {
	background-color: #f6f8ff;
}
.ip-competition__table .tr--title .td {
	height: 38px;
}
.ip-competition__table .tr--subtitle .td {
	height: 28px;
}
.ip-competition__table .tr--body .td {
	height: 75px;
	/*width: 20%; */
}
.ip-competition__table .tr--body .td:nth-child(2) {
	width: auto;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.ip-competition__table .tr--body .td:first-child,
.ip-competition__table .tr--body .td:last-child {
	width: 75px;
}
.tournament--action-cell {
	width: 100px !important;
}
.ip-competition__header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 10px;
	height: 38px;
	background-color: #f6f8ff;
}
.ip-competition__body {
	background-color: #fff;
}
.ip-competition__tab {
	width: 100%;
	height: 38px;
	padding: 0 10px;
	background-color: #fff;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ip-competition__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	position: relative;
	margin-right: 20px;
}
.ip-competition__link:after {
	width: 0;
	height: 4px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.ip-competition__link:hover,
.ip-competition__link--active {
	color: #6c90fc;
}
.ip-competition__link:hover:after,
.ip-competition__link--active:after {
	width: 100%;
}
.ip-competition__info {
	width: 40%;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin: 0 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border: 1px solid #e7e7e7;
	-webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
}
.ip-competition__info:first-child {
	margin-left: 0;
}
.ip-competition__info:last-child {
	margin-right: 0;
}
.ip-competition__info .ip-competition__coff {
	-webkit-border-radius: 0;
	border-radius: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 0;
}
.ip-competition__info .ip-competition__coff:first-child {
	border-left: 0;
}
.ip-competition__goal {
	-webkit-border-radius: 1px;
	border-radius: 1px;
	border: 1px solid #f1cb62;
	-webkit-box-shadow: 0px 2px 4px rgba(241, 203, 98, 0.4);
	box-shadow: 0px 2px 4px rgba(241, 203, 98, 0.4);
	background-color: rgba(241, 203, 98, 0.5);
	padding: 0 5px;
	margin-left: 10px;
	margin-right: auto;
}
.ip-competition__action--right {
	margin-right: 0;
	margin-left: auto;
}
.ip-competition__action--left {
	margin-left: 0;
	margin-right: auto;
}
.ip-competition__action {
	margin: 0px 5px;
}
.ip-competition__favourite {
	color: rgba(151, 151, 151, 0.3);
	font-size: 14px;
	cursor: pointer;
	text-align: center;
}
.ip-competition__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
}
.ip-competition__favourite:hover:after,
.ip-competition__favourite--active:after {
	content: '\f005';
	font-weight: 900;
	color: #f1cb62;
	font-family: 'Font Awesome 5 Pro';
}
.ip-competition__coff {
  display: flex;
	align-items: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	min-height: 28px;
}
.ip-competition__coff > * {
	white-space: nowrap;
	margin: 0 3px;
	line-height: 1;
	/* -webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end; */
}
.ip-competition__coff--big {
	-webkit-box-flex: 2;
	-webkit-flex: 2;
	-ms-flex: 2;
	flex: 2;
}
.nowrap {
	white-space: nowrap;
	max-width: 100%;
}
.ip-competition__coff--big span.font--default-alpha-05 {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: break-word;
	white-space: nowrap;
}
.ip-competition__coff.active {
	background-color: #6c90fc !important;
}
.ip-competition__coff.active * {
	color: #ffffff !important;
}
.ip-competition__coff--outline {
	border: 1px solid rgba(108, 144, 252, 0.55) !important;
	background-color: #f6f8ff !important;
}
.ip-competition__coff--disabled {
	position: relative;
	pointer-events: none;
}
.ip-competition__coff--disabled i {
	font-size: 12px;
	color: rgba(35, 39, 55, 0.5);
}
.ip-competition__dropdown {
	position: relative;
	z-index: 1;
}
.ip-competition__dropdown .dropdown__toggle {
	display: inline-block;
	margin-top: 4px;
}
.ip-competition__dropdown .dropdown__menu {
	background-color: #f6f8ff;
}
.ip-competition__dropdown .dropdown__item {
	border-bottom: 1px solid #e7e7e7;
}
.ip-competition__dropdown .dropdown__item:hover,
.ip-competition__dropdown .dropdown__item--active {
	background-color: #dde4ff;
}
.ip-competition__drop {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 10px;
	position: relative;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
/*.ip-competition__drop:hover .dropdown, .ip-competition__drop--active .dropdown {*/
/*    display: block;*/
/*}*/
.ip-competition__drop .dropdown {
	position: absolute;
	right: 0;
	top: 100%;
	min-width: 200px;
	background: #fff;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	padding: 5px 20px 10px 5px;
	z-index: 3;
}
.ip-competition__drop .dropdown__item {
	padding: 0;
	margin: 5px 0;
	border: 1px solid #e7e7e7;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.01);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.01);
	-webkit-border-radius: 2px;
	border-radius: 2px;
}
.ip-competition__drop .dropdown .c-icon {
	position: absolute;
	right: 8px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
}
.ip-competition__drop .dropdown .btn--coff {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-top: 0;
	border-bottom: 0;
	border-left: 0;
}
.ip-competition__drop .dropdown .btn--coff:last-child {
	border-right: 0;
}
.ip-competition .text-ellipsis {
	max-width: 100%;
}
.event-content {
	min-height: 400px;
}
.c-icon.not-active {
	filter: /* grayscale(70%) */ invert(50%);
}
.flex-1-1 {
	flex: 1 1;
}
.l-h-32 {
	line-height: 52px;
}
.h-search .h-search__icon:after {
	position: relative;
	right: 36px;
}
.h-search--leagues {
	display: inline-flex;
	height: 32px;
	padding: 0 8px;
	transition: all 0.5s;
	position: relative;
	z-index: 10;
	width: 21rem;
}
.h-search__form {
	height: 32px;
	position: relative;
	background-color: #383a4a;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #1b1d2c;
	width: 150%;
}
.h-search__icon {
	color: rgba(255, 255, 255, 0.5);
	font-size: 12px;
	position: absolute;
	left: 10px;
}
.fal.fa-search:after {
	content: '\f002';
}
.ip-classification-header .h-search__field {
	padding-left: 30px;
}
.h-search__field--leagues {
	display: block;
	width: 100%;
	height: 100%;
	color: #fff !important;
	font-size: 13px;
	padding-left: 32px !important;
	padding-right: 32px !important;
}
.h-search__reset {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	color: rgba(255, 255, 255, 0.5);
	display: none;
}
