.h-nav {
	margin-bottom: 1px;
}
.h-nav__up {
	padding: 0 15px;
	height: 60px;
	background-color: #1a1e30;
}
.h-nav__down {
	padding: 0 15px;
	height: 40px;
	background-color: #232737;
}
.search-hover:hover {
	color: #fff;
}
.h-a-menu {
	position: absolute;
	top: 100%;
	right: 0;
	left: auto;
	width: 230px;
	background-color: #f6f8ff;
	z-index: 9;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	display: none;
}
.h-a-menu--active {
	display: block;
}
.h-a-menu__head {
	width: 100%;
	height: 108px;
	background-color: #fff;
	margin-bottom: 4px;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.rotating {
	-webkit-animation: rotating 1s linear infinite;
	-moz-animation: rotating 1s linear infinite;
	-ms-animation: rotating 1s linear infinite;
	-o-animation: rotating 1s linear infinite;
	animation: rotating 1s linear infinite;
}
.h-a-menu__info .fas:after {
	cursor: pointer;
	color: rgba(35, 39, 55, 0.5);
}
.h-a-menu__info .fas:hover:after,
.h-a-menu__info .fas--active:hover:after {
	color: #232737;
}
.h-a-menu__info .inline-flex div,
.h-a-menu__info .inline-flex p {
	margin-top: 10px;
}
.h-a-menu__info .inline-flex div {
	margin-right: 10px;
}
.h-a-menu__tab {
	width: 100%;
	height: 32px;
	background-color: #fff;
}
.h-a-menu__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.h-a-menu__link:after {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.h-a-menu__link:hover:after,
.h-a-menu__link--active:after {
	width: 100%;
}
.h-a-menu__info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: flexs-start;
	-webkit-align-items: flexs-start;
	-ms-flex-align: flexs-start;
	align-items: flexs-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 5px 10px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.h-a-menu__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 34px;
	cursor: pointer;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	padding: 5px 10px;
	background-color: #fff;
}
.h-a-menu__item--active > .font,
.h-a-menu__item:hover > .font {
	color: #6c90fc;
}
.h-a-menu__mail .icon {
	font-size: 12px;
	color: rgba(56, 58, 74, 0.8);
}
.h-a-menu__mail .icon:hover,
.h-a-menu__mail .icon.active {
	color: #6c90fc;
}
.h-a-menu__body {
	display: none;
}
.h-a-menu__body--active {
	display: block;
}

.h-menu__item {
	color: #9a9b9f;
	margin: 0 10px;
	letter-spacing: 0.2px;
	text-transform: uppercase;
}
.h-menu__item--active,
.h-menu__item:hover {
	color: #fff !important;
}

.h-menu__dropdown {
	position: relative;
	z-index: 9;
}

.h-logo {
	width: 170px;
	height: 24px;
	background-image: url('../img/logo.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.h-submenu__link {
	color: #9a9b9f;
}
.h-submenu__link:hover,
.h-submenu__link--active {
	color: #fff;
}

.h-submenu__item {
	margin-right: 17px;
}
.h-submenu__item:last-child {
	margin-right: 0;
}

.h-submenu__lang {
	cursor: pointer;
}
.h-submenu__lang:hover,
.h-submenu__lang--active {
	background-color: #191c27;
}
.h-submenu__lang:hover .font,
.h-submenu__lang--active .font {
	color: #fff;
}
.h-submenu__lang img {
	width: 18px;
	height: 15px;
}

.h-submenu__separate {
	width: 1px;
	height: 24px;
	background-color: #383a4a;
}

.h-submenu__text {
	color: #9a9b9f;
}

.h-submenu__dropdown {
	position: relative;
	z-index: 0;
	min-width: 100px;
}
.h-submenu__dropdown .dropdown__menu {
	right: 0;
	left: auto;
	min-width: 100px;
}
.h-submenu .lang {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: -2px;
}
.h-submenu .lang__img {
	display: none;
	margin-bottom: 2px;
}
.h-submenu .lang--active .lang__img {
	display: block;
}

.h-search {
	height: 24px;
	/*padding: 0 8px;*/
	margin: 2px 10px 0 auto;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	position: relative;
	z-index: 9;
}
.h-search--active {
	background-color: #2f3346;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.h-search--active .h-search__icon {
	display: none;
}
.h-search--active .h-search__field {
	display: block !important;
}
.h-search--active .h-search__text {
	color: #f1cb62;
}
.h-search--active .h-search__dropdown {
	display: block;
}
.h-search__icon {
	color: #f1cb62;
	font-size: 12px;
	margin-right: 8px;
	margin-bottom: 2px;
}
.field.h-search__field {
	display: none;
	padding: 0 0 0 8px;
	padding-left: 16px;
	color: #fff;
	margin-right: 8px;
	border-right: 1px solid #0c1022;
	font-size: 13px;
}
.field.h-search__field::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.field.h-search__field::-moz-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.field.h-search__field:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.h-search__dropdown {
	display: none;
	position: absolute;
	height: auto;
	max-height: 400px;
	left: 0;
	right: 0;
	top: 31px;
	background-color: #2f3346;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	/*z-index: 2;*/
}
.h-search__subtitle,
.h-search__item {
	border-bottom: 1px solid rgba(12, 16, 34, 0.5);
}
.h-search__subtitle {
	padding: 10px 15px;
}
.h-search__item {
	padding: 10px 15px;
}
.h-search__hr {
	width: 1px;
	height: 12px;
	border: 0;
	margin: 0 10px;
	background-color: #9a9b9f;
}

.h-account__wrap {
	position: relative;
	margin-left: 10px;
}

.h-account__btn {
	min-width: 80px;
	padding: 5px 25px;
}
.h-account__btn span {
	font-weight: 600 !important;
}
.h-account__mail {
	color: rgba(255, 255, 255, 0.5);
	position: relative;
}
.h-account__mail:hover,
.h-account__mail--active {
	color: #f1cb62;
}
.h-account__mail--active span {
	width: 5px;
	height: 5px;
	background-color: #fc3164;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	right: -1px;
	top: -1px;
}

.h-account__nickname:hover {
	color: #fff;
}

.h-account__popup {
	display: none;
	position: absolute;
	top: 20px;
	right: 0;
	left: auto;
	width: 365px;
	padding: 10px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #fff;
	z-index: 10;
	overflow: hidden;
}
.h-account__popup--active {
	display: block;
}

.h-account__block > .flex:first-child {
	margin-bottom: 2px;
}

.h-account__block > .flex:last-child {
	margin-top: 2px;
}

.h-account__close {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	background-color: #f6f8ff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
}
.h-account__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: translate(160%, -70%);
	-ms-transform: translate(160%, -70%);
	transform: translate(160%, -70%);
	color: #383a4a;
	font-size: 14px;
}

.h-account__avatar {
	width: 36px;
	height: 36px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 10px;
	position: relative;
}
.h-account__avatar--active .h-account__picture svg,
.h-account__avatar:hover .h-account__picture svg {
	stroke: #f1cb62;
}

.h-account__picture {
	width: 32px;
	height: 32px;
}
.h-account__picture svg {
	width: 100%;
	height: 100%;
	stroke: rgba(35, 39, 55, 0.5);
}

.h-popup {
	width: 270px;
	height: 320px;
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 65;
	background-color: #333545;
	-webkit-transform: translateY(8px);
	-ms-transform: translateY(8px);
	transform: translateY(8px);
	padding: 40px 16px 20px 16px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
	box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
}
.h-popup--active {
	display: block;
}
.h-popup__wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
.h-popup__wrapper form {
	height: 80%;
}
.h-popup__wrapper form .loginFormWrapper {
	height: 80% !important;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
.loginFormWrapper span:hover {
	cursor: pointer;
}
.h-popup__btn {
	min-width: 80px;
	padding: 5px 25px;
	margin-left: 10px;
}
.field.h-popup__field {
	width: 100%;
	height: 25px;
	border-bottom: 1px solid rgba(223, 223, 223, 0.5) !important;
	color: #fff;
	padding: 3px 0;
	font-size: 13px;
}
.field.h-popup__field::-webkit-input-placeholder {
	color: rgba(223, 223, 223, 0.5);
}
.field.h-popup__field::-moz-placeholder {
	color: rgba(223, 223, 223, 0.5);
}
.field.h-popup__field:-ms-input-placeholder {
	color: rgba(223, 223, 223, 0.5);
}
.h-popup__error {
	display: none;
}
.h-popup__wrap {
	position: relative;
}
.h-popup__or {
	position: relative;
	text-align: center;
}
.h-popup__or:before,
.h-popup__or:after {
	width: 100px;
	height: 1px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: rgba(255, 255, 255, 0.5);
	content: '';
}
.h-popup__or:before {
	left: 0;
}
.h-popup__or:after {
	right: 0;
}
.h-popup .show-password,
.cube .show-password {
	color: rgba(223, 223, 223, 0.5);
	cursor: pointer;
}
.h-popup .show-password:hover,
.h-popup .show-password--active,
.cube .show-password:hover,
.cube .show-password--active {
	color: #fff;
}

.required {
	position: relative;
}
.required .error {
	display: none;
}
.required--error .field,
.required--error .select {
	border-color: #fc3164 !important;
	color: #fc3164;
}
.required--error .field::-webkit-input-placeholder,
.required--error .select::-webkit-input-placeholder {
	color: #fc3164;
}
.required--error .field::-moz-placeholder,
.required--error .select::-moz-placeholder {
	color: #fc3164;
}
.required--error .field:-ms-input-placeholder,
.required--error .select:-ms-input-placeholder {
	color: #fc3164;
}
.required--error .font {
	color: #fc3164;
}
.required--error .error {
	display: block !important;
	position: absolute;
	margin-top: 5px;
}
.h-popup__overlay {
	z-index: 60;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	display: block;
	width: 100%;
}

.show-password {
	position: absolute;
	cursor: pointer;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #b5b7c6;
}
.show-password .fas {
	font-size: 14px;
}
.show-password:hover {
	color: #232737;
}
.show-password--active .fas:after {
	content: '\f070' !important;
}
.fas--active:after {
	content: '\f070' !important;
}
.big-height .show-password {
	top: 32%;
}
.breadcrumbs {
	position: relative;
}
.breadcrumbs__wrap {
	height: 100%;
}
.breadcrumbs__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	padding-right: 10px;
	margin-right: 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.breadcrumbs__item:after {
	content: '/';
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0;
	color: rgba(255, 255, 255, 0.5);
}
.breadcrumbs__item:last-child:after {
	display: none;
}
.breadcrumbs__link:hover,
.breadcrumbs__link--active {
	color: #fff !important;
}
.breadcrumbs__text {
	color: #fff;
}
.breadcrumb_title_part {
	max-width: 140px !important;
}
.breadcrumb_title_part:nth-child(2) {
	padding: 0px 5px;
}
.breadcrumbs--bg {
	width: 100%;
	height: 40px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	padding: 5px 10px;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.breadcrumbs--bg:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
}
.breadcrumbs .dropdown__item:hover,
.breadcrumbs .dropdown__item--active {
	background-color: #191c27;
}
.h-submenu__dropdown.dropdown--active .dropdown__toggle {
	border-bottom-color: transparent;
	-webkit-border-radius: 12px 12px 0 0;
	border-radius: 12px 12px 0 0;
}
.h-submenu .lang {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.h-submenu__dropdown {
	position: relative;
	z-index: 0;
}
.h-submenu__item {
	margin-right: 17px;
}
.h-submenu__dropdown .dropdown__toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	min-height: 24px;
	min-width: 120px;
	padding: 2px 20px 2px 10px;
	-webkit-border-radius: 12px;
	border-radius: 12px;
	border: 1px solid #383a4a;
	padding-left: 15px;
}
.dropdown__toggle {
	padding-right: 10px;
	position: relative;
	cursor: pointer;
}
.h-submenu__dropdown .dropdown__toggle:after {
	right: 8px;
	color: #9a9b9f;
	font-size: 12px;
}
.h-submenu .lang__img,
.h-submenu .lang__country {
	width: 16px;
	height: 14px;
}
.h-submenu .lang .dropdown__menu {
	left: 0;
	min-width: 0;
}
.h-submenu__dropdown .dropdown__menu {
	right: 0;
	left: 0;
	-webkit-border-radius: 0 0 12px 12px;
	border-radius: 0 0 12px 12px;
	border-left: 1px solid #383a4a;
	border-right: 1px solid #383a4a;
	border-bottom: 1px solid #383a4a;
	overflow: hidden;
}
.langs-overlay {
	z-index: 60;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	display: none;
	width: 100%;
}
.langs-overlay.active {
	display: block;
}
