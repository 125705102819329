.ips-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	background-color: #fff;
}
.ips-table .td {
	position: relative;
	padding: 0 5px;
}
.ips-table .td:nth-child(1) {
	width: 10%;
}
.ips-table .td:nth-child(2) {
	width: 25%;
}
.ips-table .td:nth-child(3) {
	width: 45%;
}
.ips-table .td:nth-child(4) {
	width: 10%;
}
.ips-table .td:nth-child(5) {
	width: 10%;
}
.ips-table .tr {
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	height: 30px;
}
.ips-table .tr--title {
	background-color: #f1f3f7;
	height: 30px;
}
.ips-table .c-icon {
	margin: 0 auto;
}

.ips-control-bar {
	width: 100%;
	height: 45px;
	background-color: #fff;
	overflow: hidden;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ips-control-bar__item {
	width: 158px;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.ips-control-bar__item:after {
	width: 1px;
	height: 16px;
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	border-right: 1px dashed rgba(194, 211, 212, 0.5);
}
.ips-control-bar__item:before {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.ips-control-bar__item:hover,
.ips-control-bar__item--active {
	cursor: pointer;
}
.ips-control-bar__item:hover *,
.ips-control-bar__item--active * {
	color: #6c90fc;
}
.ips-control-bar__item:hover:before,
.ips-control-bar__item--active:before {
	width: 100%;
}

.ip-control-bar {
	height: 34px;
	background-color: #1a1e30;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}
.ip-control-bar__wrap {
	padding: 0 10px;
}
.ip-control-bar__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	margin-right: 20px;
	position: relative;
	color: rgba(108, 144, 252, 0.5);
}
.ip-control-bar__link:after {
	width: 0;
	height: 4px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.ip-control-bar__link:hover,
.ip-control-bar__link--active {
	color: #6c90fc;
}
.ip-control-bar__link:hover:after,
.ip-control-bar__link--active:after {
	width: 100%;
}
.ipn-control-left {
    left: 0px;
}
.ipn-control-left::before{
    content: '\f104';
}
.ipn-control-right {
    right: 0px;
}
.ipn-control-right::before{
    content: '\f105';
}
.ipn-control-btn {
    display: none;
    position: absolute;
    z-index: 10;
    top: 0px;
    height: 100%;
    width: 20px;
    background: #6C90FC;
    border:none;
    outline: none;
}
.ipn-control-btn::before {
    font-family: 'Font Awesome 5 Pro';
    font-size: 15px;
    color: #ffffff;
}
.ips-control-bar:hover .ipn-control-btn {
    display: block;
}
.ips-control-bar__wrap {
    transition: transform .4s ease-in-out;
}
.ips-table-empty {
    min-height: calc(80vh - 160px);
    background: #f1f3f7;
    width: 100%;
}
