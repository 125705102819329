.fade-appear {
	opacity: 0;
}
.fade-appear.fade-appear-active {
	opacity: 1;
	transition: opacity 1000ms ease-in;
}
.fade-exit {
	opacity: 1;
}
.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 1000ms ease-in;
}
.fade-exit-done {
	opacity: 0;
}
.fade-enter {
	opacity: 0.01;
}
.fade-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 1000ms ease-in;
}
