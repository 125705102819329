.lv-translation {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #232737;
}
.lv-translation__header {
	height: 32px;
	border-bottom: 1px solid #0c1022;
}
.lv-translation__body {
	/* min-height: 325px; */
}
.lv-translation--hide .lv-translation__body {
	display: none;
}
.lv-translation--show .lv-translation__body {
	display: block;
}
.lv-translation__placeholder {
	height: 265px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lv-translation__icon {
	width: 32px;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	border-left: 1px solid #0c1022;
}
.lv-translation__icon .icon {
	font-size: 14px;
	color: #9a9b9f;
}
.lv-translation__icon:first-child {
	border-left: 0;
	border-right: 1px solid #0c1022;
}
.lv-translation__icon:hover .icon,
.lv-translation__icon--active .icon {
	color: #fff;
}
.lv-translation__icon--uncompress .icon:after {
	content: '\f424';
}
.lv-translation__icon--compress .icon:after {
	content: '\f422';
}
.lv-translation__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 100%;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.flex .lv-translation__link:nth-child(even) {
	border-right: 1px solid #0c1022;
}
.lv-translation__link:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	background-color: #f1cb62;
	width: 0;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.lv-translation__link--active,
.lv-translation__link:hover {
	color: #f1cb62;
}
.lv-translation__link--active:after,
.lv-translation__link:hover:after {
	width: 100%;
}
.lv-translation__hr {
	width: 1px;
	height: 16px;
	background-color: #0c1022;
	margin: 0;
	border: 0;
}
.lv-translation .volume--active {
	position: relative;
}
.lv-translation .volume--active .icon {
	color: #fff;
}
.lv-translation .volume__menu {
	/* display: none;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 240px;
    background-color: #fff;
    border-radius: 0 2px 2px 2px; */
	display: none;
	padding: 10px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 240px;
	background-color: #fff;
	-webkit-border-radius: 0px 2px 2px 2px;
	border-radius: 0px 2px 2px 2px;
	z-index: 2;
}
/* .lv-translation .volume:hover .volume__menu, .lv-translation .volume--active .volume__menu {
    display: block;
} */
.lv-translation .volume:hover {
	position: relative;
}
.lv-translation .volume__icon {
	color: #9a9b9f;
	width: 24px;
}
.lv-translation .volume__icon:hover {
	color: #6c90fc;
}
.lv-translation .volume__icon--active:after {
	content: '\f6a9';
}
.lv-translation .drop--active .icon:after {
	content: '\f107';
}

.betslip {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #fff;
}
.betslip__header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 10px;
	height: 40px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 14px;
	height: 100%;
	position: relative;
}
.betslip__link:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: #6c90fc;
	width: 0;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.betslip__link:hover,
.betslip__link--active {
	color: #6c90fc;
}
.betslip__link:hover:after,
.betslip__link--active:after {
	width: 100%;
}
.betslip__quickbet {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 10px;
	height: 36px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__quickbet-panel {
	margin-bottom: 10px;
	padding: 10px;
	background: #fff;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border: 1px solid #e7e7e7;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}
.betslip__quickbet-panel hr {
	width: 100%;
	height: 1px;
	display: block;
	border: 0;
	background-color: rgba(194, 211, 212, 0.5);
}
.betslip__quickbet-btn {
	max-width: 30%;
	margin: 5px 0;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}
.betslip__quickbet-btn:hover,
.betslip__quickbet-btn--active {
	background-color: rgba(0, 0, 0, 0.05);
}
.betslip__body {
	position: relative;	
	background-color: #f6f8ff;
	padding: 10px;
}
.betslip__item {
	padding: 14px 10px 10px;
	background: #fff;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.176546);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.176546);
	margin: 10px 0;
	overflow: hidden;
	position: relative;
}
.betslip__item:first-child {
	margin-top: 0;
}
.betslip__item:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 2px;
}
.betslip__item--active:before {
	background-color: rgba(252, 49, 100, 0.7);
}
.betslip__item--disabled:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.4);
}
.betslip__item--disabled .betslip__amount:after {
	content: '\f30d';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 2;
	font-size: 14px;
	color: rgba(35, 39, 55, 0.5);
}
.betslip__item--disabled .betslip__amount:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: #f9f9f9;
	z-index: 1;
}
.betslip__close {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	background-color: #f6f8ff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
	z-index: 2;
}
.betslip__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: translate(160%, -70%);
	-ms-transform: translate(160%, -70%);
	transform: translate(160%, -70%);
	color: #383a4a;
	font-size: 14px;
}
.betslip__multiples {
	background: #fff;
	border: 1px solid #e7e7e7;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	-webkit-border-radius: 2px;
	border-radius: 2px;
}
.betslip__multiple {
	padding: 10px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__multiple:last-child {
	border-bottom: 0;
}
.betslip__multiple .betslip__amount {
	width: 90px;
}
.betslip__drop {
	cursor: pointer;
	padding: 0 10px;
	height: 28px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.4);
}
.betslip__drop .fal {
	color: rgba(35, 39, 55, 0.5);
}
.betslip__drop--down .fal:after {
	content: '\f107';
}
.betslip__drop--up .fal:after {
	content: '\f106';
}
.betslip__odds--up {
	color: #15b93a !important;
}
.betslip__odds--down {
	color: #fc3164 !important;
}
.betslip__amount {
	width: 144px;
	height: 28px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background: #f9f9f9;
	border: 1px solid #e7e7e7;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	position: relative;
	overflow: hidden;
}
.betslip__amount hr {
	width: 1px;
	height: 16px;
	display: block;
	position: absolute;
	top: 50%;
	right: 34px;
	border: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0;
	background-color: #c2d3d4;
}
.betslip__amount input {
	width: 110px;
	height: 100%;
	text-align: center;
}
.betslip__amount input::-webkit-outer-spin-button,
.betslip__amount input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}
.betslip__amount input[type='number'] {
	-moz-appearance: textfield;
}
.betslip__amount div.button--max {
	cursor: pointer;
	width: 30px;
	text-align: center;
}
.betslip__posible {
	margin-top: 10px;
}
.betslip__accept {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px;
	background: #fff;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.176546);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.176546);
	margin: 10px 0;
}
.betslip__message {
	padding: 5px 10px;
}
.betslip__remove-all {
	padding: 5px 10px;
	text-align: left;
	text-decoration: underline;
	color: rgba(35, 39, 55, 0.5);
}
.betslip__remove-all:hover {
	cursor: pointer;
}
.betslip__placebet,
.betslip__apply {
	-webkit-border-radius: 0px 0px 2px 2px;
	border-radius: 0px 0px 2px 2px;
}

.betslip__drop--down .fal.fa-angle-right {
	transform: rotate(90deg);
}
.betslip__drop--up .fal.fa-angle-right {
	transform: rotate(-90deg);
}
.betslip__quickbet-panel .betslip__quickbet-btn:hover {
	background-color: #202434;
	color: #fff;
	font-weight: bold;
}
.betslip__quickbet-panel .betslip__quickbet-btn:active {
	background-color: rgba(11, 29, 91, 0.6);
	font-weight: bold;
}
.betslip__quickbet-panel .betslip__quickbet-btn:hover span {
	color: #fff !important;
}
.betslip__quickbet-panel .betslip__quickbet-btn:active span {
	color: #ffff00 !important;
}
.betslip__empty {
	padding: 15px 10px 20px 10px;
}
.betslip__empty .font {
	margin-bottom: 15px;
}
.betslip__empty .font:last-child {
	margin-bottom: 0;
}
.longclick {
	color: rgba(35, 39, 55, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}
.longclick svg {
	color: rgba(35, 39, 55, 0.5);
	width: 50%;
	height: 50%;
}
.longclick svg path {
	fill: #c1cad3;
}
.wrap--authorize {
	display: inline-flex;
	width: 100%;
	margin-bottom: 20px;
	background-color: #f40034;
	border-radius: 3%;
	cursor: pointer;
}
.not-logon--icon {
	width: 30%;
}
.not-logon--icon svg {
	width: 50px;
}
.not-logon--icon svg path {
	fill: #fff;
}
.wrap--authorize .text {
	color: #fff;
	line-height: 50px;
	font-weight: bold;
	font-size: 1rem;
	width: 70%;
}
/* Settings */
.betslip__settings {
	cursor: pointer;
	margin-top: 10px;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	-webkit-border-radius: 2px;
	border-radius: 2px;
}
.betslip__settings-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 32px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	background-color: #f6f8ff;
	padding: 0 10px;
}
.betslip__settings-body {
	display: block;
	overflow-y: hidden;
	height: 0;
	background-color: #f1f3f7;
	transition: height 250ms ease-in-out;
}
.betslip__settings--active .betslip__settings-body {
	display: block;
}
.betslip__settings-icon {
	width: 16px;
	height: 16px;
	margin-left: 10px;
}
.betslip__settings-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 33px;
	padding: 0 10px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}

.settings_slide-enter {
	height: 0;
	overflow-y: hidden;
}
.settings_slide-enter-active {
	height: 99px;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
}
.settings_slide-enter-done {
	height: 99px !important;
}
.settings_slide-exit {
	height: 99px;
}
.settings_slide-exit-active {
	height: 0 !important;
	-webkit-transition: height 250ms ease-in;
	-moz-transition: height 250ms ease-in;
	-o-transition: height 250ms ease-in;
	transition: height 250ms ease-in;
}
.betslip__quickbet-panel {
	height: 0;
	overflow: hidden;
}
.qb_slide-enter {
	height: 0;
	overflow-y: hidden;
}
.qb_slide-enter-active {
	height: 82px;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
}
.qb_slide-enter-done {
	height: 100% !important;
}
.qb_slide-exit {
	height: 82px;
}
.qb_slide-exit-active {
	height: 0 !important;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
}
.b-accept__wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #f6f8ff;
	padding: 10px;
	width: 100%;
}
.b-accept__header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 33px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-border-radius: 2px 2px 0 0;
	border-radius: 2px 2px 0 0;
	background-color: #15b93a;
	padding: 0 10px;
}
.b-accept__remove,
.b-accept__edit {
	color: #fff;
	font-size: 11px;
}
.b-accept__bets {
	-webkit-border-radius: 0 0 2px 2px;
	border-radius: 0 0 2px 2px;
	-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
}
.b-accept__footer {
	margin-top: 5px;
}
.b-accept__bet {
	width: 100%;
	height: 108px;
	border-bottom: 1px dashed rgba(194, 211, 212, 0.5);
	background-color: #fff;
	padding: 10px;
	overflow: hidden;
}
.b-accept__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.b-accept__cell {
	overflow: hidden;
}
.b-accept__cell--sm {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 5px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.b-accept__odds {
	font-size: 12px;
	color: #6c90fc;
	font-weight: 700;
}
.b-accept__field {
	width: 66px;
	height: 30px;
	-moz-appearance: textfield;
	border: 1px solid #e7e7e7;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #f9f9f9;
	/* padding: 0 10px; */
	text-align: center;
	color: rgba(56, 58, 74, 0.8);
	font-size: 13px;
	margin-left: 10px;
}
.b-accept__title {
	font-weight: 500;
	min-height: 18px;
}
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	margin: 4px 0;
}
.b-accept__money,
.b-accept__text,
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}
.b-accept__teams {
	color: #75757c;
	font-size: 11px;
}
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	margin: 4px 0;
}
.b-accept__label {
	font-size: 12px;
}
.b-accept__info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: space-betwenn;
	-webkit-justify-content: space-betwenn;
	-ms-flex-pack: space-betwenn;
	justify-content: space-betwenn;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 50px;
	position: relative;
	margin-bottom: 5px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #fff;
}
.b-accept__info .b-accept__cell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	padding: 0 10px;
}
.b-accept__info .b-accept__cell > * {
	margin: 2px 0 0 2px;
}
.b-accept__text {
	color: rgba(56, 58, 74, 0.65);
	font-size: 13px;
}
.b-accept__info .b-accept__cell > * {
	margin: 2px 0 0 2px;
}
.b-accept__info .b-accept__cell::after {
	width: 1px;
	height: 31px;
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: rgba(194, 211, 212, 0.5);
	content: '';
}
.b-accept__money--green {
	color: #15b93a;
}
.b-accept__money {
	font-size: 13px;
}
.b-accept__market--blue {
	color: #6c90fc;
}
.b-accept__market {
	font-size: 12px;
}
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	margin: 4px 0;
}
input,
textarea {
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
}
.b-accept__bottom {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 5px;
}
.wrrrap {
	height: 0;
	overflow: hidden;
}
.mult_slide-enter {
	height: 0;
	overflow-y: hidden;
}
/* .mult_slide-enter-done {
	height: 100%;
	overflow-y: hidden;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
} */
.mult_slide-enter-active {
	height: 48px;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
}
.mult_slide-enter-done {
	height: auto;
}
.mult_slide-exit {
	height: 48px;
}
.mult_slide-exit-active {
	overflow: hidden;
	height: 0;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 300ms ease;
}
.mult_slide-exit-done {
	overflow: hidden;
	height: 0;
}
.betslip__settled {
	width: 100%;
	height: 36px;
	padding: 0 10px;
	cursor: pointer;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__settled i {
	font-size: 12px;
	margin-left: 10px;
	position: relative;
}
.betslip__settled .myb-dropdown {
	border-radius: 0 0 0 0;
	border-left: 2px solid #b6b6b6;
	border-right: 1px solid #b6b6b6;
	background-color: #fbfbfb;
	overflow: hidden;
	display: none;
	position: absolute;
	left: 0;
	z-index: 4;
}

.betslip .myb-dropdown.dropdown--active {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 24px;
	width: 27%;
}
.myb-dropdown .myb-dropdown__dropitem {
	padding: 5px 5px;
	border-bottom: 1px solid #dadada;
	width: 100%;
}
.betslip__td--small {
	width: 20%;
	position: relative;
}
.betslip__td--border:after {
	content: '';
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 5px;
	width: 1px;
	background-color: rgba(194, 211, 212, 0.5);
}

.quickbet-panel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	/* margin: 0 20px; */
	/* background-color: #f1f3f7; */
	/* border-top: 1px dashed #000; */
}
.quickbet-panel p {
	margin: 0 10px;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.quickbet-panel .input-group {
	margin-right: 20px;
}
.input-group {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	height: 40px;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin-right: 20px;
}
.plus-minus-input {
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.plus-minus-input {
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.plus-minus-input .input-group-field {
	text-align: center;
	/* margin-left: 0.5rem;
	margin-right: 0.5rem; */
	padding: 1rem;
}

.plus-minus-input .input-group-field::-webkit-inner-spin-button,
.plus-minus-input .input-group-field ::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
}

.fa-minus:before {
	content: '\f068';
	font-weight: 500;
	font-size: 18px;
}
.fa-plus:before {
	content: '\f067';
	font-weight: 500;
	font-size: 18px;
}

.button.hollow {
	/* background-color: #f9f9f9; */
	border: 1px solid rgba(0, 0, 0, 0.4) /*#1779ba*/;
	color: rgba(0, 0, 0, 1) /*#1779ba*/;
	border-radius: 2%;
}
/* .button.hollow[data-quantity='minus'] {
	border-radius: 8% 0 0 8%;
}
.button.hollow[data-quantity='plus'] {
	border-radius: 0 8% 8% 0;
} */
.button.hollow,
.button.hollow:hover,
.button.hollow:focus {
	background-color: transparent;
	background-color: #fff;
}
.input-group-label,
.input-group-field,
.input-group-button,
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
	margin: 0;
	white-space: nowrap;
	height: 30px;
	width: 45px;
}
.input-group-button:first-child {
	margin-right: 10px;
}
.input-group-button:last-child {
	margin-left: 10px;
}
.input-group-field {
	border-radius: 0;
	-webkit-flex: 1 1 0px;
	-ms-flex: 1 1 0px;
	flex: 1 1 0px;
	height: 40px;
	min-width: 0;
	box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.3);
}
.betslip__field__ext {
	width: 70px;
	height: 30px;
	text-align: right;
	color: rgba(0, 0, 0, 0.8);
	/* font-weight: 700; */
	border: 1px solid rgba(0, 0, 0, 0.4);
	/* background-color: #fff; */
}
.plus-minus-input .input-group-field {
	text-align: center;
	/* margin-left: 0.5rem;
	margin-right: 0.5rem; */
	padding: 1rem;
}

.betslip__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: calc(100vh - 241px);
	background-color: rgba(194, 211, 212, 0.3);
	z-index: 10;
}
.betslip__overlay .spinner__outer {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.betslip__overlay-spinner {
	width: 20px;
	height: 20px;
	margin: 0 auto;
	border: 1px solid #393b4f;
	border-left: 1px solid #ffffff;
	border-radius: 50%;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}