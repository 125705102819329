.h-search__item, .h-search__link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #0C1022 !important;
}

.font--13 {
    font-size: 13px;
}
.font--white-alpha-05 {
    color: hsla(0,0%,100%,.5) !important;
}

.font {
    color: #232737;
    line-height: 15px;
    font-size: 13px;
    font-family: "Roboto",sans-serif;
    font-weight: 400;
}

.font--white {
    color: #fff !important;
}

.h-search__img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-left: 15px;
}