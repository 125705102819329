.cb-upload__header {
	height: 28px;
}

.cb-upload__body {
	padding: 20px 0;
}

.cb-upload__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
}
.cb-upload__item:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2px;
	width: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.cb-upload__item--active:after,
.cb-upload__item:hover:after {
	width: 100%;
}
.cb-upload__item--active .cb-upload__text,
.cb-upload__item--active .cb-upload__icon,
.cb-upload__item:hover .cb-upload__text,
.cb-upload__item:hover .cb-upload__icon {
	color: #6c90fc;
}

.cb-upload__icon {
	width: 22px;
	height: 14px;
	margin-right: 6px;
	color: #9a9b9f;
}

.cb-upload__drop {
	width: 100%;
	height: 100px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px dashed rgba(35, 39, 55, 0.5);
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.cb-upload__upload {
	display: none;
}

.cb-message {
	padding: 10px;
}
.cb-message__icon {
	width: 42px;
	height: 42px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto 15px auto;
	font-family: 'Font Awesome 5 Pro';
	font-size: 32px;
}
.cb-message--success .cb-message__icon:after {
	content: '\f118';
}
.cb-message--success .cb-message__title,
.cb-message--success .cb-message__icon {
	color: #15b93a;
}
.cb-message--error .cb-message__icon:after {
	content: '\f119';
}
.cb-message--error .cb-message__title,
.cb-message--error .cb-message__icon {
	color: #fc3164;
}

.cb-alert {
	width: 100%;
	height: 30px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	margin: 10px 0;
}
.cb-alert__wrap {
	padding: 0 10px;
}
.cb-alert__text {
	max-width: 80%;
}
.cb-alert--default,
.cb-alert--confirmed,
.cb-alert--failed,
.cb-alert--uploading {
	background-color: #f7fafd;
	border: 1px solid #d2dcf1;
}
.cb-alert--default .cb-alert__text {
	color: rgba(35, 39, 55, 0.5);
}
.cb-alert--default .cb-alert__times {
	cursor: pointer;
	font-size: 16px;
}
.cb-alert--confirmed .cb-alert__message {
	color: #15b93a;
}
.cb-alert--confirmed .cb-alert__text {
	color: rgba(35, 39, 55, 0.5);
}
.cb-alert--bad {
	background-color: #e1e5ee;
}
.cb-alert--warning {
	background-color: rgba(252, 49, 100, 0.08);
	border: 1px solid rgba(252, 49, 100, 0.5);
}
.cb-alert--warning .cb-alert__message {
	color: #fc3164;
}
.cb-alert--warning .cb-alert__text {
	color: #fc3164;
}
.cb-alert--failed .cb-alert__text {
	color: #fc3164;
}
.cb-alert--failed .cb-alert__reload {
	cursor: pointer;
}
.cb-alert--failed .cb-alert__reload .fal {
	color: #15b93a;
	font-size: 14px;
}
.cb-alert--uploading {
	height: auto;
	padding-top: 5px;
	padding-bottom: 10px;
}
.cb-alert--uploading .cb-alert__text {
	color: rgba(35, 39, 55, 0.5);
}
.cb-alert--uploading .cb-alert__separate {
	width: 3px;
	height: 3px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #232737;
	margin: 0 5px;
}
.cb-alert--uploading .cb-alert__progress {
	width: 100%;
	height: 2px;
	background-color: #d8d8d8;
}
.cb-alert--uploading .cb-alert__progress > div {
	height: 100%;
	background-color: #6c90fc;
}
.cb-alert--uploading .cb-alert__buttons {
	margin-right: 0;
	margin-left: auto;
}
.cb-alert--uploading .cb-alert__button {
	width: 16px;
	height: 16px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 0;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: transparent;
	overflow: hidden;
}
.cb-alert--uploading .cb-alert__button--stop {
	background-color: rgba(252, 49, 100, 0.3);
	position: relative;
}
.cb-alert--uploading .cb-alert__button--stop:hover {
	background-color: rgba(252, 49, 100, 0.5);
}
.cb-alert--uploading .cb-alert__button--stop i {
	text-align: center;
}
.cb-alert--uploading .cb-alert__button--stop i:before,
.cb-alert--uploading .cb-alert__button--stop i:after {
	width: 1px;
	height: 7px;
	background-color: rgba(252, 49, 100, 0.5);
	position: absolute;
	top: 50%;
	left: 50%;
	content: '';
}
.cb-alert--uploading .cb-alert__button--stop i:before {
	-webkit-transform: translate(-50%, -50%) rotate(45deg);
	-ms-transform: translate(-50%, -50%) rotate(45deg);
	transform: translate(-50%, -50%) rotate(45deg);
}
.cb-alert--uploading .cb-alert__button--stop i:after {
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	transform: translate(-50%, -50%) rotate(-45deg);
}
.cb-alert--uploading .cb-alert__button--pause {
	background-color: #d0ddea;
}
.cb-alert--uploading .cb-alert__button--pause:hover {
	background-color: #9a9b9f;
}
.cb-alert--uploading .cb-alert__button--resume:hover {
	background-color: #9a9b9f;
}
.cb-alert--uploading .cb-alert__button--pause i {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.cb-alert--uploading .cb-alert__button--resume i {
	border-style: solid;
	border-width: 37px;
	border-color: #202020;
}
.cb-alert--uploading .cb-alert__button--pause i:before,
.cb-alert--uploading .cb-alert__button--pause i:after {
	width: 2px;
	height: 6px;
	background-color: rgba(35, 39, 55, 0.5);
	margin: 0 1px;
	content: '';
}

.pause {
	background-color: #d0ddea;
}
.pause:hover {
	background-color: #9a9b9f;
}

.cb-alert--uploading .cb-alert__button--resume i:before,
.cb-alert--uploading .cb-alert__button--resume i:after {
	width: 2px;
	height: 6px;
	content: '\f04b';
}

.cb-popup {
	display: none;
	max-width: 400px;
	width: 100%;
	position: absolute;
	padding: 20px;
	left: 50%;
	top: 20px;
	z-index: 1;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	overflow: hidden;
}
.cb-popup--center {
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.cb-popup__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
.cb-popup__close {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	background-color: #f6f8ff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
}
.cb-popup__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: translate(160%, -70%);
	-ms-transform: translate(160%, -70%);
	transform: translate(160%, -70%);
	color: #383a4a;
	font-size: 14px;
}
.cb-popup__notification {
	width: 100%;
	height: 38px;
	background-color: #f1f3f7;
	margin-top: 24px;
}
.cb-popup--block {
	display: block;
}

.cb-table {
	border-collapse: collapse;
	border-spacing: 0 10px;
	width: 100%;
}
.cb-table--custom {
	table-layout: fixed;
}
.cb-table__th {
	height: 25px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__th:first-child {
	border-left: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__th:last-child {
	border-right: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__td {
	position: relative;
	height: 40px;
	border: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__td--border {
	border-right: 0 !important;
	border-left: 0 !important;
}
.cb-table__td--border:after {
	content: '';
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 0;
	width: 1px;
	background-color: rgba(194, 211, 212, 0.5);
}
.cb-table__th,
.cb-table__td {
	padding: 5px;
}
.cb-table__tr {
	display: table-row;
}
.cb-table__tr--hide {
	display: none;
}
.cb-table__tr--show {
	display: table-row;
	background-color: #f6f8ff;
}
.cb-table__tr--title {
	height: 26px;
	background-color: #f6f8ff;
}

.cb-tab {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	height: 100%;
}
.cb-tab__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	margin-right: 20px;
	cursor: pointer;
}
.cb-tab__item:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	height: 4px;
	width: 0;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.cb-tab__item:hover:after,
.cb-tab__item--active:after {
	width: 100%;
}
.cb-navbar {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: none;
}
.cb-navbar__item {
	position: relative;
	margin-bottom: 1px;
}
.cb-navbar__item > .cb-navbar {
	display: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-top: 1px;
}
.cb-navbar__item:last-child {
	margin-bottom: 0;
}
/*.cb-navbar__item:hover > .cb-navbar,*/
.cb-navbar__item--active > .cb-navbar {
	display: block;
}
.cb-navbar__item:hover > .cb-navbar__link,
.cb-navbar__item--active > .cb-navbar__link {
	background-color: #2d334c;
}
.cb-navbar__item:hover > .cb-navbar__link:after,
.cb-navbar__item--active > .cb-navbar__link:after {
	-webkit-transform: translateY(-50%) rotate(180deg);
	-ms-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg);
}
.cb-navbar__item:hover > .cb-navbar .cb-navbar__link,
.cb-navbar__item--active > .cb-navbar .cb-navbar__link {
	padding-left: 30px;
	padding-right: 40px;
	color: #9a9b9f;
	font-weight: 400;
}
.cb-navbar__item:hover > .cb-navbar .cb-navbar__link:hover,
.cb-navbar__item:hover > .cb-navbar .cb-navbar__link--active,
.cb-navbar__item--active > .cb-navbar .cb-navbar__link:hover,
.cb-navbar__item--active > .cb-navbar .cb-navbar__link--active {
	color: #f1cb62;
}
.cb-navbar__item--dropdown:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	color: inherit;
	position: absolute;
	top: 15px;
	right: 15px;
	color: #fff;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.cb-navbar__item--active.cb-navbar__item--dropdown:after {
	transform: rotate(180deg);
}
.cb-navbar__item--notification .cb-navbar__notification {
	display: block;
}
.cb-navbar__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 39px;
	padding: 0 30px 0 10px;
	position: relative;
	background-color: #1a1e30;
}
.cb-navbar__notification {
	width: 5px;
	height: 5px;
	display: none;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #f1cb62;
	position: absolute;
	top: 20px;
	right: 40px;
	z-index: 1;
}
.cb-navbar__count {
	width: 27px;
	height: 17px;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	border: 1px solid #f1cb62;
	text-align: center;
	position: absolute;
	right: 30px;
}

.cb-account {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
	padding: 10px;
	margin-bottom: 10px;
	background-color: #1a1e30;
}
.cb-account__cell {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.cb-account__identify {
	margin-bottom: 10px;
}
.cb-account__date {
	padding-left: 40px;
}
.cb-account__img {
	width: 30px;
	height: 30px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 10px;
}
.cb-account__img img {
	display: block;
}
.cb-account__hr {
	width: 100%;
	height: 1px;
	margin: 10px 0;
	border: 0;
	background-color: #2d334c;
}
.cb-account__progress {
	width: 100%;
	height: 3px;
	margin: 10px 0 15px 0;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.2);
}
.cb-account__progress > * {
	height: 100%;
	background-color: #6c90fc;
}

.cb-replenishment {
	width: 376px;
	height: 400px;
	display: none;
	position: absolute;
	left: 50%;
	top: 50%;
	overflow: hidden;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background-color: #434559;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.cb-replenishment__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.cb-replenishment__header {
	height: 46px;
	background-color: rgba(45, 51, 76, 0.68);
}
.cb-replenishment__body {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	padding: 20px 16px;
}
.cb-replenishment__img {
	max-width: 100%;
	height: auto;
}
.cb-replenishment__qr {
	width: 100px;
	height: 100px;
	background-color: #fff;
	margin: 10px auto;
}
.cb-replenishment__wallet {
	position: relative;
}
.cb-replenishment__wallet .field {
	width: 190px;
	height: 28px;
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #fff;
	color: rgba(35, 39, 55, 0.5);
	padding-right: 30px;
}
.cb-replenishment__wallet .copy {
	position: absolute;
	top: 50%;
	right: 5px;
	color: rgba(35, 39, 55, 0.5);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.cb-replenishment__wallet .copy:hover,
.cb-replenishment__wallet .copy--active {
	color: #6c90fc;
}
.cb-replenishment__amount {
	margin-bottom: 10px;
}
.cb-replenishment__amount .field {
	width: 140px;
	height: 28px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #fff;
	color: rgba(35, 39, 55, 0.5);
	text-align: center;
	font-weight: 500;
}
.cb-replenishment__amount .message {
	display: none;
	background-color: rgba(252, 49, 100, 0.3);
	-webkit-border-radius: 2px;
	border-radius: 2px;
	margin-top: 10px;
}
.cb-replenishment__amount .font {
	color: #fff;
}
.cb-replenishment__amount--error .field {
	color: #fc3164;
}
.cb-replenishment__amount--error .font {
	color: #fc3164;
}
.cb-replenishment__amount--error .message {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.cb-replenishment__footer {
	padding: 10px 16px;
	position: relative;
}
.cb-replenishment__footer:before {
	content: '';
	position: absolute;
	height: 1px;
	left: 10px;
	right: 10px;
	top: 0;
	background-color: #2d334c;
}
.cb-replenishment--hide {
	display: none;
}
.cb-replenishment--block {
	display: block;
}

.cabinet .balance {
	position: relative;
}
.cabinet .balance .copy {
	color: #6c90fc;
	cursor: pointer;
}
.cabinet .balance .hash {
	max-width: 90%;
}
.cabinet .balance .external-link {
	color: #6c90fc;
}
.cabinet .balance .external-link * {
	font-size: 14px;
}
.cabinet .balance .cancel {
	border: 1px solid rgba(35, 39, 55, 0.5);
	-webkit-border-radius: 2px;
	border-radius: 2px;
	padding: 0 10px;
}
.cabinet .balance .img {
	max-width: 100%;
	height: auto;
	display: block;
}

.cabinet .history {
	position: relative;
}
.cabinet .history .t-subcategory {
	height: 30px;
	background-color: #f6f8ff;
}
.cabinet .history .t-subcategory__left {
	width: 10%;
	padding: 0 5px 0 10px;
}
.cabinet .history .t-subcategory__right {
	width: 90%;
	padding: 0 10px 0 5px;
}
.cabinet .history .table .text-ellipsis {
	margin-right: 5px;
	max-width: 100%;
}
.cabinet .history .score {
	margin-left: 3px;
}

.cabinet .message {
	overflow: hidden;
}
.cabinet .message__content {
	padding: 10px;
}
.cabinet .message__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 38px;
	padding: 5px;
	background-color: #f1f3f7;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	cursor: pointer;
}
.cabinet .message__item div {
	overflow: hidden;
	padding: 0 2px;
}
.cabinet .message__item div:nth-child(1) {
	width: 5%;
}
.cabinet .message__item div:nth-child(2) {
	width: 25%;
}
.cabinet .message__item div:nth-child(3) {
	width: 60%;
}
.cabinet .message__item div:nth-child(4) {
	width: 10%;
	text-align: right;
}
.cabinet .message__item:hover {
	background-color: #f1f3f7;
}
.cabinet .message__item--checked {
	background-color: #d1d7e4;
}
.cabinet .message__item--new {
	background-color: #fff;
}
.cabinet .message__item--new .font {
	font-weight: 700;
}

.cabinet .account {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	padding: 30px 5%;
}
.cabinet .account .left,
.cabinet .account .right {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.cabinet .account .left {
	margin-right: 2.5%;
}
.cabinet .account .right {
	margin-left: 2.5%;
}
.cabinet .account .wrap {
	margin-bottom: 40px;
	position: relative;
}
.cabinet .account .wrap:last-child {
	margin-bottom: 0;
}
.cabinet .account .btn {
	max-width: 280px;
}
.cabinet .account .select {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin: 0 10px;
}
.cabinet .account .select:first-child {
	margin-left: 0;
}
.cabinet .account .select:last-child {
	margin-right: 0;
}
.cabinet .account .title-group {
	margin-bottom: 50px;
}
.cabinet .account .support {
	z-index: 2;
	margin-top: 200px;
	position: relative;
}
.cabinet .account .list__item {
	position: relative;
	padding-left: 10px;
}
.cabinet .account .list__item:before {
	width: 3px;
	height: 3px;
	position: absolute;
	left: 0;
	top: 5px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #232737;
	content: '';
}

.cabinet .offers {
	position: relative;
	padding: 10px;
}
.cabinet .offers .offer {
	margin-bottom: 10px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#2c2e3b),
		to(#383a4a)
	);
	background: -o-linear-gradient(left, #2c2e3b 0%, #383a4a 100%);
	background: linear-gradient(90deg, #2c2e3b 0%, #383a4a 100%);
	-webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.cabinet .offers .offer__wrap {
	min-height: 85px;
	padding: 8px 16px;
}
.cabinet .offers .offer__left,
.cabinet .offers .offer__right,
.cabinet .offers .offer__center {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.cabinet .offers .offer__center {
	padding: 0 10px;
}
.cabinet .offers .offer__btn {
	width: 100%;
}
.cabinet .offers .offer__btn--active {
	-webkit-box-shadow: 0px 0px 8px #f1cb62;
	box-shadow: 0px 0px 8px #f1cb62;
	max-width: 60%;
}
.cabinet .offers .offer__btn--not-active {
	background-color: rgba(255, 255, 255, 0.5);
	border: 1px solid #979797;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.cabinet .offers .offer__btn--more {
	border-color: #f1cb62;
	max-width: 35%;
}
.cabinet .offers .offer__btn--not-more {
	border-color: rgba(255, 255, 255, 0.5);
}
.cabinet .offers .offer .progress__body {
	margin-top: 5px;
}
.cabinet .offers .offer .progress__body > * {
	height: 5px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin: 0 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border: 1px solid #f1cb62;
	cursor: pointer;
}
.cabinet .offers .offer .progress__body > *.active {
	background-color: #f1cb62;
}
.cabinet .offers .offer .progress__body > *:first-child {
	margin-left: 0;
}
.cabinet .offers .offer .progress__body > *:last-child {
	margin-right: 0;
}
.page.cabinet .content {
	max-height: -webkit-calc(100vh - 120px);
	max-height: calc(100vh - 120px);
}

.cabinet {
	width: 100%;
}

.cabinet .content {
	width: 100%;
	max-height: 100%;
}

.cabinet .container {
	background-color: #fff;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	min-height: 700px;
	height: 100%;
	width: 100%;
}
.cabinet .container__head {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.cabinet .container__header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #e1e5ee;
	padding: 0 10px;
	height: 40px;
}
.cabinet .container__header .dropdown {
	margin-left: auto;
}
.cabinet .container__header .dropdown__menu {
	z-index: 2;
	background-color: #e1e5ee;
}
.cabinet .container__header .dropdown__item:hover,
.cabinet .container__header .dropdown__item--active {
	background-color: #d1d7e5;
}
.cabinet .container__header .hr {
	width: 1px;
	height: 20px;
	border: 0;
	margin: 0 20px;
	background-color: rgba(194, 211, 212, 0.5);
}
.cabinet .container__header .calendar {
	margin-right: 0;
}
.cabinet .container__header .calendar__item {
	color: #6c90fc;
	cursor: pointer;
}
.cabinet .container__header .action__item {
	color: #383a4a;
	margin-left: 10px;
	cursor: pointer;
}
.cabinet .container__header .action__item i {
	font-size: 12px;
}
.cabinet .container__header .action__item:hover,
.cabinet .container__header .action__item--active {
	color: #6c90fc;
}
.cabinet .container__header .action__item:hover .font,
.cabinet .container__header .action__item--active .font {
	color: #6c90fc;
}
.cabinet .container__header .action__item--back {
	margin-left: 0;
}
.cabinet .container__header .action__item--back i {
	font-size: 16px;
}
.timer--gold .timer__top > * {
	color: #f1cb62;
}
.timer__top > *:after {
	/* display: none; */
	content: ':';
	position: absolute;
	top: 50%;
	right: -50%;
	-webkit-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}
.timer__top > *:last-child::after {
	display: none;
}
.timer__top > * {
	font-size: 18px !important;
	font-weight: 700 !important;
}
.timer__top > *,
.timer__bottom > * {
	position: relative;
	width: 40px;
	text-align: center;
}
.timer--gold .timer__bottom > * {
	color: rgba(241, 203, 98, 0.5);
}
.timer__bottom > * {
	font-size: 8px;
	color: rgba(35, 39, 55, 0.5);
}
.cb-navbar__item--notification .cb-navbar__notification {
	display: block;
}
.dropdown__menu.active {
	display: block;
	cursor: pointer;
}
.details span {
	cursor: pointer;
}
.b-vs {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: #1a1e30;
	width: 100%;
	cursor: pointer;
}
.b-vs__wrap {
	padding: 10px 12px;
}
.full-height {
	height: 100% !important;
}
.b-vs__title {
	max-width: 70%;
	margin: 0 auto 10px auto;
}
.font--15 {
	font-size: 15px;
}
.align-middle {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.b-vs__team {
	width: 30%;
	text-align: center;
	overflow: hidden;
}
.b-vs__img {
	width: 36px;
	height: 36px;
}
.m-b-10 {
	margin-bottom: 10px;
}
img {
	max-width: 100%;
	height: auto;
}
.font--semibold {
	font-weight: 700;
}
.font--white {
	color: #fff;
}
.text-ellipsis {
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}
.b-vs__center {
	width: 40%;
	position: relative;
}
.b-vs__team {
	width: 30%;
	text-align: center;
	overflow: hidden;
}
.b-vs__placeholder {
	z-index: 0;
	font-size: 80px !important;
	font-weight: 700;
	color: rgba(39, 44, 70, 0.55);
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -70%);
	-ms-transform: translate(-50%, -70%);
	transform: translate(-50%, -70%);
}
.b-vs__time {
	position: relative;
	z-index: 1;
}
.text-center {
	text-align: center;
}
.b-vs__img {
	width: 36px;
	height: 36px;
}
.b-vs__footer {
	margin-top: 28px;
}
.b-vs__coff {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 33px;
	background-color: #fff;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	cursor: pointer;
}
.b-vs__coff:hover {
	background-color: #f2f2f2;
}
.b-vs__coff.active {
	background-color: #f1cb62 !important;
	font-weight: 800 !important;
}
.b-vs__bet {
	-webkit-box-flex: 2;
	-webkit-flex: 2;
	-ms-flex: 2;
	flex: 2;
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
}
.btn--blue {
	background-color: #6c90fc;
}
.btn--h-42 {
	height: 42px;
}
.btn {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	padding: 5px 10px;
	font-size: 11px;
	font-weight: 700;
	line-height: 13px;
	border: 0;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	padding: 0 2px;
	cursor: pointer;
}
.btn > * {
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.wn-banner {
	width: 296px;
	height: 170px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	margin-right: 10px;
	cursor: pointer;
}
.wn-banner--shadow:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(11, 29, 91, 0.1);
}
.wn-banner__wrap--down {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.wn-banner__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 15px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.wn-banner__wrap,
.wn-banner__footer {
	position: relative;
	z-index: 1;
}
.wn-banner__footer {
	padding: 15px;
	display: flex;
	justify-content: center;
}
.full-width {
	width: 100% !important;
}
.font--16 {
	font-size: 16px;
}
.font--17 {
	font-size: 17px;
}
.h1,
h1 {
	font-size: 55px;
}
.m-r-5 {
	margin-right: 5px;
}

.calendar-wrapper {
	position: absolute;
	display: block;
	width: 350px;
	top: 80px;
	right: 0;
	z-index: 10;
}
.calendar-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 9;
}