.mybets__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__table {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 10px;
}
.betslip__tr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 5px;
}
.betslip__tr--title:after {
  content: '';
  position: absolute;
  right: 5px;
  top: 50%;
  width: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 12px;
  font-style: normal;
  font-family: "Font Awesome 5 Pro";
}
.betslip__td {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.betslip__tr--title {
  position: relative;
  padding: 0 20px 0 0;
  height: 30px;
  background-color: #F6F8FF;
}
.betslip__id {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: #6C90FC;
  padding: 2px 5px;
  color: #fff;
}
.betslip__tr--border {
  position: relative;
}
.betslip__td--big {
  justify-content: space-between;
  width: 100%;
}
.betslip__td--small {
  width: 20%;
}
.betslip__td--grow {
  width: 80%;
}
.betslip__td--big, .betslip__td--small, .betslip__td--grow {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}
.betslip__table--down .betslip__tr--title:after {
  content: '\f106';
}
.betslip__table--up .betslip__tr--title:after {
  content: '\f107';
}
.betslip__tr--border:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 5px;
  left: 5px;
  height: 1px;
  background-color: rgba(194, 211, 212, 0.5);
}
.cashout__message.enter-done, .cashout__message.exit {
	height: 40px;
}
.cashout__message {
	display: flex;
	margin: 5px 10px;
	padding: 10px;
	background-color: rgba(241, 203, 98, 0.5);
	z-index: 5;
	height: 0;
	font-size: 15px;
	color: #000;
	align-items: center;
	justify-content: center;
}
.cashout__error {
	background-color: rgba(194, 211, 212, 0.5);
}
.cashout__message.enter {
	height: 0;
	overflow-y: hidden;
}
.cashout__message.enter-active {
	height: 40px;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
}
.cashout__message.enter-done {
	height: 40px;
}
.cashout__message.exit {
	height: 40px;
}
.cashout__message .exit-active {
	height: 0;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 300ms ease;
}
.container__wrap .t-block {
	margin-bottom: 5px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
}
.container__wrap .t-block:last-child {
	margin-bottom: 0;
}
.container__wrap .t-block--down .t-subcategory {
	background-color: #7a7f94;
}
.container__wrap .t-block--down .t-subcategory:after {
	-webkit-transform: translateY(-50%) rotateZ(180deg);
	-ms-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotateZ(180deg);
	color: #fff;
}
.container__wrap .t-block--down .t-subcategory__right .font {
	color: rgba(255, 255, 255, 0.5);
}
.container__wrap .t-block--down .t-table {
	display: block;
}
.container__wrap .t-subcategory {
	height: 40px;
	background-color: #c4cada;
	position: relative;
	padding-right: 20px;
}
.container__wrap .t-subcategory:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	color: inherit;
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.container__wrap .t-subcategory__left {
	padding: 0 5px 0 10px;
}
.container__wrap .t-subcategory__right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 20px 0 5px;
}
.container__wrap .t-subcategory__right .font {
	color: #232737;
}
.container__wrap .t-subcategory__icon {
	position: absolute;
	right: 10px;
}
.container__wrap .t-table {
	display: none;
}
.container__wrap .t-table__tr {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.container__wrap .t-table__tr--sm {
	min-height: 35px;
}
.container__wrap .t-table__tr--md {
	min-height: 52px;
}
/*.container__wrap .   {*/
/*	display: -webkit-box;*/
/*	display: -webkit-flex;*/
/*	display: -ms-flexbox;*/
/*	display: flex;*/
/*	-webkit-box-align: center;*/
/*	-webkit-align-items: center;*/
/*	-ms-flex-align: center;*/
/*	align-items: center;*/
/*	-webkit-box-flex: 1;*/
/*	-webkit-flex: 1;*/
/*	-ms-flex: 1;*/
/*	flex: 1;*/
/*	position: relative;*/
/*	padding: 5px 10px;*/
/*	height: 100%;*/
/*}*/
.container__wrap .t-table__td {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
	padding: 5px 10px;
	height: 100%;
}
.page .container__wrap .t-table__td--sm {
  flex: 0 1;
  min-width: 60px;
}
.page .container__wrap .t-table__option {
  width: 40px;
  height: 40px;
  background-color: #e1e5ee;
  border: 1px solid #c4cada;
  border-radius: 2px;
}
.container__wrap .t-table__td:after {
	content: '';
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 0;
	width: 1px;
	background-color: rgba(194, 211, 212, 0.5);
}
.container__wrap .t-table__td:last-child:after {
	display: none;
}
.container__wrap .t-table__td--column {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 98vw;
}
.container__wrap .t-table__button {
	margin: 0 auto;
}
.container__wrap .t-table__button > * {
	margin: 0 2px;
}
.container__wrap .t-table__button.disabled {
	background: #c4cada;
}
.container__wrap .spinner__outer {
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.page .container__wrap .t-table__button {
	background-color: rgba(241, 203, 98, 0.5);
	border-color: #f1cb62;
	margin: 0 auto;
}
.page .container__wrap .t-dropdown {
	display: none;
}
.page .container__wrap .t-dropdown--active {
	display: block;
}
.page .container__wrap .t-tab {
  width: 100%;
}
.page .container__wrap .t-tab__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  border-bottom: 1px solid #e7e7e7;  
}

.page .container__wrap .t-tab__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1 1;
  padding: 0 5px;
  position: relative;
  color: #383a4a;
}
.page .container__wrap .t-tab__link--active {
  color: #6c90fc;
}
.page .container__wrap .t-tab__link:after {
  width: 0;
  height: 2px;
  position: absolute;
  left: 25%;
  right: 0;
  bottom: 0;
  background-color: #6c90fc;
  content: "";
	transition: width .5s;
}
.page .container__wrap .t-tab__link--active:after {
  width: 64px;
}
.page .container__wrap .t-tab__link:hover:after {
  width: 64px;
}
.page .container__wrap .t-tab__body {
  padding: 10px;
}
.container__wrap .spinner {
	width: 35px;
	height: 35px;
}
.t-table .btn .spinner {
	width: 25px;
	height: 25px;
}

.bspa {
	padding: 2px 5px;
	border-radius: 2px;
}
.bspa__open {
	display: none;
	color: #fff;
	background-color: rgba(18, 60, 247, 0.5);
}
.bspa__win {
	border: 1px solid #15b93a;
	background-color: rgba(21, 185, 58, 0.5);
}
.bspa__loose {
	border: 1px solid #b95115;
	color: #b95115;
	/* background-color: rgba(211, 73, 91, 0.5); */
}
.bspa__canceled {
	border: 1px solid #383A4A;
	color: #383A4A;
	/*background-color: rgba(21, 185, 58, 0.5);*/
}
.bspa__cashedout {
	border: 1px solid #f1cb62;
	color: #383A4A;
	background-color: #f1cb62;
}
.bspa__push {
	border: 1px solid #383A4A;
	color: #383A4A;
}
.bspa__canceled {
	border: 1px solid #383a4a5b;
	color: #383A4A;
}
.bspa__halfwin {
	color: #000;
	border: 1px solid #383a4a;
}
.bspa__halfwin  span{
	color: #15b93a;
}
.bspa__halfloose {
	color: #000;
	border: 1px solid #383a4a;
}
.bspa__halfloose span{
	color: #b95115;
}