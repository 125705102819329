.ip-scoreboard {
	width: 100%;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	position: relative;
}
@media screen and (min-width: 1400px) {
    .ip-scoreboard{
        background-size: cover;
    }
}
.ip-scoreboard:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 0;
}
.ip-scoreboard__header {
    position: relative;
    z-index: 1;
    height: 40px;
}
.ip-scoreboard__option {
	position: absolute;
    right: 10px;
    top: 10px;

}
.ip-scoreboard-teams {
    position: absolute;
		left: 36%;
    right: 32%;
    top: 6px;

}
.ip-scoreboard-teams-enter {
    opacity: 0;
}
.ip-scoreboard-teams-enter-active {
    opacity: 1;
    transition: opacity .3s 200ms;
}
.ip-scoreboard-teams-exit {
    opacity: 1;
}
.ip-scoreboard-teams-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
.ip-scoreboard-team-name:first-child::after {
    content: 'vs';
    margin-left: 10px;
}
.ip-scoreboard__favourite:after {
	content: '\f005';
	font-size: 14px;
	font-weight: 300;
	font-family: 'Font Awesome 5 Pro';
	color: #f1cb62;
}
.ip-scoreboard__favourite:hover:after,
.ip-scoreboard__favourite--active:after {
	content: '\f005';
	font-weight: 900;
}
.ip-scoreboard__hide {
	border-color: rgba(255, 255, 255, 0.5) !important;
    width: 100px !important;
    padding: 0px !important;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.ip-scoreboard__hide:hover,
.ip-scoreboard__hide--active {
	border-color: #fff;
}
.ip-scoreboard__hide:hover > *,
.ip-scoreboard__hide--active > * {
	color: #fff;
}
.ip-scoreboard__body--wrapper {
	width: 100%;
	max-width: 900px;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.ip-scoreboard__body--wrapper-enter {
	max-height: 0px;
}
.ip-scoreboard__body--wrapper-enter-active {
	max-height: 165px;
	transition: max-height 0.2s linear;
}
.ip-scoreboard__body--wrapper-exit {
	max-height: 165px;
}
.ip-scoreboard__body--wrapper-exit-active {
    max-height: 0px;
    transition: max-height 0.2s linear;
}

.ip-scoreboard__body--wrapper-enter .ip-scoreboard__body{
    transform: translate(0, -120%);
    opacity: 0;
}
.ip-scoreboard__body--wrapper-enter-active .ip-scoreboard__body{
    transform: translate(0, 0);
    opacity: 1;
    transition: transform 0.2s cubic-bezier(.11,.71,.3,.71), opacity .1s 0.2s cubic-bezier(.11,.71,.3,.71);
}
.ip-scoreboard__body--wrapper-exit .ip-scoreboard__body{
    transform: translate(0, 0);
    opacity: 1;
}
.ip-scoreboard__body--wrapper-exit-active .ip-scoreboard__body{
    transform: translate(0, -120%);
    opacity: 0;
    transition: opacity;
}
.ip-scoreboard__body {
	padding-top: 30px;
	padding-bottom: 30px;
}
.ip-scoreboard__score {
	width: 100%;
}
.ip-scoreboard__stats {
	width: 14px;
	height: 12px;
}
.ip-scoreboard__wrap {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 33%;
}
.ip-scoreboard__wrap--small {
	width: 60px;
}
.ip-scoreboard__team {
	width: 12px;
	height: 12px;
}
.ip-scoreboard__cursor {
	width: 6px;
	height: 6px;
	background-color: rgba(255, 255, 255, 0.5);
	border: 1px solid rgba(255, 255, 255, 0.5);
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.ip-scoreboard__dots {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 15px;
	position: relative;
	z-index: 0;
}
.ip-scoreboard__dots span {
	width: 6px;
	height: 6px;
	display: block;
	cursor: pointer;
	margin: 0 2px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.5);
}
.ip-scoreboard__dots span.active,
.ip-scoreboard__dots span:hover {
	background-color: #fff;
}

.ip-scoreboard__table {
	overflow: hidden;
}

.ip-scoreboard__table,
.ip-scoreboard__events {
	position: relative;
	z-index: 1;
	margin: 15px 40px 0 40px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}

/*.scoreboard-fade-enter  {*/
/*    opacity: 0;*/
/*}*/
/*.scoreboard-fade-enter-active {*/
/*    opacity: 1;*/
/*    transition: opacity 100ms;*/
/*}*/
/*.scoreboard-fade-exit {*/
/*    opacity: 1;*/
/*}*/
/*.scoreboard-fade-exit-active {*/
/*    opacity: 0;*/
/*    transition: opacity 100ms;*/
/*}*/

.ip-scoreboard__events {
	padding: 5px 0;
	height: 72px;
	overflow: hidden;
	background-color: rgba(56, 58, 74, 0.95);
}

/*.ev-enter-active,.ev-enter-done,.ev-exit, .ev-exit-active{*/
/*    overflow: visible;*/
/*}*/
/*.ev-enter .ip-scoreboard__block{*/
/*    max-height: 72px;*/
/*    transition: max-height .3s linear;*/
/*}*/
/*.ev-enter-done .ip-scoreboard__block , .ev-enter-active .ip-scoreboard__block , .ev-exit .ip-scoreboard__block {*/
/*    max-height: 225px;*/
/*    transition: max-height .3s linear;*/
/*}*/
/*.ev-exit-active .ip-scoreboard__block{*/
/*    max-height: 72px;*/
/*    transition: max-height .3s linear;*/
/*}*/

/*.ev-enter-active .ip-scoreboard__more ,*/
/*.ev-enter-done .ip-scoreboard__more ,*/
/*.ev-exit .ip-scoreboard__more,*/
/*.ev-exit-active .ip-scoreboard__more{*/
/*    position: static;*/
/*}*/
/*.ev-exit-done .ip-scoreboard__more {*/
/*    position: absolute;*/
/*}*/
/*.ip-scoreboard__more:after {*/
/*    transform: rotate(0);*/
/*    transition: transform .3s linear;*/
/*}*/
/*.ev-enter-active .ip-scoreboard__more:after ,*/
/*.ev-enter-done .ip-scoreboard__more:after ,*/
/*.ev-exit .ip-scoreboard__more:after,*/
/*.ev-exit-active .ip-scoreboard__more:after {*/
/*    transform: rotate(-180deg);*/
/*    transition: transform .3s linear;*/
/*}*/
/*.ev-enter .scroll, .ev-enter-active .scroll, .ev-exit .scroll, .ev-exit-active .scroll {*/
/*    overflow-x: hidden;*/
/*    overflow-y: hidden;*/
/*}*/

.ip-scoreboard__event {
	padding: 2px 5px;
}
.ip-scoreboard__event:first-child {
	margin-top: 0;
}
.ip-scoreboard__event:last-child {
	margin-bottom: 0;
}
.ip-scoreboard__block {
	position: relative;
	z-index: 1;
	background-color: rgba(56, 58, 74, 0.95);
}
.ip-scoreboard__more {
	position: absolute;
	left: 0;
	right: 0;
	top: 62px;
	height: 13px;
	background-color: rgba(56, 58, 74, 0.95);
	color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	-webkit-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
	z-index: 1;
}
.ip-scoreboard__more:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}
.ip-scoreboard__points {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	min-width: 15px;
	max-width: 15px;
	height: 19px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.ip-scoreboard__points--blue {
	background-color: #6c90fc;
}
.ip-scoreboard__points--gold {
	background-color: #f1cb62;
}
.ip-scoreboard__points--green {
	background-color: #15b93a;
}
.ip-scoreboard__field {
	position: absolute;
	top: 50%;
	left: 10px;
	max-height: 35px;
	max-width: 50px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.ip-scoreboard__table {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	position: relative;
	background-color: rgba(56, 58, 74, 0.5);
	padding: 0px 20px;
}
.ip-scoreboard__table .table {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}
.ip-scoreboard__table .column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.ip-scoreboard__table .column:first-child {
	overflow: hidden;
	max-width: 50%;
}
.ip-scoreboard__table .column:first-child .th {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.ip-scoreboard__table .column:last-child {
	border-bottom: 0;
}
.ip-scoreboard__table .th {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 24px;
	padding: 0 5px;
	border-bottom: 1px solid rgba(216, 216, 216, 0.2);
}
.ip-scoreboard__table .th:last-child {
	border-bottom: 0;
}
.ip-scoreboard__table .th > * {
	width: 100%;
	text-align: center;
	margin: 0 auto;
}
.ip-scoreboard__table .th--big {
	height: 48px;
	padding-left: 60px;
	position: relative;
}
.ip-scoreboard__table .th--right > * {
	text-align: right;
}
.ip-scoreboard__table .th--left > * {
	text-align: left;
}
.ip-scoreboard__table .th .text-ellipsis {
	max-width: 100%;
}
.ip-scoreboard__table .th .dropdown {
	position: static;
}
.ip-scoreboard__table .th .dropdown__toggle {
	width: 10px;
	height: 10px;
}
.ip-scoreboard__table .th .dropdown__menu {
	right: 0;
	left: 0;
	background-color: #f1f3f7;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	z-index: 3;
}
.ip-scoreboard__table .th .dropdown .title {
	background-color: #e1e5ee;
	height: 33px;
	padding: 0 10px;
}
.ip-scoreboard__table .th .dropdown .table--market .column:last-child .th {
	border-right: 0;
}
.ip-scoreboard__table .th .dropdown .table--market .th {
	border-bottom: 1px solid #e1e5ee;
	border-right: 1px solid #e1e5ee;
}
.ip-scoreboard__table .th .dropdown .table .th {
	height: 38px;
	border: 0;
}
.ip-scoreboard__table .th .dropdown .table .th:first-child {
	background-color: #e1e5ee;
}
.ip-scoreboard__table .th .dropdown .table .btn {
	border: 0;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0 10px;
	background-color: transparent;
}
.ip-scoreboard__table .th .dropdown .table .btn:hover,
.ip-scoreboard__table .th .dropdown .table .btn--active {
	background-color: #e1e5ee;
}
