.wn-banner-slider {
	position: relative;
	overflow: hidden;
	padding-bottom: 24px;
}
.wn-banner-slider__dots {
	height: 24px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}
.wn-banner-slider__dots span {
	width: 16px;
	height: 4px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: rgba(108, 144, 252, 0.5);
	margin: 0 5px;
	cursor: pointer;
}
.wn-banner-slider__dots span:hover,
.wn-banner-slider__dots span.active {
	-webkit-box-shadow: 0px 0px 4px #a4baff;
	box-shadow: 0px 0px 4px #a4baff;
	background-color: #6c90fc;
}
.wn-banner-slider__wrap {
	/* overflow: hidden; */
	transition: transform 1s;
}

.wn-banner {
	width: 296px;
	height: 170px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	margin-right: 10px;
}
.wn-banner__wrap,
.wn-banner__footer {
	position: relative;
	z-index: 1;
}
.wn-banner__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 24px;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.wn-banner__btn {
	border-color: #f1cb62 !important;
	width: auto !important;
	padding: 0 10px;
	margin: 5px auto 0 0;
}
.wn-banner__btn:hover,
.wn-banner__btn--active {
	background-color: #f1cb62;
}
.wn-banner__btn:hover > *,
.wn-banner__btn--active > * {
	color: #0c1022;
	font-weight: bold;
	transition: none !important;
}
.wn-banner__coff {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #fff;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 5px 10px;
	max-width: 76px;
	margin-right: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-right: 1px solid #c2d3d4;
}
.wn-banner__coff:last-child {
	border-right: 0;
}
.wn-banner__coff:hover,
.wn-banner__coff--active {
	background-color: #e6e6e6;
}
.wn-banner--shadow:hover::before {
	background: rgba(11, 29, 91, 0.1);
}
.wn-banner--shadow:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(11, 29, 91, 0.2);
}

.wn-nav {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	background-color: #0c1022;
}

.wn-popular {
	background-color: #0c1022;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
}
.wn-popular__title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 24px;
	padding: 5px 10px;
	background-color: #2f3346;
	margin-bottom: 1px;
}
.wn-popular__item {
	width: 100%;
	height: 40px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	padding: 5px 10px;
	margin-bottom: 1px;
	background-color: #1a1e30;
}
.wn-popular__item:hover > .font,
.wn-popular__item--active > .font {
	color: #fff;
}

.wn-top-matches {
	background-color: #f1f3f7;
}
.wn-top-matches__title {
	padding: 10px;
}
.wn-top-matches__slider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	padding: 10px;
	overflow: hidden;
}
.wn-top-matches__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 270px;
	height: 160px;
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 6px rgba(56, 71, 89, 0.19);
	box-shadow: 0px 0px 6px rgba(56, 71, 89, 0.19);
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 15px;
}
.wn-top-matches__body {
	padding: 15px 10px 10px;
}
.wn-top-matches__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.wn-top-matches__cell {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin: 0 5px;
}
.wn-top-matches__team {
	width: 36px;
	height: 28px;
	margin: 0 auto 5px auto;
}
.wn-top-matches__team img {
	display: block;
	height: 100%;
	margin: 0 auto;
}
.wn-top-matches__btn {
	min-width: 56px;
	margin-top: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 5px 10px;
}
.wn-top-matches__footer {
	width: 100%;
	height: 38px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(194, 211, 212, 0.5);
}

.wn-classification__tab {
	width: 100%;
	height: 38px;
	margin-bottom: 1px;
}

.wn-classification__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-right: 1px;
	background-color: #1a1e30;
	position: relative;
}
.wn-classification__link:after {
	width: 0;
	height: 1px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f1cb62;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.wn-classification__link:last-child {
	margin-right: 0;
}
.wn-classification__link:hover,
.wn-classification__link--active {
	color: #f1cb62;
}
.wn-classification__link:hover:after,
.wn-classification__link--active:after {
	width: 100%;
}
.wn-classification__link i {
	font-size: 14px;
}

.wn-classification__body {
	background-color: #0c1022;
}

.wn-classification-sport__header {
	width: 100%;
	height: 39px;
	position: relative;
	cursor: pointer;
	padding: 5px 30px 5px 12px;
	background-color: #1a1e30;
	margin-bottom: 1px;
}
/* .wn-classification-sport__header:before {
    content: '';
    width: 6px;
    height: 10px;
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/icon/dots.svg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
} */
.wn-classification-sport__header.to_live::after {
	display: none;
}
.wn-classification-sport {
	z-index: 20;
}
/*.wn-classification-sport__header .drag-handle {*/
/*	width: 6px;*/
/*	height: 10px;*/
/*	content: '';*/
/*	position: absolute;*/
/*	left: 10px;*/
/*	top: 50%;*/
/*	transform: translateY(-50%);*/
/*	background-image: url(../img/icon/dots.svg);*/
/*	background-size: cover;*/
/*	background-position: 50% 50%;*/
/*	background-repeat: no-repeat;*/
/*}*/
/* .wn-classification-sport__header:before {
	width: 6px;
	height: 10px;
	content: '';
	position: absolute;
	left: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-image: url('../img/icon/dots.svg');
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
} */
.wn-classification-sport__header:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-size: 16px;
	/* color: #9a9b9f; */
	color: #fff;
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.wn-classification-sport__header .count {
	margin-left: auto;
	/* color: rgba(154, 155, 159, 0.5); */
	color: #fff;
}
.wn-classification-sport__header .text {
	margin: 0 5px;
}
.wn-classification-sport__header:hover {
	background-color: #232737;
}
.wn-classification-sport__header:hover .text {
	color: #f1cb62;
}

.wn-classification-sport__body {
	display: none;
}

.wn-classification-sport--active .wn-classification-sport__header {
	background-color: #232737;
}
.wn-classification-sport--active .wn-classification-sport__header:after {
	content: '\f106';
}
.wn-classification-sport--active .wn-classification-sport__header .text {
	color: #f1cb62;
}

.wn-classification-sport--active .wn-classification-sport__body {
	display: block;
}
.wn-classification-sport__body .ip-competition__country {
	width: 20px;
	height: 20px;
}
.wn-classification-league {
	margin-bottom: 1px;
	display: block;
}
.wn-classification-league__header {
	width: 100%;
	height: 39px;
	padding: 5px 10px 5px 22px;
	position: relative;
	cursor: pointer;
	background-color: #15192c;
}
.wn-classification-league__header.active .text {
	color: #f1cb62;
}
.wn-classification-league__header .count {
	margin-left: auto;
	color: rgba(154, 155, 159, 0.5);
}
.wn-classification-league__header .text {
	margin-right: 10px;
}
.wn-classification-league__header:hover .text {
	color: #f1cb62;
}
.wn-classification-league__header:hover .wn-classification-league__favourite {
	display: block;
}
.wn-classification-league__favourite {
	position: absolute;
	top: 0;
	right: 24px;
	cursor: pointer;
	-webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%);
	display: none;
}
.wn-classification-favourites {
	position: absolute;
	top: 12px;
	left: 8px;
	cursor: pointer;
	/* -webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%); */
	display: block;
}
.wn-classification-favourites:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 12px;
	color: #383a4a;
}
.wn-classification__link:hover .wn-classification-favourites:after {
	color: #f1cb62;
}
.wn-classification-league__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 12px;
	color: #383a4a;
}
.wn-classification-league__favourite--active:after,
.wn-classification-league__favourite:hover:after {
	color: #f1cb62;
}
.wn-classification-league--active .text {
	color: #f1cb62;
}

.wn-classification-region {
	margin-bottom: 1px;
}
.wn-classification-region__header {
	width: 100%;
	height: 39px;
	padding: 5px 10px;
	background-color: #1a1e30;
	position: relative;
	cursor: pointer;
	margin-bottom: 1px;
}
.wn-classification-region__header .count {
	margin-left: auto;
	color: rgba(154, 155, 159, 0.5);
}
.wn-classification-region__header .text {
	margin-right: 10px;
}
.wn-classification-region__header:hover .text {
	color: #fff;
}
.wn-classification-region__body {
	display: none;
}
.wn-classification-region__header .text-ellipsis {
	max-width: 80% !important;
}
.wn-classification-region--active .wn-classification-region__header .text {
	color: #fff;
}
.wn-classification-region--active .wn-classification-region__body {
	display: block;
}

.wn-classification-bar {
	width: 100%;
	height: 44px;
	background-color: #232737;
	margin-top: 1px;
	overflow: hidden;
}
.wn-classification-bar:hover .wn-classification-prev {
	display: flex;
}
.wn-classification-bar:hover .wn-classification-next {
	display: flex;
}
.wn-classification-bar__wrap {
	width: 100%;
	padding: 8px 12px;
	transition: transform 1s;
}
.wn-classification-bar__wrap::-webkit-scrollbar {
	display: none;
}
.wn-classification-bar__item {
	width: auto;
	height: 28px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-border-radius: 15px;
	border-radius: 15px;
	margin-right: 10px;
	padding: 0 10px;
	background-color: #2f3346;
}
.wn-classification-bar__item:hover,
.wn-classification-bar__item--active {
	-webkit-box-shadow: 0px 0px 6px rgba(9, 13, 18, 0.4);
	box-shadow: 0px 0px 6px rgba(9, 13, 18, 0.4);
}
.wn-classification-bar__item:hover .wn-classification-bar__text,
.wn-classification-bar__item--active .wn-classification-bar__text {
	color: #f1cb62;
}
.wn-classification-bar__item:hover .wn-classification-bar__icon svg g,
.wn-classification-bar__item:hover .wn-classification-bar__icon svg rect,
.wn-classification-bar__item:hover .wn-classification-bar__icon svg path,
.wn-classification-bar__item--active .wn-classification-bar__icon svg g,
.wn-classification-bar__item--active .wn-classification-bar__icon svg rect,
.wn-classification-bar__item--active .wn-classification-bar__icon svg path {
	opacity: 1;
	fill: #f1cb62;
}
.wn-classification-bar__text {
	color: #8c8e97;
}
.wn-classification-bar__icon {
	width: 16px;
	height: 16px;
	overflow: hidden;
	margin-right: 5px;
}
.wn-classification-bar__icon svg g,
.wn-classification-bar__icon svg rect,
.wn-classification-bar__icon svg path {
	opacity: 0.5;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.wn-classification-prev,
.wn-classification-next {
	position: absolute;
	top: 0px;
	z-index: 2;
	cursor: pointer;
	border: none;
	height: 100%;
	padding: 0px 10px;
	background: #1a1e3080;
	color: #8c8e97;
	display: none;
}
.wn-classification-prev {
	left: 0px;
}
.wn-classification-next {
	right: 0px;
}
.wn-classification-prev:hover,
.wn-classification-next:hover {
	color: #f1cb62;
	background: #131625a6;
}
.wn-classification-prev::before,
.wn-classification-next::before {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 25px;
	font-weight: 300;
}
.wn-classification-prev::before {
	content: '\f104';
}
.wn-classification-next::before {
	content: '\f105';
}
.wn-control-bar {
	background: #ffffff;
	border-radius: 0px;
	border-bottom: 1px solid #e1e1e1;
}
.wn-control-bar .ip-control-bar__link span {
	color: #6c90fc;
	font-size: 13px;
	font-weight: 500;
}
