.ip-bonus {
	height: 34px;
	background-color: #1a1e30;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}
.ip-bonus__wrap {
	padding: 0 15px 0 5px;
}
.ip-bonus__placeholder {
	padding: 2px 5px;
	background-color: #15b93a;
	margin-right: 8px;
}
.ip-bonus__hr {
	width: 1px;
	height: 16px;
	background-color: #15b93a;
	border: 0;
	margin: 0 8px;
}
.ip-bonus__close {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transform: translate(45%, -45%);
	-ms-transform: translate(45%, -45%);
	transform: translate(45%, -45%);
	background-color: rgba(154, 155, 159, 0.5);
	-webkit-border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
}
.ip-bonus__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: translate(80%, -10%);
	-ms-transform: translate(80%, -10%);
	transform: translate(80%, -10%);
	color: #9a9b9f;
	font-size: 10px;
}

.ipn-control-bar {
	height: 34px;
	-webkit-border-radius: 2px 2px 0px 0px;
	border-radius: 2px 2px 0px 0px;
	background-color: #2d334c;
}
.ipn-control-bar__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 2;
	-webkit-flex: 2;
	-ms-flex: 2;
	flex: 2;
	position: relative;
	cursor: pointer;
	height: 100%;
	transition: opacity 0.1s linear 0.2s;
}
.ipn-control-bar__item > * {
	color: #9a9b9f !important;
}
.ipn-control-bar__item > i {
	font-size: 12px;
	color: #9a9b9f;
}
.ipn-control-bar__item:after {
	width: 1px;
	height: 20px;
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #0c1022;
}
.ipn-control-bar__item:before {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f1cb62;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.ipn-control-bar__item:last-child {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.ipn-control-bar__item:last-child:after {
	display: none;
}
.ipn-control-bar__item--active > *,
.ipn-control-bar__item:hover > * {
	color: #f1cb62;
}
.ipn-control-bar__item--active:before,
.ipn-control-bar__item:hover:before {
	width: 100%;
}
.ipn-control-bar__item--menu i {
	font-weight: 300;
	font-style: normal;
	font-family: 'Font Awesome 5 Pro';
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.ipn-control-bar__item--menu i:after {
	content: '\f323';
}
.ipn-classification {
	position: relative;
}
.ipn-classification-favourites-wrapper {
}
/*.ipn-favourites-item__header {*/
/*    width: 100%;*/
/*    height: 39px;*/
/*    border-bottom: 1px solid #0C1022;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*    background-color: rgba(45, 51, 76, 0.41);*/
/*    padding: 5px 30px 5px 15px;*/
/*}*/

.ipn-classification__header,
.ipn-favourites-item__header {
	width: 100%;
	height: 39px;
	border-bottom: 1px solid #0c1022;
	position: relative;
	cursor: pointer;
	background-color: rgba(45, 51, 76, 0.41);
	padding: 5px 30px 5px 15px;
	opacity: 1;
}

.ipn-classification__header:hover .ipn-classification__favourite--icon {
	display: block;
}
.ipn-classification__favourite--icon.active {
	display: block;
}
.ipn-favourites-item__header .ipn-classification__favourite--icon {
	display: block;
	top: 20px;
	right: 30px;
}
.ipn-favourites-item__header .ipn-classification__favourite--icon::after {
	color: #f1cb62;
}
.ipn-classification__favourite--icon.active:after,
.ipn-classification__favourite--icon:hover:after {
	color: #f1cb62 !important;
}
.ipn-classification__header:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 5px;
	background: -webkit-gradient(
			linear,
			left bottom,
			left top,
			from(rgba(56, 58, 74, 0.3)),
			to(rgba(56, 58, 74, 0.3))
		),
		-webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
	background: -o-linear-gradient(
			bottom,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		-o-linear-gradient(left, currentColor 0%, currentColor 100%);
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%);
}
.ipn-classification__header:after,
.ipn-favourites-item__header:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-size: 14px;
	color: #fff;
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(0deg);
	-ms-transform: translateY(-50%) rotate(0deg);
	transform: translateY(-50%) rotate(0deg);
	transition: transform 0.3s linear;
}
.wn-classification-sport--active .ipn-classification__header,.ipn-classification__header:hover {
	background: -webkit-gradient(
			linear,
			left bottom,
			left top,
			from(rgba(56, 58, 74, 0.3)),
			to(rgba(56, 58, 74, 0.3))
		),
		-webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor)) !important;
	background: -o-linear-gradient(
			bottom,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		-o-linear-gradient(left, currentColor 0%, currentColor 100%) !important;
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%) !important;
}
.ipn-classification__body {
	position: relative;
	background-color: #15192c;
}
.ipn-classification__body.appear,
.ipn-classification-favourites-wrapper__body.appear,
.ipn-classification__body.appear-active,
.ipn-classification-favourites-wrapper__body.appear-active,
.ipn-classification__body.appear-done,
.ipn-classification-favourites-wrapper__body.appear-done {
	max-height: 2000px;
}
.ipn-classification__body.enter,
.ipn-classification-favourites-wrapper__body.enter {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
	overflow: hidden;
}
.ipn-classification__body.enter-active,
.ipn-classification-favourites-wrapper__body.enter-active {
	max-height: 2000px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
	overflow: hidden;
}
.ipn-classification__body.enter-done,
.ipn-classification-favourites-wrapper__body.enter-done {
	max-height: 2000px;
	overflow: visible;
}
.ipn-classification__body.exit,
.ipn-classification-favourites-wrapper__body.exit {
	max-height: 2000px;
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
}
.ipn-classification__body.exit-active,
.ipn-classification-favourites-wrapper__body.exit-active {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
	overflow: hidden;
}
.ipn-classification__favourite--icon {
	position: absolute;
	top: 12px;
	right: 28px;
	cursor: pointer;
	/* -webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%); */
	display: none;
}
.ipn-classification__favourite--icon:after {
	height: 16px;
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 16px;
	color: #383a4a;
}
.aside--small .ipn-classification__favourite--icon:hover:after {
	display: none;
}
.ipn-classification--down .ipn-classification__icon svg {
	fill: #fff !important;
	stroke: #fff !important;
}
.ipn-classification__icon {
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.wn-icon-big {
	width: 24px;
	height: 24px;
}
.ip-classification-sport-icon {
	margin-right: 10px;
}
.ipn-classification__text {
	margin-right: 10px;
}
.ipn-classification__count {
	margin-left: auto;
}
.ipn-classification__hidden {
	display: none;
}
.ipn-classification--down .ipn-classification__header,
.favourites-wrapper__down .ipn-classification__header {
	background: -webkit-gradient(
			linear,
			left bottom,
			left top,
			from(rgba(56, 58, 74, 0.3)),
			to(rgba(56, 58, 74, 0.3))
		),
		-webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
	background: -o-linear-gradient(
			bottom,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		-o-linear-gradient(left, currentColor 0%, currentColor 100%);
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%);
}
.arrow-up .ipn-classification__header:after,
.favourites-wrapper__down .ipn-classification__header:after {
	transform: translateY(-50%) rotate(180deg);
}
.ipn-classification--down .ipn-classification__icon svg {
	fill: #fff !important;
	stroke: #fff !important;
}
/*.ipn-classification--down .ipn-classification__body {*/
/*    max-height: 1000px;*/
/*    transition: max-height .3s linear;*/
/*}*/
.ipn-classification-favourites-wrapper {
	color: #fdff01;
}
.ipn-classification--american_football {
	color: #629d5b;
}
.ipn-classification--bandy {
	color: #4ed7ff;
}
.ipn-classification--baseball {
	color: #ff8e00;
}
.ipn-classification--basketball {
	color: #ff845c;
}
.ipn-classification--beach_volleyball {
	color: #ffaf3f;
}
.ipn-classification--bowls {
	color: #6488a7;
}
.ipn-classification--boxing {
	color: #ff1515;
}
.ipn-classification--cricket {
	color: #d86308;
}
.ipn-classification--cycling {
	color: #79b35c;
}
.ipn-classification--darts {
	color: #ed4830;
}
.ipn-classification--e-sports {
	color: #c364c5;
}
.ipn-classification--floorball {
	color: #985970;
}
.ipn-classification--formula_1 {
	color: #578986;
}
.ipn-classification--futsal {
	color: #cabb76;
}
.ipn-classification--golf {
	color: #70c448;
}
.ipn-classification--handball {
	color: #d37831;
}
.ipn-classification--ice_hockey {
	color: #00b6ec;
}
.ipn-classification--motorbikes {
	color: #badbad;
}
.ipn-classification--pool {
	color: #b0d437;
}
.ipn-classification--rugby_league {
	color: #d79a7e;
}
.ipn-classification--snooker {
	color: #e5191a;
}
.ipn-classification--soccer {
	color: #30a01a;
}
.ipn-classification--speedway {
	color: #37b0d4;
}
.ipn-classification--table_tennis {
	color: #5e9fde;
}
.ipn-classification--tennis {
	color: #e1e31c;
}
.ipn-classification--volleyball {
	color: #ffaf3d;
}
.ipn-classification--water_polo {
	color: #a6caf0;
}
.aside--small .ipn-classification:hover .ipn-classification__header {
	background: -webkit-gradient(
			linear,
			left bottom,
			left top,
			from(rgba(56, 58, 74, 0.3)),
			to(rgba(56, 58, 74, 0.3))
		),
		-webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor)) !important;
	background: -o-linear-gradient(
			bottom,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		-o-linear-gradient(left, currentColor 0%, currentColor 100%) !important;
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%) !important;
}

.aside--small .ipn-classification:hover .ipn-classification__icon svg {
	fill: #fff !important;
	stroke: #fff !important;
}

.aside--small .ipn-classification__header {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0;
}
.aside--small .ipn-classification__header:after,
.aside--small .ipn-classification__header:before {
	display: none;
}

.aside--small .ipn-classification__icon {
	margin-right: 0;
}
.aside--small .ipn-classification__icon svg {
	fill: currentColor;
	stroke: currentColor;
}

.aside--small .ipn-classification__text,
.aside--small .ipn-classification__count {
	display: none;
}
.aside--small .ipn-classification__body,
.aside--small .ipn-classification-favourites-wrapper__body {
	display: none;
}

.ipn-classification__body--small {
	width: 440px;
	position: absolute;
	left: 10px;
	z-index: 61;
	background: #1a1e30;
	/* max-height: 280px; */
}
.ipn-classification__body--small .ipn-classification__body-inner {
	max-height: 250px;
}
.ipn-classification__body--small .ipn-classification__hidden {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 39px;
	padding: 0 10px;
	background-color: #232737;
	border-bottom: 1px solid #383a4a;
}
.ipn-classification__body--small .ipn-competition__favourite {
	top: 20px;
	right: 25px;
}
.aside--small .ipn-control-bar__item {
	max-width: 0px;
	min-width: 0px;
	opacity: 0;
	overflow: hidden;
}
.aside--small .ipn-control-bar__item--menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	max-width: 100%;
	opacity: 1;
}
.aside--small .ipn-control-bar__item--menu i {
	-webkit-transform: rotateZ(180deg);
	-ms-transform: rotate(180deg);
	transform: rotateZ(180deg);
}
.aside--small .ipn-fixture__header {
	height: 32px;
	background-color: rgba(67, 69, 89, 0.3);
	padding: 5px 10px;
	border-bottom: 1px solid #383a4a;
}
.aside--small .ipn-fixture__header:after {
	display: none;
}

.aside--small .ipn-fixture__time {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid #c2d3d4;
}

.aside--small .ipn-fixture__team {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	padding-left: 10px;
	padding-right: 5px;
}

.aside--small .ipn-fixture__score {
	margin-left: auto;
	margin-right: 0;
}
.aside--small .ipn-fixture__score .text-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.aside--small .ipn-fixture__score table {
	border-collapse: collapse;
}
.aside--small .ipn-fixture__score table td {
	padding-left: 2px;
}

.aside--small .ipn-fixture__separate {
	display: block;
}

/*.ipn-classification__body--small .ipn-fixture__favourite {*/
/*    display: block;*/
/*    position: relative;*/
/*    -webkit-transform: none;*/
/*    -ms-transform: none;*/
/*    transform: none;*/
/*    margin-left: 10px;*/
/*}*/
/*.aside--small .ipn-fixture__favourite:after {*/
/*    content: '\f005';*/
/*    font-weight: 300;*/
/*    font-size: 14px;*/
/*}*/
/*.aside--small .ipn-fixture__favourite:hover:after, .aside--small .ipn-fixture__favourite--active:after {*/
/*    font-weight: 900;*/
/*}*/

.ipn-competition-enter {
	opacity: 0;
}
.ipn-competition-enter-active {
	opacity: 1;
	transition: opacity 0.1s linear;
}

.ipn-competition-exit {
	opacity: 1;
	max-height: 1000px;
	transition: opacity 0.2s linear,
		max-height 0.3s cubic-bezier(0.15, 0.59, 0.36, 0.93);
}
.ipn-competition-exit-active {
	opacity: 0;
	max-height: 0px;
	transition: opacity 0.2s linear,
		max-height 0.3s cubic-bezier(0.15, 0.59, 0.36, 0.93);
}

.ipn-competition__header {
	width: 100%;
	height: 39px;
	border-bottom: 1px solid #0c1022;
	position: relative;
	cursor: pointer;
	background-color: rgba(45, 51, 76, 0.41);
	padding: 5px 30px 5px 15px;
}
.ipn-competition__header:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-size: 14px;
	color: #9a9b9f;
	position: absolute;
	right: 10px;
	top: 55%;
	-webkit-transform: translateY(-50%) rotate(0deg);
	-ms-transform: translateY(-50%) rotate(0deg);
	transform: translateY(-50%) rotate(0deg);
	transition: transform 0.3s linear;
}
.ipn-competition__header:hover .font {
	color: #fff;
}
.wn-classification-sport__header:hover .ipn-competition__favourite,
.wn-classification-region__header:hover .ipn-competition__favourite,
.wn-classification-league__header:hover .ipn-competition__favourite,
.ipn-competition:hover .ipn-competition__favourite,
.ipn-competition__favourite.active{
	display: block;
}
.simplebar-content>.ipn-competition:hover .ipn-competition__favourite:after{
	position: absolute;
	right: 7px;
	top: -7px;
}
.ipn-competition__body {
	max-height: 0px;
	overflow: hidden;
}
.ipn-competition__body.appear,
.ipn-competition__body.appear-active,
.ipn-competition__body.appear-done {
	max-height: 2000px;
	overflow: visible;
}
.ipn-competition__body.enter {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
}
.ipn-competition__body.enter-active {
	max-height: 2000px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
}
.ipn-competition__body.enter-done {
	max-height: 21000px;
	overflow: visible;
}
.ipn-competition__body.exit {
	max-height: 2000px;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
}
.ipn-competition__body.exit-active {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
}

.ipn-competition__favourite {
	position: absolute;
	top: 12px;
	right: 28px;
	cursor: pointer;
	/* -webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%); */
	display: none;
}
.ipn-competition__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 14px;
	color: #383a4a;
}
.ipn-competition__favourite--active:after,
.ipn-competition__favourite:hover:after,
.ipn-competition__favourite.active:after {
	color: #f1cb62;
}

.ipn-competition--down .ipn-competition__header:after {
	transform: translateY(-50%) rotate(180deg);
}

/*.ipn-competition--down .ipn-competition__body {*/
/*    max-height: 1000px; */
/*}*/

.ipn-competition__icon {
	width: 16px;
	height: 16px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 10px;
}

.ipn-competition__text {
	margin-right: 10px;
}
.ipn-competition__empty-message {
	padding: 5px 30px 5px 15px;
	min-height: 35px;
	text-align: center;
}
.ipn-fixture {
	display: block;
}
.ipn-fixture-enter {
	opacity: 0;
}
.ipn-fixture-enter-active {
	opacity: 1;
	transition: opacity 200ms;
}
.ipn-fixture-exit {
	opacity: 1;
}
.ipn-fixture-exit-active {
	opacity: 0;
	transition: opacity 200ms;
}
.ipn-fixture:hover .ipn-fixture__header .font,
.ipn-fixture.active .ipn-fixture__header .font {
	color: #fff;
}

/*.ipn-fixture:hover .ipn-fixture__favourite {*/
/*    display: block;*/
/*}*/

.ipn-fixture__header {
	width: 100%;
	height: 54px;
	border-bottom: 1px solid #0c1022;
	position: relative;
	cursor: pointer;
	padding: 5px 10px;
}

.ipn-fixture__body {
	display: none;
	border-bottom: 1px solid #0c1022;
}

/*.ipn-fixture__favourite {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 5px;*/
/*    cursor: pointer;*/
/*    -webkit-transform: translateY(-60%);*/
/*    -ms-transform: translateY(-60%);*/
/*    transform: translateY(-60%);*/
/*    display: none; }*/
/*.ipn-fixture__favourite:after {*/
/*    content: '\f005';*/
/*    font-family: "Font Awesome 5 Pro";*/
/*    font-weight: 900;*/
/*    font-size: 16px;*/
/*    color: #383A4A; }*/
/*.ipn-fixture__favourite--active:after, .ipn-fixture__favourite:hover:after {*/
/*    color: #F1CB62; }*/

.ipn-fixture__separate {
	display: none;
}

.ipn-fixture__score,
.ipn-fixture__team,
.ipn-fixture__time {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	height: 100%;
}

.ipn-fixture__score {
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
}

.ipn-fixture__team {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 0 2px;
	overflow: hidden;
}

.ipn-fixture__time {
	max-width: 10%;
	width: 100%;
}
.ipn-fixture__time-light {
	font-size: 20px;
	position: absolute;
	right: 0px;
	top: 0px;
	animation: time-light 1s infinite;
}
@keyframes time-light {
	0% {
		color: #d7dede;
	}
	100% {
		color: #888888;
	}
}

.ipn-fixture__row {
	border-bottom: 1px solid #0c1022;
}
.ipn-fixture__row:last-child {
	border-bottom: 0;
}

.ipn-fixture__cell {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
}
.ipn-fixture__cell:after {
	width: 1px;
	height: 20px;
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #0c1022;
}
.ipn-fixture__cell:last-child:after {
	display: none;
}

.ipn-fixture__btn {
	background-color: #191e32;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between !important;
	-ms-flex-pack: justify;
	justify-content: space-between !important;
	padding: 0 8px !important;
}
.ipn-fixture__btn:hover,
.ipn-fixture__btn--active {
	background-color: #222843;
}
.ipn-fixture__btn-lock {
	justify-content: center !important;
	padding: 0 8px !important;
	background-color: #191e32;
	cursor: auto;
}

.ipn-fixture__btn-lock i {
	font-size: 13px;
}

.ipn-fixture__table {
	width: auto;
	margin-right: 0;
	margin-left: auto;
}

.ipe-header {
	width: 100%;
	height: 40px;
	background: #fff;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
	position: relative;
	padding: 0 10px;
}
.ipe-header__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 10px;
	height: 100%;
	position: relative;
}
.ipe-header__link:after {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.ipe-header__link:hover:after,
.ipe-header__link--active:after {
	width: 100%;
}
.ipe-header__icon {
	margin-left: 10px;
	cursor: pointer;
	position: relative;
}
.ipe-header__icon > i {
	font-size: 12px;
}
.ipe-header__icon:hover > .ipe-header__dropdown,
.ipe-header__icon--active > .ipe-header__dropdown {
	display: block;
}
.ipe-header__dropdown {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	width: 260px;
	z-index: 1;
	background-color: #fff;
	border: 1px solid rgba(203, 201, 201, 0.59);
	-webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.125738);
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.125738);
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-transform: translateY(10px);
	-ms-transform: translateY(10px);
	transform: translateY(10px);
}
.ipe-header__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 5px 10px;
	min-height: 35px;
	border-bottom: 1px solid rgba(154, 155, 159, 0.5);
}
.ipe-header__item:last-child {
	border-bottom: 0;
}
.ipe-header__item.dropdown {
	width: 100%;
	padding: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.ipe-header__item.dropdown .dropdown__toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 35px;
	padding: 0 10px;
}
.ipe-header__item.dropdown .dropdown__toggle:after {
	right: 14px;
	font-size: 18px;
	color: #232737;
}
.ipe-header__item.dropdown .dropdown__menu {
	padding: 0 10px;
	position: static;
	background-color: transparent;
	border-top: 1px solid rgba(154, 155, 159, 0.5);
}
.ipe-header__item.dropdown .dropdown__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 35px;
	padding: 0;
	border-bottom: 1px solid rgba(154, 155, 159, 0.5);
}
.ipe-header__item.dropdown .dropdown__item:last-child {
	border-bottom: 0;
}
.ipe-header__table {
	border-collapse: collapse;
}
.ipe-header__table tr:first-child {
	background-color: #f4f4f4;
}
.ipe-header__table td {
	text-align: center;
	padding: 2px 4px;
}

.market-line {
	cursor: pointer;
	padding: 9px 7px;
	background: #162238;
	border-left: 1px solid darkgoldenrod;
}
.market-line:hover {
	border-radius: 5%;
	border-left: 3px solid darkgoldenrod;
	border-right: 3px solid darkgoldenrod;
}
.market-line:hover .font--grey-300 {
	color: white;
}
/* .wn-classification-sport__header:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 5px;
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%);
}
 */
