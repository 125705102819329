@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../FontAwesome/fa-regular-400.eot);
  src: url(../FontAwesome/fa-regular-400.eot?#iefix) format('embedded-opentype'),
    url(../FontAwesome/fa-regular-400.woff2) format('woff2'),
    url(../FontAwesome/fa-regular-400.woff) format('woff'),
    url(../FontAwesome/fa-regular-400.ttf) format('truetype'),
    url(../FontAwesome/fa-regular-400.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(../FontAwesome/fa-light-300.eot);
  src: url(../FontAwesome/fa-light-300.eot?#iefix) format('embedded-opentype'),
    url(../FontAwesome/fa-light-300.woff2) format('woff2'),
    url(../FontAwesome/fa-light-300.woff) format('woff'),
    url(../FontAwesome/fa-light-300.ttf) format('truetype'),
    url(../FontAwesome/fa-light-300.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../FontAwesome/fa-solid-900.eot);
  src: url(../FontAwesome/fa-solid-900.eot?#iefix) format('embedded-opentype'),
    url(../FontAwesome/fa-solid-900.woff2) format('woff2'),
    url(../FontAwesome/fa-solid-900.woff) format('woff'),
    url(../FontAwesome/fa-solid-900.ttf) format('truetype'),
    url(../FontAwesome/fa-solid-900.svg#fontawesome) format('svg');
}

.fal {
  font-weight: 300;
}

.far {
  font-weight: 400;
}

.fal,
.far {
  font-style: normal;
  font-family: 'Font Awesome 5 Pro';
}

.fa,
.fas {
  font-style: normal;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.far {
  font-size: 18px;
  font-style: normal;
}
.far.fa-microphone-alt:after {
  content: '\f3c9';
}

.fas.fa-eye:after {
  content: '\f06e';
}
.fas.fa-sync-alt:after {
  content: '\f2f1';
}
.fas--active.fa-sync-alt:after {
  content: '\f2f1' !important;
  color: darkgreen;
}
.fas--active.fa-sync-alt:hover:after {
  content: '\f2f1' !important;
  color: darkslategrey;
}
.fas.fa-volume:after {
  content: '\f6a8';
}

.fas.fa-star:after {
  content: '\f005';
}

.fal {
  font-size: 18px;
  font-style: normal;
}
.fal.fa-envelope:after {
  content: '\f0e0';
}
.fal.fa-angle-left:after {
  content: '\f104';
}
.fal.fa-angle-right:after {
  content: '\f105';
}
.fal.fa-angle-up:after {
  content: '\f106';
}
.fal.fa-angle-down:after {
  content: '\f107';
}
.fal.fa-search:after {
  content: '\f002';
}
.fal.fa-eye:after {
  content: '\f06e';
}
.fal.fa-redo-alt:after {
  content: '\f2f9';
}
.fal.fa-times:after {
  content: '\f00d';
}
.fal.fa-address-card:after {
  content: '\f2bb';
}
.fal.fa-trash-alt:after {
  content: '\f2ed';
}
.fal.fa-envelope-open:after {
  content: '\f2b6';
}
.fal.fa-copy:after {
  content: '\f0c5';
}
.fal.fa-calendar-alt:after {
  content: '\f073';
}
.fal.fa-external-link-alt:after {
  content: '\f35d';
}
.lv-translation__icon.pinned .fal.fa-thumbtack {
  background-image: url('../img/icon/pin_active.svg');
}
.lv-translation__icon .fal.fa-thumbtack {
  background-image: url('../img/icon/pin_not_active.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  /* transform: scale(0.75, 1) rotate(45deg); */
}
.fal.fa-thumbtack:after {
  /*content: '\f08d';*/
}
.fal.fa-play:after {
  content: '\f04b';
}
.fal.fa-play:after {
  content: '\f04b';
  font-size: 11px;
  font-weight: bolder;
}

.fal.fa-trophy:after {
  content: '\f091';
}
.fal.fa-bell:after {
  content: '\f0f3';
}
.fal.fa-tshirt:after {
  content: '\f553';
}
.fal.fa-lock-alt:after {
  content: '\f30d';
}

.c-icon {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border: 1px solid transparent;
}
.c-icon--playing {
  width: 15px;
  height: 12px;
  background-image: url('../img/icon/nostream.svg');
}
.c-icon--rejected {
  position: absolute;
  margin-left: 2px;
  width: 16px;
  height: 10px;
  background-image: url('../img/icon/rejected.svg');
}
.c-icon--no-play {
  width: 18px;
  height: 14px;
  margin: 0 2px;
  background-image: url('../img/icon/no-play.svg');
}
.c-icon--play {
  width: 15px;
  height: 12px;
  background-image: url('../img/icon/play.svg');
}

.c-icon--no-anim {
  opacity: 0.5;
}
.c-icon--video, .c-icon--video:hover {
  height: 17px;
  width: 20px;
  margin: 0px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='18' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23189970' stroke='%23fff'/%3E%3Cpath d='M9 10V3l6 3.5z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--video.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='%23B5B5B5'%3E%3Cpath d='M22.788.238H.212v12.524h22.576V.238zm-.762.761V12H.974V.999h21.052z'/%3E%3Cpath d='M8.625 10V3l6.708 3.501z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--1, .c-icon--1:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23347a37' stroke='%23fff' stroke-linecap='square'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M11 1h1.001v3.5H11zM11 8.5h1.001V12H11zM22.029 10h-3.003V3h3.003v1h-2.001v5h2.001zM1.045 2.983l3.003.034-.087 7-3.003-.034.017-1L2.976 9l.054-5-2.002-.017z' opacity='.8'/%3E%3C/g%3E%3Cpath d='M9.5 6.5c0-1.167.885-2 2-2 1.147 0 2 .833 2 2 0 1.042-.885 2-2 2-1.147 0-2-.958-2-2z' stroke='%23d3e2d3'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--1.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23B5B5B5' d='M22.5.5v12H.5V.5z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M11.014 1h1.001v3.5h-1.001zm0 7.5h1.001V12h-1.001zM22.029 10h-3.003V3h3.003v1h-2.001v5h2.001zM1.045 2.983l3.003.034-.087 7-3.003-.034.017-1L2.976 9l.054-5-2.002-.017z'/%3E%3Cpath stroke='%23B5B5B5' d='M9.5 6.5c0-1.167.885-2 2-2 1.147 0 2 .833 2 2 0 1.042-.885 2-2 2-1.147 0-2-.958-2-2z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--5, .c-icon--5:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%235c8026' stroke='%23fff' stroke-linecap='square'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M7.014 6h9v1h-9z' opacity='.5'/%3E%3Cpath d='M11.014 1h1.001v11h-1.001z' opacity='.9'/%3E%3Cpath d='M6.014 1h1.001v11H6.014zM16.014 1h1.001v11h-1.001z' opacity='.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--5.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='square' d='M.5.5h22v12H.5z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M7.014 6h9v1h-9z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M11.014 1h1.001v11h-1.001zm-5 0h1.001v11H6.014zm10 0h1.001v11h-1.001z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--2, .c-icon--2:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23c18c00' stroke='%23fff' stroke-linecap='square'/%3E%3Cpath d='M11 1h1.001v4H11zM11 8h1.001v4H11z' fill='%23fff' opacity='.8'/%3E%3Cg stroke='%23fff'%3E%3Ccircle cx='11.5' cy='6.5' opacity='.9' r='2'/%3E%3Cpath d='M.5 3l2.377.04S6 3.04 6 6.5 2.964 9.942 2.964 9.942L.5 10M22.5 3l-2.377.04S17 3.04 17 6.5s3.036 3.442 3.036 3.442L22.5 10' opacity='.8'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--2.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='square' d='M.5.5h22v12H.5z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M11 1h1.001v4H11zm0 7h1.001v4H11z'/%3E%3Cg stroke='%23B5B5B5' transform='translate(0 3)'%3E%3Ccircle cx='11.5' cy='3.5' r='2'/%3E%3Cpath d='M.5 0l2.377.04S6 .04 6 3.5 2.964 6.942 2.964 6.942L.5 7m22-7l-2.377.04S17 .04 17 3.5s3.036 3.442 3.036 3.442L22.5 7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--3, .c-icon--3:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='19' viewBox='0 0 19 19' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5 9.5l9-9.192 9 9.192-9 9.192z' fill='%235e733f'/%3E%3Cg fill='%23eb9a67'%3E%3Cpath d='M3.306 12.499A3.498 3.498 0 0 0 5 9.5a3.498 3.498 0 0 0-1.694-2.999L.308 9.5zM13.992 9.5c0-1.272.678-2.386 1.693-2.999l3 2.999-3 2.999h.316c-1.015-.613-2.01-1.727-2.01-2.999zM9.488 5.05c-1.272 0-2.161-.44-2.774-1.454l2.774-3 2.77 3C11.645 4.61 10.76 5.05 9.488 5.05zM9.487 13.969c-1.272 0-2.162.44-2.775 1.455l2.775 3 2.77-3c-.613-1.016-1.498-1.455-2.77-1.455z'/%3E%3C/g%3E%3Cpath d='M.5 9.5l9-9.192 9 9.192-9 9.192z' stroke='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--3.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='1 0 19 19'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M1.308 9.5L10.5.308 19.692 9.5 10.5 18.692z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M4.306 12.499A3.498 3.498 0 0 0 6 9.5a3.498 3.498 0 0 0-1.694-2.999L1.308 9.5l2.998 2.999zM14.992 9.5c0-1.272.678-2.386 1.693-2.999l3 2.999-3 2.999h.316c-1.015-.613-2.01-1.727-2.01-2.999zm-4.504-4.45c-1.272 0-2.161-.44-2.774-1.454l2.774-3 2.77 3c-.613 1.015-1.498 1.454-2.77 1.454zm-.001 8.919c-1.272 0-2.162.44-2.775 1.455l2.775 3 2.77-3c-.613-1.016-1.498-1.455-2.77-1.455z' opacity='.1'/%3E%3Cpath stroke='%23B5B5B5' d='M1.5 9.5l9-9.192 9 9.192-9 9.192z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--waterpollo, .c-icon--waterpollo:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke-linecap='square'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%230ea8e6' stroke='%23fff'/%3E%3Cpath d='M11.5 1.5v10' opacity='.9' stroke='%23fff'/%3E%3Cg stroke-dasharray='2'%3E%3Cpath d='M14.5 1.5v10m-6-10v10' opacity='.4' stroke='%23ffe200'/%3E%3Cpath d='M5.5 1.5v10m12-10v10' opacity='.25' stroke='red'/%3E%3C/g%3E%3C/g%3E%3Cg stroke='%23fff'%3E%3Cpath d='M0 4.5h2.5v4H0M23 4.5h-2.5v4H23' opacity='.9'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--waterpollo.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke-linecap='square'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%230ea8e6' stroke='%23fff'/%3E%3Cpath d='M11.5 1.5v10' opacity='.9' stroke='%23fff'/%3E%3Cg stroke-dasharray='2'%3E%3Cpath d='M14.5 1.5v10m-6-10v10' opacity='.4' stroke='%23ffe200'/%3E%3Cpath d='M5.5 1.5v10m12-10v10' opacity='.25' stroke='red'/%3E%3C/g%3E%3C/g%3E%3Cg stroke='%23fff'%3E%3Cpath d='M0 4.5h2.5v4H0M23 4.5h-2.5v4H23' opacity='.9'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--4, .c-icon--4:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Crect fill='%23aedee6' height='12' rx='3' stroke='%23fff' width='22' x='.5' y='.5'/%3E%3Cg stroke='%23cc8392'%3E%3Ccircle cx='5' cy='4' r='1.5'/%3E%3Ccircle cx='5' cy='9' r='1.5'/%3E%3Ccircle cx='18' cy='4' r='1.5'/%3E%3Ccircle cx='18' cy='9' r='1.5'/%3E%3Cpath d='M11.5 1.5v3m0 4v3'/%3E%3C/g%3E%3Ccircle cx='11.5' cy='6.5' fill='%23aedee6' r='2' stroke='%237697c2'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--4.not-active{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23B5B5B5'%3E%3Crect width='22' height='12' x='.5' y='.5' rx='3'/%3E%3Cg transform='translate(3 1)'%3E%3Ccircle cx='2' cy='3' r='1.5'/%3E%3Ccircle cx='2' cy='8' r='1.5'/%3E%3Ccircle cx='15' cy='3' r='1.5'/%3E%3Ccircle cx='15' cy='8' r='1.5'/%3E%3Cpath d='M8.5.5v3m0 4v3'/%3E%3Ccircle cx='8.5' cy='5.5' r='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--23, .c-icon--23:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23b36e60' stroke='%23fff' stroke-linecap='square'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M11.014 1h1.001v11h-1.001z' opacity='.9'/%3E%3Cpath d='M6.014 1h1.001v11H6.014zM16.014 1h1.001v11h-1.001z' opacity='.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--23.not-active{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M22.5 12.5H.5V.5h22z' stroke='%23b5b5b5'/%3E%3Cpath d='M16 1h1v11h-1zm-5 0h1v11h-1zM6 1h1v11H6z' fill='%23b5b5b5' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--22, .c-icon--22:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='19' viewBox='0 0 19 19' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' fill='%23fff'%3E%3Cpath d='M8.573 8.677l7.071 4.95-2.121 2.12zm0 0l-7.07-4.95 2.12-2.121zm0 0l-4.95 7.07-2.12-2.12zm7.08-5.135L8.5 8.5l5.032-7.08zM8.5 8.5l1.51 8.5h-3zm0 0l8.536-1.35v3zm0 0L7 0h3zm0 0L0 10V7z' fill-rule='evenodd'/%3E%3C/mask%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-3 -3)'%3E%3Ccircle cx='12.5' cy='12.5' fill='%23333' r='9' stroke='%23fff'/%3E%3Ccircle cx='12.5' cy='12.5' r='5' stroke='%23aa212e'/%3E%3Cpath d='M12.5 12.5L14 21h-3zm0 0L11 4h3zm0 0L4 14v-3zm0 0L21 11v3z' fill='%23d5bfaf'/%3E%3Cpath d='M12.622 12.71l4.877-6.967 2.122 2.121-6.962 4.873 6.985 4.89-2.121 2.12-4.893-6.99-.08.057.06-.086-.037-.051zm-.049-.033l-7.07-4.95 2.12-2.121zm0 0l-4.95 7.07-2.12-2.12z' fill='%23d5bfaf'/%3E%3Ccircle cx='12.5' cy='12.5' fill='%23aa212e' r='1' stroke='%23aa212e'/%3E%3Ccircle cx='8.5' cy='8.5' mask='url(%23a)' r='5' stroke='%2307804a' transform='translate(4 4)'/%3E%3Ccircle cx='12.5' cy='12.5' r='8' stroke='%23333'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--22.not-active{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-3 -3)'%3E%3Ccircle cx='12.5' cy='12.5' r='9' stroke='%23B5B5B5'/%3E%3Ccircle cx='12.5' cy='12.5' r='5' stroke='%23B5B5B5' opacity='.1'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M12.5 12.5L14 21h-3l1.5-8.5zm0 0L11 4h3l-1.5 8.5zm0 0L4 14v-3l8.5 1.5zm0 0L21 11v3l-8.5-1.5z' opacity='.12'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M12.573 12.677l7.07 4.95-2.12 2.12-4.95-7.07zm0 0l-7.071-4.95 2.121-2.121 4.95 7.07zm0 0l-4.95 7.07-2.121-2.12 7.07-4.95zm-.028-.07l4.95-7.07 2.12 2.121-7.07 4.95z' opacity='.12'/%3E%3Ccircle cx='12.5' cy='12.5' r='1' stroke='%23B5B5B5'/%3E%3C/g%3E%3C/svg%3E");
}

.c-icon-glow:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1a1e30;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}
.c-icon-glow:hover:before {
  transform: scale(1.1);
  box-shadow: 1px 1px 15px #1a1e30;
}
.c-icon-glow {
  color: #cea850;
  box-shadow: 1px 1px 5px #f1cb62; /*#1A1E30; */
  /* background-color: #CEA850; */
}
.c-icon--field {
  width: 17px;
  height: 12px;
  background-image: url('../img/icon/field.svg');
}
.c-icon--dollar {
  width: 18px;
  height: 12px;
  background-image: url('../img/icon/dollar.svg');
}
.c-icon--stats {
  width: 12px;
  height: 12px;
  background-image: url('../img/icon/stats.svg');
}
.c-icon--sort {
  width: 6px;
  height: 12px;
  background-image: url('../img/icon/sort.svg');
}
.c-icon--free-kick {
  width: 12px;
  height: 12px;
  background-image: url('../img/icon/free-kick.svg');
}
.c-icon--goal {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon/goal.svg');
}
.c-icon--y-card {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon/y-card.svg');
}
.c-icon--r-card {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon/r-card.svg');
}
.c-icon--corner {
  width: 12px;
  height: 12px;
  background-image: url('../img/icon/corner.svg');
}
.c-icon--throw-in {
}
.c-icon--goal-kick {
}
.c-icon--penalty {
  width: 12px;
  height: 12px;
  background-image: url('../img/icon/penalty.svg');
}
.c-icon--substitution {
  width: 12px;
  height: 12px;
  background-image: url('../img/icon/substitution.svg');
}
body {
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: none;
  color: #232737;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  background-color: #0c1022;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*body::-webkit-scrollbar {*/
/*	width: 8px;*/
/*}*/

/*body::-webkit-scrollbar-corner {*/
/*	background-color: #474747;*/
/*}*/
/*body::-webkit-scrollbar-thumb {*/
/*	-webkit-border-radius: 19px;*/
/*	border-radius: 19px;*/
/*	border: 2px solid transparent;*/
/*	background-color: #888;*/
/*	background-clip: content-box;*/
/*}*/
/*body::-webkit-scrollbar-track {*/
/*	background-color: #474747;*/
/*	border: 1px solid #373737;*/
/*}*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

marquee {
  width: 100%;
}

ul,
li {
  list-style: none;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

input,
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

input[type='button'],
input[type='submit'],
input[type='reset'],
button,
label,
a {
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
}

td,
tr,
form {
  margin: 0;
  padding: 0;
}

a {
  color: var(--font-color);
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
span,
div,
button {
  outline: none !important;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-row {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap !important;
  flex-direction: row !important;
}
.gll-market-group__row.flex-row .flex-50:last-child {
  max-width: 50%;
}
.flex-normal {
  -webkit-align-items: normal;
  -ms-align-items: normal;
  align-items: normal;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex-33 {
  flex: 1 1 33% !important;
}
.flex-50 {
  flex: 1 1 50% !important;
}
.align-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.align-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.align-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-justify {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-spaced {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-bottom {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-middle {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-self-top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.align-self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.align-center-middle {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-cursive {
  font-style: italic;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  max-width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.border {
  border: 0 !important;
}

.padding {
  padding: 0 !important;
}
.margin {
  margin: 0 !important;
}

.relative {
  position: relative !important;
}

.full-height {
  height: 100% !important;
}

.half-height {
  height: 50% !important;
}

.height-auto {
  height: auto !important;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.width-auto {
  width: auto !important;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  width: 8px;
}
.scroll::-webkit-scrollbar-corner {
  background-color: #474747;
}
.scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 19px;
  border-radius: 19px;
  border: 2px solid transparent;
  background-color: #888;
  background-clip: content-box;
}
.scroll::-webkit-scrollbar-track {
  background-color: #474747;
  border: 1px solid #373737;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-a-5 {
  margin: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-a-10 {
  margin: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-a-15 {
  margin: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-a-20 {
  margin: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-a-30 {
  margin: 30px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-l-5 {
  padding-left: 5px;
}
.p-a-3 {
  padding: 3px;
}
.p-a-5 {
  padding: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-a-10 {
  padding: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-a-15 {
  padding: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-a-20 {
  padding: 20px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-a-30 {
  padding: 30px;
}

body {
  margin: 0 auto;
}

.page {
  width: 100%;
  min-width: 1200px;
}

.main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
}

.content {
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
  max-height: -webkit-calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  overflow: hidden;
}

.live-wrapper {
  width: 100%;
}

/*.content.scheduleWrapper {*/
/*  max-width: calc(100% - 300px);*/
/*}*/
/*.content.overviewWrapper {*/
/*  max-width: calc(100% - 300px);*/
/*}*/

.center {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  min-height: 60vh;
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.overlay--main {
  z-index: 10;
  position: fixed;
  background-color: rgba(31, 33, 51, 0.5);
}
.overlay--additionally {
  z-index: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
}
.overlay--block {
  display: block;
}

.font {
  /*color: #232737;*/
  line-height: 15px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.font--green {
  color: #15b93a;
}
.font--red {
  color: #fc3164;
}
.font--gold {
  color: #f1cb62;
}
.font--blue {
  color: #6c90fc;
}
.font--white {
  color: #fff;
}
.font--grey-500 {
  color: #383a4a;
}
.font--grey-500-alpha-05 {
  color: rgba(56, 58, 74, 0.5);
}
.font--grey-500-alpha-08 {
  color: rgba(56, 58, 74, 0.8);
}
.font--grey-400 {
  color: #545763;
}
.font--grey-300 {
  color: #9a9b9f;
}
.font--default-alpha-05 {
  color: rgba(35, 39, 55, 0.5);
}
.font--white-alpha-05 {
  color: rgba(255, 255, 255, 0.5);
}
.font--medium {
  font-weight: 500;
}
.font--semibold {
  font-weight: 700;
}
.font--10 {
  font-size: 10px;
}
.font--11 {
  font-size: 11px;
}
.font--12 {
  font-size: 12px;
}
.font--14 {
  font-size: 14px;
}
.font--15 {
  font-size: 15px;
}
.font--16 {
  font-size: 16px;
}
.font--17 {
  font-size: 17px;
  line-height: 24px;
}
.font--19 {
  font-size: 19px;
}
.font--20 {
  font-size: 20px;
}
.font--21 {
  font-size: 21px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #232737;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
h1--green,
.h1--green,
h2--green,
.h2--green,
h3--green,
.h3--green,
h4--green,
.h4--green,
h5--green,
.h5--green,
h6--green,
.h6--green {
  color: #15b93a;
}
h1--red,
.h1--red,
h2--red,
.h2--red,
h3--red,
.h3--red,
h4--red,
.h4--red,
h5--red,
.h5--red,
h6--red,
.h6--red {
  color: #fc3164;
}
h1--gold,
.h1--gold,
h2--gold,
.h2--gold,
h3--gold,
.h3--gold,
h4--gold,
.h4--gold,
h5--gold,
.h5--gold,
h6--gold,
.h6--gold {
  color: #f1cb62;
}
h1--blue,
.h1--blue,
h2--blue,
.h2--blue,
h3--blue,
.h3--blue,
h4--blue,
.h4--blue,
h5--blue,
.h5--blue,
h6--blue,
.h6--blue {
  color: #6c90fc;
}
h1--white,
.h1--white,
h2--white,
.h2--white,
h3--white,
.h3--white,
h4--white,
.h4--white,
h5--white,
.h5--white,
h6--white,
.h6--white {
  color: #fff;
}
h1--medium,
.h1--medium,
h2--medium,
.h2--medium,
h3--medium,
.h3--medium,
h4--medium,
.h4--medium,
h5--medium,
.h5--medium,
h6--medium,
.h6--medium {
  font-weight: 500;
}
h1--semibold,
.h1--semibold,
h2--semibold,
.h2--semibold,
h3--semibold,
.h3--semibold,
h4--semibold,
.h4--semibold,
h5--semibold,
.h5--semibold,
h6--semibold,
.h6--semibold {
  font-weight: 700;
}

.h1,
h1 {
  font-size: 55px;
}

.h2,
h2 {
  font-size: 41px;
}

.h3,
h3 {
  font-size: 32px;
}

.h4,
h4 {
  font-size: 30px;
}

.h5,
h5 {
  font-size: 25px;
}

.h6,
h6 {
  font-size: 22px;
}

.btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  border: 0;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.btn::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.btn::-moz-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.btn:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.btn--h-14 {
  height: 14px;
}
.btn--h-18 {
  height: 18px;
}
.btn--h-22 {
  height: 22px;
}
.btn--h-24 {
  height: 24px;
}
.btn--h-26 {
  height: 26px;
}
.btn--h-28 {
  height: 28px;
}
.btn--h-30 {
  height: 30px;
}
.btn--h-32 {
  height: 32px;
}
.btn--h-36 {
  height: 36px;
}
.btn--h-40 {
  height: 40px;
}
.btn--h-42 {
  height: 42px;
}
.btn--h-44 {
  height: 44px;
}
.btn--green {
  background-color: #15b93a;
}
.btn--green:hover {
  background-color: #1ce549;
}
.btn--red {
  background-color: #fc3164;
}
.btn--red:hover {
  background-color: #fd638a;
}
.btn--gold {
  background-color: #f1cb62;
}
.btn--gold:hover {
  background-color: #f5db91;
}
.btn--blue {
  background-color: #6c90fc;
}
.btn--blue:hover {
  background-color: #9eb6fd;
}
.btn--white {
  background-color: #fff;
}
.btn--white:hover {
  background-color: white;
}
.btn--grey-600 {
  background-color: #2f3346;
}
.btn--grey-600:hover {
  background-color: #434965;
}
.btn > * {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.btn--outline {
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
}
/* styling a snake */
:root {
  --bg: #3c465c;
  --primary: #78ffcd;
  --solid: #fff;
  --btn-w: 1em;
  --dot-w: calc(var(--btn-w) * 0.2 - 10px);
  --tr-X: 0;
  --tr-Y: 0;
  --half-h: calc(var(--tr-Y) / 2 - 15px);
}
.dot {
  content: '';
  position: absolute;
  top: -10px;
  left: -8px;
  bottom: -10px;
  right: -8px;
  width: var(--dot-w);
  height: 100%;
  border-radius: 0;
  transition: all 300ms ease;
  display: none;
  transform-origin: center center;
}
.dot:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0.4em;
  height: 0.9em;
  width: 0.9em;
  background: #6c90fc;
  border-radius: 1em;
  border: 0.25em solid var(--solid);
  box-shadow: 0 0 0.7em var(--solid), 0 0 2em var(--primary);
  z-index: 20;
}
.btn--coff.qb:hover .dot:after {
  animation: atom 2s infinite linear;
}
.btn--coff.qb:hover .dot {
  display: block;
}
@keyframes atom {
  0% {
    transform: translate(3px, 3px);
  }
  33% {
    transform: translate(var(--tr-X), 3px);
  }
  50% {
    transform: translate(var(--tr-X), var(--tr-Y));
  }
  83% {
    transform: translate(3px, var(--tr-Y));
  }
  100% {
    transform: translate(3px, 3px);
  }
}
/* Spinner */
.btn--coff .spinner {
  display: none;
  position: absolute;
  left: calc(0px - var(--tr-X));
  top: calc(var(--half-h) - var(--tr-Y));
  width: calc(var(--tr-X) * 3);
  height: calc(var(--tr-Y) * 3);
}
.btn--coff.gll-market-group__btn .spinner,
.btn--coff.gll-market-group__btn--center .spinner {
  left: calc(0px - var(--tr-X) + 0.2rem);
  top: calc(var(--half-h) - var(--tr-Y) - 0.35rem);
}
.gll-market-group__btn-group div.btn--coff {
  border: 0;
}
.btn--coff.qb .spinner {
  overflow: visible;
  display: block;
}
.btn--coff .spinner span {
  position: absolute;
  background-color: #6c90fc;
}
.btn--coff.active .spinner span {
  background-color: lightcyan;
}
.btn--coff .spinner .top-spinner {
  top: calc(var(--tr-Y) + 1px);
  left: -3px;
  height: 4px;
  width: var(--tr-X);
  animation: filling-top 2000ms;
}
.btn--coff .spinner .right-spinner {
  top: 1px;
  left: calc(var(--tr-X) * 2 - 7px);
  height: var(--tr-Y);
  width: 4px;
  animation: filling-right 2000ms;
}
.btn--coff .spinner .bottom-spinner {
  top: calc(var(--tr-Y) * 2 - 2px);
  right: 0px;
  height: 4px;
  width: var(--tr-X);
  animation: filling-bottom 2000ms;
}
.btn--coff .spinner .left-spinner {
  top: calc(2 * var(--tr-Y) + 2px);
  left: calc(var(--tr-X) - 4px);
  height: var(--tr-Y);
  width: 4px;
  animation: filling-left 2000ms;
}
@keyframes filling-top {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(var(--tr-X));
  }
  50% {
    transform: translateX(var(--tr-X));
  }
  83% {
    transform: translateX(var(--tr-X));
  }
  100% {
    transform: translateX(var(--tr-X));
  }
}
@keyframes filling-right {
  0% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(var(--tr-Y));
  }
  83% {
    transform: translateY(var(--tr-Y));
  }
  100% {
    transform: translateY(var(--tr-Y));
  }
}
@keyframes filling-bottom {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(0);
  }
  83% {
    transform: translateX(calc(0px - var(--tr-X)));
  }
  100% {
    transform: translateX(calc(0px - var(--tr-X)));
  }
}
@keyframes filling-left {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  79% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(0px - var(--tr-Y)));
  }
}
.btn--coff {
  position: relative;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
}
.gll-market-group__cell .btn--coff {
  border: 0;
  min-height: 40px;
}
.aside--small .ipn-classification__header:hover .ipn-classification__favourite--icon {
  display: none;
}
.btn--coff:hover {
  background-color: #f2f2f2;
}
.btn--up,
.btn--down {
  overflow: hidden;
  position: relative;
}
.btn--up:after,
.btn--down:after {
  width: 8px;
  height: 8px;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotateZ(-45deg);
  -ms-transform: translate(50%, -50%) rotate(-45deg);
  transform: translate(50%, -50%) rotateZ(-45deg);
}
.btn--up:after {
  background-color: #15b93a;
}
.btn--down:after {
  background-color: #fc3164;
}
.btn--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.field {
  font-style: normal;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #232737;
  line-height: 15px;
  text-transform: none;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: 0;
}

.field--error {
  color: red;
}

.field--white {
  height: 28px;
  padding: 2px 0;
  color: #fff;
  border-bottom: 1px solid #b5b7c6;
}

.field::-webkit-input-placeholder {
  color: #b5b7c6;
}
.field::-moz-placeholder {
  color: #b5b7c6;
}
.field:-ms-input-placeholder {
  color: #b5b7c6;
}
.field--main {
  height: 28px;
  padding: 2px 0;
  color: #b5b7c6;
  /* color: #232737; */
  border-bottom: 1px solid #e7e7e7;
}
.field--main::-webkit-input-placeholder {
  color: #b5b7c6;
  /* color: rgba(35, 39, 55, 0.5); */
}
.field--main::-moz-placeholder {
  color: #b5b7c6;
  /* color: rgba(35, 39, 55, 0.5); */
}
.field--main:-ms-input-placeholder {
  color: #b5b7c6;
  /* color: rgba(35, 39, 55, 0.5); */
}

.select {
  font-style: normal;
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  color: var(--font-color);
  line-height: var(--line-height);
  text-transform: none;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(181,183,198,0.5)'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 8px;
  background-position: right center;
  background-repeat: no-repeat;
}
.select::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.select::-moz-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.select:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.select--main {
  height: 28px;
  padding: 2px 0;
  color: #232737;
  border-bottom: 1px solid #e7e7e7;
}
.select--main::-webkit-input-placeholder {
  color: rgba(35, 39, 55, 0.5);
}
.select--main::-moz-placeholder {
  color: rgba(35, 39, 55, 0.5);
}
.select--main:-ms-input-placeholder {
  color: rgba(35, 39, 55, 0.5);
}

.checkbox {
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: relative;
  color: #696a76;
}
.checkbox:before {
  width: 12px;
  height: 12px;
  content: '';
  position: absolute;
  top: 0;
  border: 0;
  background: transparent;
  border: 1px solid rgba(105, 106, 118, 0.5);
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.checkbox:after {
  content: '\2713';
  position: absolute;
  color: #000;
  font-weight: bold;
  font-size: 11px;
  opacity: 0;
  top: 5px;
  left: 5px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.checkbox:checked:after {
  opacity: 1;
  color: #696a76;
}
.checkbox:focus,
.checkbox:hover {
  border: 0;
  outline: 0;
}
.checkbox--gold {
  color: #f1cb62;
}
.checkbox--gold:before {
  border: 1px solid #f1cb62;
}
.checkbox--gold:after {
  color: #f1cb62;
}
.checkbox--gold:checked:after {
  color: #f1cb62;
}
.checkbox--blue {
  color: #fff;
}
.checkbox--blue:checked:before {
  background-color: #6c90fc;
}
.checkbox--blue:checked:after {
  color: #fff;
}

.dropdown {
  position: relative;
}
.dropdown--active .dropdown__toggle:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.dropdown--active .dropdown__menu {
  display: block;
}
.dropdown--active {
  position: relative;
  z-index: 4 !important;
}
.dropdown__toggle {
  padding-right: 10px;
  position: relative;
  cursor: pointer;
}
.dropdown__toggle:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: inherit;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.dropdown--center {
  right: -6px !important;
  top: 50% !important;
  transform: translate(0, -50%);
}
.dropdown__menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  z-index: 61;
  background-color: #232737;
  min-width: 150px;
}
.h-submenu__dropdown .dropdown__menu.odds {
  min-width: 250px;
}
.dropdown__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: middle;
  -webkit-align-items: middle;
  -ms-flex-align: middle;
  align-items: middle;
  padding: 5px 10px;
}

.radio {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-left: 16px;
}
.radio span {
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgba(194, 211, 212, 0.5);
}
.radio span:after {
  background-color: #6c90fc;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  display: none;
  content: '';
}
.radio input {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
  opacity: 0;
}
.radio input:checked ~ span:after {
  display: block;
}

.toggle {
  width: 20px;
  height: 12px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.toggle__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(105, 106, 118, 0.3);
}
.toggle__swipe {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 50%;
  -webkit-border-radius: inherit;
  border-radius: inherit;
  background-color: #fff;
  border: 1px solid rgba(105, 106, 118, 0.3);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.toggle__input {
  width: 0;
  height: 0;
  opacity: 0;
}
.toggle__input:checked + .toggle__swipe {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.toggle__input:checked ~ .toggle__bg {
  background-color: #6c90fc;
}
.toggle--grey-200 {
  background-color: rgba(216, 216, 216, 0.5);
}
.toggle--grey-200__swipe {
  border: 1px solid rgba(216, 216, 216, 0.5);
}

.range {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.range:focus {
  outline: none;
}
.range::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.range::-webkit-slider-thumb {
  width: 6px;
  height: 12px;
  -webkit-appearance: none;
  background: #fff;
  cursor: pointer;
  margin-top: -4px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #6c90fc;
}
.range::-moz-range-thumb {
  width: 6px;
  height: 12px;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
}
.range::-ms-thumb {
  width: 6px;
  height: 12px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #6c90fc;
}
.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #f1f3f7;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.range::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #f1f3f7;
  border-radius: 3px;
}
.range:focus::-webkit-slider-runnable-track {
  background: #e1e5ee;
}
.range::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.range::-ms-fill-lower,
.range::-ms-fill-upper {
  background: #6c90fc;
  border-radius: 3px;
}
.range:focus::-ms-fill-lower,
.range:focus::-ms-fill-upper {
  background: #6c90fc;
}
.range--big {
  height: 18px;
}
.range--big::-webkit-slider-thumb {
  width: 14px;
  height: 16px;
  margin-top: -6px;
  background-image: url('../img/service/range-block.svg');
  background-color: transparent;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.range--big::-moz-range-thumb {
  width: 14px;
  height: 16px;
  background-color: #fff;
}
.range--big::-ms-thumb {
  width: 14px;
  height: 16px;
  background-color: #fff;
}
.aside {
  max-height: 100%;
}
.aside--wrapper {
  position: relative;
  z-index: 3;
}
.aside--small {
  min-width: 58px !important;
  max-width: 58px !important;
  transition: max-width 0.1s, min-width 0.1s;
  margin-right: 8px;
}
.aside--left {
  min-width: 228px;
  max-width: 228px;
  transition: max-width 0.1s, min-width 0.1s;
  margin-right: 8px;
}

.aside--right {
  min-width: 288px;
  max-width: 288px;
  margin-left: 8px;
  transition-duration: 250ms;
}
.aside--wide {
  transition-duration: 250ms;
  min-width: 464px;
  max-width: 464px;
  margin-left: 8px;
}
.mx-h-100,
.cabinet {
  /* max-height: 100% !important; */
}
.mx-h-200 {
  max-height: 200px;
  overflow: hidden;
}
.mx-h-160 {
  max-height: 159px;
  overflow: hidden;
}
.show-more-btn {
  border-bottom: 1px solid #e7e7e7 !important;
  border-top: 0px;
  height: 35px;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 200;
  color: #727272;
}
.up::after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: inherit;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}
.down::after {
  content: '\f106';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: inherit;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}
.mx-w {
  max-width: 155px !important;
}

.height-scroll {
  max-height: -webkit-calc(100vh - 160px);
  max-height: calc(100vh - 160px);
}
.height-aside-scroll {
  max-height: -webkit-calc(100vh - 120px);
  max-height: calc(100vh - 120px);
}
.social__item {
  margin: 0 auto;
  cursor: pointer;
}
.social__item:first-child {
  margin-left: 0;
}
.social__item:last-child {
  margin-right: 0;
}
.social__link {
  width: 52px;
  height: 26px;
}
.social__link svg g {
  opacity: 0.5;
}
.social__link svg g,
.social__link svg rect,
.social__link svg path {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.social__link:hover svg g,
.social__link--active svg g {
  opacity: 1;
}
.social__link:hover svg rect,
.social__link--active svg rect {
  stroke: #6c90fc;
}
.social__link:hover svg path,
.social__link--active svg path {
  fill: #6c90fc;
}

.social--blue .social__link svg g {
  opacity: 1;
}

.social--blue .social__link svg rect {
  stroke: #6c90fc;
}

.social--blue .social__link svg path {
  fill: #6c90fc;
}

.social--blue .social__link:hover svg g,
.social--blue .social__link--active svg g {
  opacity: 1;
}

.social--blue .social__link:hover svg rect,
.social--blue .social__link--active svg rect {
  fill: #6c90fc;
}

.social--blue .social__link:hover svg path,
.social--blue .social__link--active svg path {
  fill: #fff;
}

.social--disabled .social__link {
  pointer-events: none;
}

.social--disabled .social__link svg g {
  opacity: 0.5;
}

.container {
  position: relative;
  background: #ffffff;
}
.loader-progress {
  position: absolute;
  z-index: 10;
  top: 1px;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background: #070a1a82;
}
.loader-progress-inner {
  height: 100%;
  background: #6c90fc;
  overflow: hidden;
  animation: showProgress linear 0.5s;
  transition: width 0.5s linear;
}

.loader-logo {
  background-color: rgba(3, 6, 19, 1);
}
.loader-logo_wrap {
  overflow: hidden;
  transform: scale(0);
  width: 50px;
}

/*Logo Container*/
.logo-loader-appear-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.logo-loader-exit {
  opacity: 1;
}
.logo-loader-appear-done {
  opacity: 1;
}
.logo-loader-exit-active {
  opacity: 0;
  transition: opacity 1000ms 500ms;
}

.logo-loader-appear .loader-logo_wrap {
  transform: scale(1);
  opacity: 1;
  transition: transform 1000ms linear, opacity 1000ms linear;
}
.logo-loader-appear-active .loader-logo_wrap {
  transform: scale(2);
  opacity: 1;
  transition: transform 1000ms linear, opacity 1000ms linear;
}
.logo-loader-appear-done .loader-logo_wrap {
  transform: scale(2);
  opacity: 1;
  width: 160px;
}
.logo-loader-exit-active .loader-logo_wrap {
  transform: scale(2);
  opacity: 0;
  width: 160px;
  transition: width 500ms linear, opacity 500ms 500ms linear;
}

.loader-logo_wrap {
  width: 50px;
  overflow: hidden;
  transform: scale(0);
  animation: logoScale 3.5s cubic-bezier(0.48, 0.53, 0.51, 0.99);
  transition: width 1s linear;
}
.lv-translation__streaming {
  width: 100% !important;
  height: 325px;
  /* height: 100% !important; */
}
.video-js {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  height: 100% !important;
}

@keyframes showProgress {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
.f-33 {
  flex: 1 1 33.3333%;
  border-right: 0.5px solid gainsboro;
  background: #fff;
}
.f-33 .btn {
  border-radius: 0;
}
.ui-popup {
  display: block;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 180px;
  overflow: visible;
  z-index: 11;
}
.ui-popup .flex {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  margin: 0 auto;
}
.ui-popup .flex .btn {
  margin-top: 35px;
  width: 15rem;
}
.abso {
  position: absolute;
  top: 100%;
  right: 0;
  width: 270px;
  height: 320px;
  perspective: 600px;
  z-index: 65;
}
.cube {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  transform: translateZ(-160px);
}
.cube__face {
  position: absolute;
  width: 270px;
  height: 320px;
  background: #333545;
  transition-duration: 1000ms;
  box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
}
.cube__face .h-popup__wrapper {
  position: relative;
  padding: 40px 16px 20px;
}
.cube__face--front {
  transform: translateX(10px) translateY(25px) translateZ(160px) rotateY(0deg);
}
.cube__face--bottom {
  transform: translateX(10px) translateY(175px) translateZ(0px) rotateX(-90deg);
}
.cube__face--bottom h4.error-title {
  color: rgba(255, 255, 255, 0.5);
  margin: 15px auto;
  font-size: 1.4rem;
}
.cube__face--bottom p.error {
  color: white;
  margin: 20px;
  margin-left: 35px;
  font-size: 1.1rem;
}
.cube__face--bottom .btn {
  background: #b64438;
  width: 200px;
  height: 40px;
  color: #fff;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  margin: 30px auto;
  transition: all 200ms;
  text-shadow: 1px 0.5px 0.5px black;
}
.cube__face--bottom .btn:hover {
  box-shadow: 1px 1px black, -0.1em 0 0.4em black;
  text-shadow: none;
}
.cube.show-front {
  transform: translateX(-10px) translateY(-15px) translateZ(-160px) rotateY(0deg);
}
.cube.show-bottom {
  transform: translateX(-10px) translateY(20px) translateZ(-160px) rotateX(90deg);
}
.abso.slideDown {
  animation: slideDown 0.5s ease-in-out;
}
@keyframes slideDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.highlight-up {
  color: green !important;
}
.highlight-down {
  color: red !important;
}
.simplebar-placeholder {
  max-width: 100%;
  width: auto !important;
}
.ip-competition__country {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.prematch-event {
  overflow-x: hidden;
}
.simplebar-placeholder {
  max-width: 100%;
  width: auto !important;
}

.l-game {
  width: 100%;
  height: 112px;
  display: flex;
  border-radius: 2px;
  background-color: #f6f8ff;
  overflow: hidden;
}

.l-game__left {
  width: 199px;
  height: 100%;
}

.l-game__right {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  flex-grow: 1;
}

.l-game__video {
  width: 100%;
  height: 100%;
  position: relative;
}

.l-game video {
  display: block;
  outline: none;
}

.l-game__play {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.l-game__head {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.l-game__body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 5px;
}

.l-game__icon {
  margin-right: 10px;
}

.l-game__row {
  padding: 2px 0;
}

.l-game__group {
  width: 100%;
  height: 28px;
  display: flex;
  border-radius: 2px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #e7e7e7;
  overflow: hidden;
}

.l-game__btn {
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 0 10px;
  border-radius: 0;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
}

.wn-banner--white {
  margin: 1px 0;
  background-color: #fff;
}

.top-league {
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  margin: 2px 0;
}

.top-league__header {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.top-league__headline {
  margin-right: 10px;
}

.top-league__wrap {
  margin-right: 0;
  margin-left: auto;
}

.top-league__icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;
  filter: grayscale(1);
  opacity: 0.7;
  transition: all 0.3s linear;
}

.top-league__icon--active,
.top-league__icon:hover {
  filter: grayscale(0);
  opacity: 1;
}

.top-league__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  cursor: pointer;
  padding: 0 10px;
  background-color: #f6f8ff;
  border-bottom: 1px solid rgba(194, 211, 212, 0.5);
  transition: all 0.3s linear;
  overflow: hidden;
}

.top-league__flag {
  width: 26px;
  height: 16px;
  margin-right: 10px;
}

.top-league__count {
  margin-left: auto;
  margin-right: 0;
}

.s-block {
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  margin: 2px 0;
}

.s-block__header {
  width: 100%;
  height: 41px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.ip-competition {
  width: 100%;
}

.ip-competition__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 57px;
  padding: 0 5px;
  background-color: #fff;
  border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}

.ip-competition__row--header {
  height: 38px;
  background-color: #f1f3f7;
}

.ip-competition__row--header .ip-competition__cell:first-child,
.ip-competition__row--market .ip-competition__cell:first-child {
  justify-content: flex-start;
  margin-right: 5px;
}

.ip-competition--prematch .ip-competition__cell--info {
  max-width: 60%;
}

.ip-competition__cell--info {
  width: 100%;
  max-width: 30%;
  flex: auto;
  overflow: hidden;
  margin-right: 5px;
  padding-right: 10px;
}

.ip-competition__cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 3px;
}

.ip-competition__row--market {
  height: 28px;
  background-color: #f6f8ff;
}
.btn--scroll {
  opacity: 0;
  width: 42px;
  height: 42px;
  background-color: #6c90fc;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 8;
}
.btn--scroll.active {
  opacity: 1;
  animation: fade 2s linear;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.btn--scroll * {
  color: #fff;
}
.flipmove-placeholder {
	position: absolute;
	width: 100%;
	opacity: 0;
}