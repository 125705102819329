.ip-classification-bar-sport {
    width: 100%;
    height: 44px;
    background-color: #1A1E30;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    overflow: hidden;
}

.ip-classification-bar-other {
    width: 100%;
    height: 70px;
    background-color: #1A1E30;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    overflow: hidden;
}
.ip-classification-bar-sport:hover .ip-classification-prev{
    display: flex;
}
.ip-classification-bar-sport:hover .ip-classification-next {
    display: flex;
}
.ip-classification-bar-other:hover .ip-classification-prev {
    display: flex;
}
.ip-classification-bar-other:hover .ip-classification-next {
    display: flex;
}
.ip-classification-bar__wrap {
    width: 100%;
    padding: 10px;
    -webkit-transition: transform 1s;
    -moz-transition: transform 1s;
    -ms-transition: transform 1s;
    -o-transition: transform 1s;
    transition: transform 1s;
}
.ip-classification-bar__wrap::-webkit-scrollbar {
    display: none;
}
.ip-classification-bar__item {
    width: 90px;
    height: 100%;
    cursor: pointer;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-shadow: 0px 0px 6px rgba(56, 71, 89, 0.2);
    box-shadow: 0px 0px 6px rgba(56, 71, 89, 0.2);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #2F3346;
    margin-right: 5px;
    padding-top: 5px;
}
.ip-classification-bar__item:hover .ip-classification-bar__text, .ip-classification-bar__item--active .ip-classification-bar__text {
    color: #F1CB62;
}
.ip-classification-bar__item:hover .ip-classification-bar__icon svg g,
.ip-classification-bar__item:hover .ip-classification-bar__icon svg rect,
.ip-classification-bar__item:hover .ip-classification-bar__icon svg path, .ip-classification-bar__item--active .ip-classification-bar__icon svg g,
.ip-classification-bar__item--active .ip-classification-bar__icon svg rect,
.ip-classification-bar__item--active .ip-classification-bar__icon svg path {
    opacity: 1;
    fill: #F1CB62;
}
.ip-classification-bar__block {
    height: 50%; }
.ip-classification-bar__text {
    color: #8C8E97; }
.ip-classification-bar__icon {
    width: 16px;
    height: 16px;
    overflow: hidden; }
.ip-classification-bar__icon svg g,
.ip-classification-bar__icon svg rect,
.ip-classification-bar__icon svg path {
    opacity: 0.5;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }

.ip-classification-header {
    width: 100%;
    height: 38px;
    background-color: #1A1E30;
}
.ip-classification-header--american_football {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#629D5B), to(#629D5B));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #629D5B 0%, #629D5B 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #629D5B 0%, #629D5B 100%); }
.ip-classification-header--bandy {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#4ED7FF), to(#4ED7FF));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #4ED7FF 0%, #4ED7FF 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #4ED7FF 0%, #4ED7FF 100%); }
.ip-classification-header--baseball {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#FF8E00), to(#FF8E00));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #FF8E00 0%, #FF8E00 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FF8E00 0%, #FF8E00 100%); }
.ip-classification-header--basketball {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#FF845C), to(#FF845C));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #FF845C 0%, #FF845C 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FF845C 0%, #FF845C 100%); }
.ip-classification-header--beach_volleyball {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#FFAF3F), to(#FFAF3F));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #FFAF3F 0%, #FFAF3F 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FFAF3F 0%, #FFAF3F 100%); }
.ip-classification-header--bowls {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#6488A7), to(#6488A7));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #6488A7 0%, #6488A7 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #6488A7 0%, #6488A7 100%); }
.ip-classification-header--boxing {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#FF1515), to(#FF1515));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #FF1515 0%, #FF1515 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FF1515 0%, #FF1515 100%); }
.ip-classification-header--cricket {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#D86308), to(#D86308));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #D86308 0%, #D86308 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #D86308 0%, #D86308 100%); }
.ip-classification-header--cycling {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#79B35C), to(#79B35C));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #79B35C 0%, #79B35C 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #79B35C 0%, #79B35C 100%); }
.ip-classification-header--darts {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#ED4830), to(#ED4830));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #ED4830 0%, #ED4830 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #ED4830 0%, #ED4830 100%); }
.ip-classification-header--e-sports {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#C364C5), to(#C364C5));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #C364C5 0%, #C364C5 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #C364C5 0%, #C364C5 100%); }
.ip-classification-header--floorball {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#985970), to(#985970));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #985970 0%, #985970 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #985970 0%, #985970 100%); }
.ip-classification-header--formula_1 {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#578986), to(#578986));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #578986 0%, #578986 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #578986 0%, #578986 100%); }
.ip-classification-header--futsal {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#CABB76), to(#CABB76));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #CABB76 0%, #CABB76 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #CABB76 0%, #CABB76 100%); }
.ip-classification-header--golf {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#70C448), to(#70C448));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #70C448 0%, #70C448 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #70C448 0%, #70C448 100%); }
.ip-classification-header--handball {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#D37831), to(#D37831));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #D37831 0%, #D37831 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #D37831 0%, #D37831 100%); }
.ip-classification-header--ice_hockey {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#00B6EC), to(#00B6EC));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #00B6EC 0%, #00B6EC 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #00B6EC 0%, #00B6EC 100%); }
.ip-classification-header--motorbikes {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#BADBAD), to(#BADBAD));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #BADBAD 0%, #BADBAD 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #BADBAD 0%, #BADBAD 100%); }
.ip-classification-header--pool {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#B0D437), to(#B0D437));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #B0D437 0%, #B0D437 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #B0D437 0%, #B0D437 100%); }
.ip-classification-header--rugby_league {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#D79A7E), to(#D79A7E));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #D79A7E 0%, #D79A7E 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #D79A7E 0%, #D79A7E 100%); }
.ip-classification-header--snooker {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#E5191A), to(#E5191A));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #E5191A 0%, #E5191A 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #E5191A 0%, #E5191A 100%); }
.ip-classification-header--soccer {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#30A01A), to(#30A01A));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #30A01A 0%, #30A01A 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #30A01A 0%, #30A01A 100%); }
.ip-classification-header--speedway {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#37B0D4), to(#37B0D4));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #37B0D4 0%, #37B0D4 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #37B0D4 0%, #37B0D4 100%); }
.ip-classification-header--table_tennis {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#5E9FDE), to(#5E9FDE));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #5E9FDE 0%, #5E9FDE 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #5E9FDE 0%, #5E9FDE 100%); }
.ip-classification-header--tennis {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#E1E31C), to(#E1E31C));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #E1E31C 0%, #E1E31C 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #E1E31C 0%, #E1E31C 100%); }
.ip-classification-header--volleyball {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#FFAF3D), to(#FFAF3D));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #FFAF3D 0%, #FFAF3D 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FFAF3D 0%, #FFAF3D 100%); }
.ip-classification-header--water_polo {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(56, 58, 74, 0.3)), to(rgba(56, 58, 74, 0.3))), -webkit-gradient(linear, left top, right top, from(#A6CAF0), to(#A6CAF0));
    background: -o-linear-gradient(bottom, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), -o-linear-gradient(left, #A6CAF0 0%, #A6CAF0 100%);
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #A6CAF0 0%, #A6CAF0 100%); }
.ip-classification-header--favourite {
    background-color: #2f3346;
    height: 30px;
}
.ip-classification-header--active {
    height: auto;
}
.ip-classification-header--active .ip-classification-header__dropdown {
    display: block;
}
.ip-classification-header--active .ip-classification-header__icon {
    color: #f1cb62;
}
.ip-classification-header--active .ip-classification-header__league .font {
    color: #F1CB62;
}
.ip-classification-header__wrap {
    width: 100%;
    padding: 10px;
}
.ip-classification-header__clear:hover, .ip-classification-header__clear--active {
    color: #F1CB62;
}
.ip-classification-header__league {
    margin-left: 60px;
    margin-right: auto;
    cursor: pointer;
}
.ip-classification-header__league:hover .font, .ip-classification-header__league--active .font {
    color: #F1CB62; }
.ip-classification-header__icon {
    width: 16px;
    height: 16px; }
.ip-classification-header__streaming {
    margin-left: 20px;
}
.ip-classification-header__streaming:hover label, .ip-classification-header__streaming--active label {
    color: #fff; }
.ip-classification-header__dropdown {
    background-color: #232737;
    padding: 20px 5%;
    display: none; }
.ip-classification-header__hr {
    border: 0;
    background-color: #383A4A;
    height: 1px;
    margin: 20px 0; }
.ip-classification-header__checkbox {
    max-width: 25%;
    width: 100%;
    padding-right: 10px; }
.ip-classification-header__text {
    color: #fff; }
.ip-classification-header__btn {
    width: 80px !important;
    margin: 0 5px; }
.ip-classification-header__btn.btn--outline {
    border-color: #383A4A; }
.ip-classification-header__btn.btn--outline:hover {
    border-color: #fff; }
.ip-classification-header__btn.btn--outline:hover > * {
    color: #fff; }
.ip-classification-header .dropdown {
    z-index: 3;
}
.ip-classification-header .dropdown:hover .dropdown__toggle, .ip-classification-header .dropdown--active .dropdown__toggle {
    color: #fff;
}
.ip-classification-header .dropdown__menu {
    min-width: 200px;
    right: 0;
    left: auto;
    background-color: #fff;
}
.ip-classification-header .dropdown__item {
    border-bottom: 1px solid rgba(194, 211, 212, 0.5); }
.ip-classification-header .dropdown__item:last-child {
    border-bottom: 0; }
.ip-classification-header .dropdown__item:hover, .ip-classification-header .dropdown__item--active {
    background-color: rgba(194, 211, 212, 0.5);
}
.ip-classification-prev, .ip-classification-next {
    position: absolute;
    top: 0px;
    z-index: 2;
    cursor: pointer;
    border: none;
    height: 100%;
    padding: 0px 15px;
    background: #1a1e3080;
    color: #8c8e97;
    display: none;
}
.ip-classification-prev {
    left: 0px;
}
.ip-classification-next {
    right: 0px;
}
.ip-classification-prev:hover, .ip-classification-next:hover {
    color: #f1cb62;
    background: #131625a6;
}
.ip-classification-prev::before, .ip-classification-next::before {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 300;
}
.ip-classification-prev::before {
    content: '\f104';
}
.ip-classification-next::before {
    content: '\f105';
}


.slides-prev, .slides-next {
    position: absolute;
    top: 0px;
    z-index: 2;
    cursor: pointer;
    border: none;
    height: 100%;
    width: 20px;
    padding: 0px 15px;
    background: #1a1e3080;
    color: #8c8e97;
    display: flex;
    opacity: 0;
    transition: opacity .5s;
}
.wn-banner-slider:hover .slides-next, .wn-banner-slider:hover .slides-prev  {
    opacity: 0.64;
}
.slides-prev {
    left: 0px;
}
.slides-next {
    right: 0px;
}
.slides-prev:hover, .slides-next:hover {
    color: #f1cb62;
    background: #131625a6;
}
.slides-prev::before, .slides-next::before {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 300;
}
.slides-prev::before {
    content: '\f104';
}
.slides-next::before {
    content: '\f105';
}