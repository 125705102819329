.unmount {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.2s cubic-bezier(1, 0.03, 0.97, 0.92),
		height 0.2s cubic-bezier(1, 0.03, 0.97, 0.92);
}

.none {
	overflow: hidden !important;
	opacity: 0 !important;
}
.gll-market-group {
	transition: all cubic-bezier(0.65, 0.09, 0.34, 0.93);
}
.gll-markets-wrapper,
.gll-markets-wrapper--favourites {
	display: flex;
	flex-direction: column;
	position: relative;
}

.gll-market-group-enter {
	opacity: 0;
	max-height: 0px;
	transform: scale(0.5);
	transition: opacity 0.7s linear, transform 0.7s linear;
}
.gll-market-group-enter-active {
	opacity: 1;
	max-height: 1000px;
	transform: scale(1);
	transition: opacity 0.8s 0.3s cubic-bezier(0.8, 0.21, 0.84, 0.48),
		max-height 0.9s 0.3s cubic-bezier(0.8, 0.21, 0.84, 0.48),
		transform 0.8s 0.3s cubic-bezier(0.8, 0.21, 0.84, 0.48);
}

.gll-market-group-exit {
	opacity: 1;
	max-height: 1000px;
	transform: scale(0.9);
	transition: opacity 0.6s linear,
		max-height 0.5s cubic-bezier(0.21, 0.73, 0.35, 0.83), transform 0.6s linear;
}
.gll-market-group-exit-active {
	opacity: 0;
	max-height: 0px;
	transform: scale(0.7);
	transition: opacity 0.5s linear, max-height 0.5s linear, transform 0.5s linear;
}

.gll-market-group--closed .gll-market-group__header {
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
}
.gll-market-group--closed .gll-market-group__header:after {
	content: '\f107';
}

.gll-market-group__body {
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}
.gll-market-group__body-enter {
	max-height: 0px;
}
.gll-market-group__body-enter-active {
	max-height: 1000px;
	transition: max-height 0.3s cubic-bezier(0.54, 0.1, 0.81, 0.27);
}
.gll-market-group__body-exit {
	max-height: 1000px;
}
.gll-market-group__body-exit-active {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(0.54, 0.77, 0.04, 1.05);
}
.gll-market-group__header {
	width: 100%;
	height: 28px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 40px 0 10px;
	background-color: #f1f3f7;
	position: relative;
	cursor: pointer;
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
	transition: all 0.2s linear;
}
.gll-market-group__header:after {
	content: '\f106';
	font-family: 'Font Awesome 5 Pro';
	font-size: 14px;
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.gll-market-group__name {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 10px;
	width: 100%;
	text-align: center;
	position: absolute;
}

.gll-market-group__favourite {
	font-size: 12px;
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.gll-market-group__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
}

.gll-market-group__favourite--active:after,
.gll-market-group__favourite:hover:after {
	content: '\f005';
	font-weight: 900;
	color: #f1cb62;
}

.gll-market-group__tab {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 0 10px;
	height: 100%;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.gll-market-group__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	margin: 0 5px;
	position: relative;
}
.gll-market-group__link:after {
	width: 0;
	height: 3px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.gll-market-group__link--active > *,
.gll-market-group__link:hover > * {
	color: #6c90fc;
}
.gll-market-group__link--active:after,
.gll-market-group__link:hover:after {
	width: 100%;
}
.gll-market-group__link:first-child {
	margin-left: 0;
}
.gll-market-group__link:last-child {
	margin-right: 0;
}

.gll-market-group__offers {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 20px;
	padding: 0 6px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	border: 1px solid #788485;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 10px;
	margin-left: auto;
	cursor: pointer;
}

.gll-market-group__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #fff;
}
.gll-market-group__row.col {
	flex-direction: column;
}
.gll-market-group__column {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-right: 1px solid rgba(223, 223, 223, 0.8);
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
	background-color: #f2f2f2;
}
.gll-market-group__column:last-child {
	border-right: 0;
}

.gll-market-group__cell {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	overflow: hidden;
	border-right: 1px solid rgba(223, 223, 223, 0.8);
	height: 40px;
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
}

.gll-market-group__btn {
	width: 100%;
	height: 100%;
	border: 0 !important;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: center;
	-ms-flex-pack: justify;
	justify-content: center !important;
	align-items: center;
}
.gll-market-group__btn--active {
	background-color: #6c90fc !important;
}
.gll-market-group__btn--active span{
	color: #ffffff !important;
}
.gll-market-group__btn--active > .font {
	color: #fff;
}
.gll-market-group__btn--center {
	width: 100%;
	height: 100%;
	border: 0 !important;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center !important;
}

.gll-market-group__coff {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	background-color: #fff;
}
.gll-market-group__coff--center {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.gll-market-group__title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 28px;
	padding: 0 10px;
	background-color: #e1e5ee;
}

.gll-market-group__title-border {
	border-bottom: 1px solid #d5d5d5;
}
.gll-market-group__column-border {
	border-right: 1px solid #d5d5d5;
}

.gll-market-group__range-group {
	-webkit-box-flex: 2;
	-webkit-flex: 2;
	-ms-flex: 2;
	flex: 2;
	padding: 0 20px;
}

.gll-market-group__btn-group {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 144px;
	height: 32px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	padding: 2px;
	border: 1px solid #e7e7e7;
	-webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.0824558);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.0824558);
	align-self: center;
}

.gll-market-group__count {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 100%;
	-webkit-border-radius: inherit;
	border-radius: inherit;
	cursor: pointer;
}
.gll-market-group__count--active {
	background-color: #6c90fc;
}
.gll-market-group__count--active > * {
	color: #fff !important;
}

.tooltip {
	position: relative;
	margin: 0 5px;
}
.tooltip--active .tooltip__dropdown,
.tooltip:hover .tooltip__dropdown {
	display: block;
}
.tooltip__item {
	width: 12px;
	height: 12px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #f1cb62;
	font-size: 8px;
	font-style: italic;
	font-weight: 700;
	line-height: 1.8;
	text-align: center;
	color: #fff;
}
.tooltip__dropdown {
	display: none;
	position: absolute;
	top: -webkit-calc(100% + 10px);
	top: calc(100% + 10px);
	left: -5px;
	-webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	-webkit-border-radius: 6px;
	border-radius: 6px;
	background-color: #fff;
	z-index: 1;
	min-width: 220px;
	padding: 10px;
}
