.mn-popup--active {
	display: block;
}
/* .register-popup:after {
	position: absolute;
	top: 0;
	right: 0;
	content: '\00d7';
	font-size: 1rem;
	color: #fff;
} */
.mn-popup {
	background-color: #0c1022;
	border-radius: 4px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 950px;
	padding: 50px 80px;
	overflow: hidden;
}

.mn-popup__body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
	height: 400px;
	width: 100%;
}

.mn-popup__link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
}
.mn-popup__link:after {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.mn-popup__link:hover > .font,
.mn-popup__link--active > .font {
	color: #6c90fc;
}
.mn-popup__link:hover:after,
.mn-popup__link--active:after {
	width: 100%;
}
.mn-popup__tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}

.flex {
	display: flex;
}

.align-center-middle {
	justify-content: center;
	align-items: center;
}

.mn-popup__block {
	align-items: stretch;
	min-height: 455px;
}

.congr-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 520px;
	max-height: 400px;
	background-color: #fff;
}

.mn-popup__left,
.mn-popup__right {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;
}

.mn-popup__left {
	padding-right: 30px;
}

.mn-popup__banner {
	margin-bottom: 40px;
}

.mn-popup__title {
	max-width: 240px;
	margin: 0 auto;
	width: 100%;
}

.mn-popup__right {
	padding-left: 30px;
}

.mn-popup__head {
	margin-top: 0;
	margin-bottom: 50px;
}

.h-logo {
	width: 170px;
	height: 30px;
	background-image: url(../img/logo.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-flex;
}

.mn-popup__wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex-grow: 1;
}

.mn-popup__wrap {
	margin-bottom: 40px;
	position: relative;
}

.q-popup__title {
	margin-top: 50px;
}
.q-popup__body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding-bottom: 20px;
}

.sent-popup {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 300px;
	max-width: 550px;
}

.sent-popup .sent {
	display: flex;
	justify-content: center;
	align-items: center;
}

.sent-popup .sent svg {
	margin: 5px;
}

.sent-popup .sent svg.stars {
	position: absolute;
}

.q-popup__password .field {
	margin: 20px 10px;
	text-align: center;
}
.q-popup__password .field:first-child {
	margin-left: 0;
}
.q-popup__password .field:last-child {
	margin-right: 0;
}
.q-popup__password .field {
	color: #fff;
	max-width: 90px;
}
.q-popup__password .field.error {
	color: #d90101 !important;
	border-bottom: 1px solid #d90101 !important;
}
.q-popup__password .field::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.q-popup__password .field::-moz-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.q-popup__password .field:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.next-btn {
	margin-top: 50px;
	width: 350px!important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
