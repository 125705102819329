.footer {
    padding: 0 25px;
    background-color: #1F2133; }
.footer__content {
    padding: 0 10px; }
.footer__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 25px 0; }
.footer__list {
    padding-right: 5px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }
.footer__list--empty {
    padding-top: 35px; }
.footer__list:not(.footer__list--empty) .footer__item:first-child {
    margin-bottom: 20px; }
.footer__item {
    margin-bottom: 10px; }
.footer__link:hover, .footer__link--active {
    color: #fff; }
.footer__partners {
    height: 58px;
    border-top: 1px solid #545763;
    border-bottom: 1px solid #545763; }
.footer__partner {
    min-width: 100px;
    height: 80%;
    background-color: #f2f2f2; }
.footer__copyright {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 42px; }