@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
.mn-popup--active {
	display: block;
}
/* .register-popup:after {
	position: absolute;
	top: 0;
	right: 0;
	content: '\00d7';
	font-size: 1rem;
	color: #fff;
} */
.mn-popup {
	background-color: #0c1022;
	border-radius: 4px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 950px;
	padding: 50px 80px;
	overflow: hidden;
}

.mn-popup__body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
	height: 400px;
	width: 100%;
}

.mn-popup__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	flex: 1 1;
	position: relative;
}
.mn-popup__link:after {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.mn-popup__link:hover > .font,
.mn-popup__link--active > .font {
	color: #6c90fc;
}
.mn-popup__link:hover:after,
.mn-popup__link--active:after {
	width: 100%;
}
.mn-popup__tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}

.flex {
	display: flex;
}

.align-center-middle {
	justify-content: center;
	align-items: center;
}

.mn-popup__block {
	align-items: stretch;
	min-height: 455px;
}

.congr-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 520px;
	max-height: 400px;
	background-color: #fff;
}

.mn-popup__left,
.mn-popup__right {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1 1;
}

.mn-popup__left {
	padding-right: 30px;
}

.mn-popup__banner {
	margin-bottom: 40px;
}

.mn-popup__title {
	max-width: 240px;
	margin: 0 auto;
	width: 100%;
}

.mn-popup__right {
	padding-left: 30px;
}

.mn-popup__head {
	margin-top: 0;
	margin-bottom: 50px;
}

.h-logo {
	width: 170px;
	height: 30px;
	background-image: url(/static/media/logo.e3893696.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-flex;
}

.mn-popup__wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex-grow: 1;
}

.mn-popup__wrap {
	margin-bottom: 40px;
	position: relative;
}

.q-popup__title {
	margin-top: 50px;
}
.q-popup__body {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 20px;
}

.sent-popup {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 300px;
	max-width: 550px;
}

.sent-popup .sent {
	display: flex;
	justify-content: center;
	align-items: center;
}

.sent-popup .sent svg {
	margin: 5px;
}

.sent-popup .sent svg.stars {
	position: absolute;
}

.q-popup__password .field {
	margin: 20px 10px;
	text-align: center;
}
.q-popup__password .field:first-child {
	margin-left: 0;
}
.q-popup__password .field:last-child {
	margin-right: 0;
}
.q-popup__password .field {
	color: #fff;
	max-width: 90px;
}
.q-popup__password .field.error {
	color: #d90101 !important;
	border-bottom: 1px solid #d90101 !important;
}
.q-popup__password .field::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.q-popup__password .field::-moz-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.q-popup__password .field:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.next-btn {
	margin-top: 50px;
	width: 350px!important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.h-search__item, .h-search__link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #0C1022 !important;
}

.font--13 {
    font-size: 13px;
}
.font--white-alpha-05 {
    color: hsla(0,0%,100%,.5) !important;
}

.font {
    color: #232737;
    line-height: 15px;
    font-size: 13px;
    font-family: "Roboto",sans-serif;
    font-weight: 400;
}

.font--white {
    color: #fff !important;
}

.h-search__img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-left: 15px;
}
.h-nav {
	margin-bottom: 1px;
}
.h-nav__up {
	padding: 0 15px;
	height: 60px;
	background-color: #1a1e30;
}
.h-nav__down {
	padding: 0 15px;
	height: 40px;
	background-color: #232737;
}
.search-hover:hover {
	color: #fff;
}
.h-a-menu {
	position: absolute;
	top: 100%;
	right: 0;
	left: auto;
	width: 230px;
	background-color: #f6f8ff;
	z-index: 9;
	border-radius: 2px;
	display: none;
}
.h-a-menu--active {
	display: block;
}
.h-a-menu__head {
	width: 100%;
	height: 108px;
	background-color: #fff;
	margin-bottom: 4px;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.rotating {
	-webkit-animation: rotating 1s linear infinite;
	animation: rotating 1s linear infinite;
}
.h-a-menu__info .fas:after {
	cursor: pointer;
	color: rgba(35, 39, 55, 0.5);
}
.h-a-menu__info .fas:hover:after,
.h-a-menu__info .fas--active:hover:after {
	color: #232737;
}
.h-a-menu__info .inline-flex div,
.h-a-menu__info .inline-flex p {
	margin-top: 10px;
}
.h-a-menu__info .inline-flex div {
	margin-right: 10px;
}
.h-a-menu__tab {
	width: 100%;
	height: 32px;
	background-color: #fff;
}
.h-a-menu__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex: 1 1;
	position: relative;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.h-a-menu__link:after {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.h-a-menu__link:hover:after,
.h-a-menu__link--active:after {
	width: 100%;
}
.h-a-menu__info {
	display: flex;
	justify-content: center;
	align-items: flexs-start;
	flex-direction: column;
	padding: 5px 10px;
	flex: 1 1;
}
.h-a-menu__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 34px;
	cursor: pointer;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	padding: 5px 10px;
	background-color: #fff;
}
.h-a-menu__item--active > .font,
.h-a-menu__item:hover > .font {
	color: #6c90fc;
}
.h-a-menu__mail .icon {
	font-size: 12px;
	color: rgba(56, 58, 74, 0.8);
}
.h-a-menu__mail .icon:hover,
.h-a-menu__mail .icon.active {
	color: #6c90fc;
}
.h-a-menu__body {
	display: none;
}
.h-a-menu__body--active {
	display: block;
}

.h-menu__item {
	color: #9a9b9f;
	margin: 0 10px;
	letter-spacing: 0.2px;
	text-transform: uppercase;
}
.h-menu__item--active,
.h-menu__item:hover {
	color: #fff !important;
}

.h-menu__dropdown {
	position: relative;
	z-index: 9;
}

.h-logo {
	width: 170px;
	height: 24px;
	background-image: url(/static/media/logo.e3893696.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-flex;
}

.h-submenu__link {
	color: #9a9b9f;
}
.h-submenu__link:hover,
.h-submenu__link--active {
	color: #fff;
}

.h-submenu__item {
	margin-right: 17px;
}
.h-submenu__item:last-child {
	margin-right: 0;
}

.h-submenu__lang {
	cursor: pointer;
}
.h-submenu__lang:hover,
.h-submenu__lang--active {
	background-color: #191c27;
}
.h-submenu__lang:hover .font,
.h-submenu__lang--active .font {
	color: #fff;
}
.h-submenu__lang img {
	width: 18px;
	height: 15px;
}

.h-submenu__separate {
	width: 1px;
	height: 24px;
	background-color: #383a4a;
}

.h-submenu__text {
	color: #9a9b9f;
}

.h-submenu__dropdown {
	position: relative;
	z-index: 0;
	min-width: 100px;
}
.h-submenu__dropdown .dropdown__menu {
	right: 0;
	left: auto;
	min-width: 100px;
}
.h-submenu .lang {
	display: flex;
	align-items: center;
	margin-bottom: -2px;
}
.h-submenu .lang__img {
	display: none;
	margin-bottom: 2px;
}
.h-submenu .lang--active .lang__img {
	display: block;
}

.h-search {
	height: 24px;
	/*padding: 0 8px;*/
	margin: 2px 10px 0 auto;
	transition: all 0.5s;
	position: relative;
	z-index: 9;
}
.h-search--active {
	background-color: #2f3346;
	border-radius: 2px;
	flex-grow: 1;
}
.h-search--active .h-search__icon {
	display: none;
}
.h-search--active .h-search__field {
	display: block !important;
}
.h-search--active .h-search__text {
	color: #f1cb62;
}
.h-search--active .h-search__dropdown {
	display: block;
}
.h-search__icon {
	color: #f1cb62;
	font-size: 12px;
	margin-right: 8px;
	margin-bottom: 2px;
}
.field.h-search__field {
	display: none;
	padding: 0 0 0 8px;
	padding-left: 16px;
	color: #fff;
	margin-right: 8px;
	border-right: 1px solid #0c1022;
	font-size: 13px;
}
.field.h-search__field::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.field.h-search__field::-moz-placeholder {
	color: rgba(255, 255, 255, 0.5);
}
.field.h-search__field:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.h-search__dropdown {
	display: none;
	position: absolute;
	height: auto;
	max-height: 400px;
	left: 0;
	right: 0;
	top: 31px;
	background-color: #2f3346;
	border-radius: 2px;
	/*z-index: 2;*/
}
.h-search__subtitle,
.h-search__item {
	border-bottom: 1px solid rgba(12, 16, 34, 0.5);
}
.h-search__subtitle {
	padding: 10px 15px;
}
.h-search__item {
	padding: 10px 15px;
}
.h-search__hr {
	width: 1px;
	height: 12px;
	border: 0;
	margin: 0 10px;
	background-color: #9a9b9f;
}

.h-account__wrap {
	position: relative;
	margin-left: 10px;
}

.h-account__btn {
	min-width: 80px;
	padding: 5px 25px;
}
.h-account__btn span {
	font-weight: 600 !important;
}
.h-account__mail {
	color: rgba(255, 255, 255, 0.5);
	position: relative;
}
.h-account__mail:hover,
.h-account__mail--active {
	color: #f1cb62;
}
.h-account__mail--active span {
	width: 5px;
	height: 5px;
	background-color: #fc3164;
	border-radius: 50%;
	position: absolute;
	right: -1px;
	top: -1px;
}

.h-account__nickname:hover {
	color: #fff;
}

.h-account__popup {
	display: none;
	position: absolute;
	top: 20px;
	right: 0;
	left: auto;
	width: 365px;
	padding: 10px;
	border-radius: 2px;
	background-color: #fff;
	z-index: 10;
	overflow: hidden;
}
.h-account__popup--active {
	display: block;
}

.h-account__block > .flex:first-child {
	margin-bottom: 2px;
}

.h-account__block > .flex:last-child {
	margin-top: 2px;
}

.h-account__close {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	background-color: #f6f8ff;
	border-radius: 50%;
	cursor: pointer;
}
.h-account__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate(160%, -70%);
	color: #383a4a;
	font-size: 14px;
}

.h-account__avatar {
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	position: relative;
}
.h-account__avatar--active .h-account__picture svg,
.h-account__avatar:hover .h-account__picture svg {
	stroke: #f1cb62;
}

.h-account__picture {
	width: 32px;
	height: 32px;
}
.h-account__picture svg {
	width: 100%;
	height: 100%;
	stroke: rgba(35, 39, 55, 0.5);
}

.h-popup {
	width: 270px;
	height: 320px;
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 65;
	background-color: #333545;
	transform: translateY(8px);
	padding: 40px 16px 20px 16px;
	border-radius: 2px;
	box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
}
.h-popup--active {
	display: block;
}
.h-popup__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
}
.h-popup__wrapper form {
	height: 80%;
}
.h-popup__wrapper form .loginFormWrapper {
	height: 80% !important;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
}
.loginFormWrapper span:hover {
	cursor: pointer;
}
.h-popup__btn {
	min-width: 80px;
	padding: 5px 25px;
	margin-left: 10px;
}
.field.h-popup__field {
	width: 100%;
	height: 25px;
	border-bottom: 1px solid rgba(223, 223, 223, 0.5) !important;
	color: #fff;
	padding: 3px 0;
	font-size: 13px;
}
.field.h-popup__field::-webkit-input-placeholder {
	color: rgba(223, 223, 223, 0.5);
}
.field.h-popup__field::-moz-placeholder {
	color: rgba(223, 223, 223, 0.5);
}
.field.h-popup__field:-ms-input-placeholder {
	color: rgba(223, 223, 223, 0.5);
}
.h-popup__error {
	display: none;
}
.h-popup__wrap {
	position: relative;
}
.h-popup__or {
	position: relative;
	text-align: center;
}
.h-popup__or:before,
.h-popup__or:after {
	width: 100px;
	height: 1px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgba(255, 255, 255, 0.5);
	content: '';
}
.h-popup__or:before {
	left: 0;
}
.h-popup__or:after {
	right: 0;
}
.h-popup .show-password,
.cube .show-password {
	color: rgba(223, 223, 223, 0.5);
	cursor: pointer;
}
.h-popup .show-password:hover,
.h-popup .show-password--active,
.cube .show-password:hover,
.cube .show-password--active {
	color: #fff;
}

.required {
	position: relative;
}
.required .error {
	display: none;
}
.required--error .field,
.required--error .select {
	border-color: #fc3164 !important;
	color: #fc3164;
}
.required--error .field::-webkit-input-placeholder,
.required--error .select::-webkit-input-placeholder {
	color: #fc3164;
}
.required--error .field::-moz-placeholder,
.required--error .select::-moz-placeholder {
	color: #fc3164;
}
.required--error .field:-ms-input-placeholder,
.required--error .select:-ms-input-placeholder {
	color: #fc3164;
}
.required--error .font {
	color: #fc3164;
}
.required--error .error {
	display: block !important;
	position: absolute;
	margin-top: 5px;
}
.h-popup__overlay {
	z-index: 60;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	display: block;
	width: 100%;
}

.show-password {
	position: absolute;
	cursor: pointer;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	color: #b5b7c6;
}
.show-password .fas {
	font-size: 14px;
}
.show-password:hover {
	color: #232737;
}
.show-password--active .fas:after {
	content: '\f070' !important;
}
.fas--active:after {
	content: '\f070' !important;
}
.big-height .show-password {
	top: 32%;
}
.breadcrumbs {
	position: relative;
}
.breadcrumbs__wrap {
	height: 100%;
}
.breadcrumbs__item {
	display: flex;
	align-items: center;
	position: relative;
	padding-right: 10px;
	margin-right: 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.breadcrumbs__item:after {
	content: '/';
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	color: rgba(255, 255, 255, 0.5);
}
.breadcrumbs__item:last-child:after {
	display: none;
}
.breadcrumbs__link:hover,
.breadcrumbs__link--active {
	color: #fff !important;
}
.breadcrumbs__text {
	color: #fff;
}
.breadcrumb_title_part {
	max-width: 140px !important;
}
.breadcrumb_title_part:nth-child(2) {
	padding: 0px 5px;
}
.breadcrumbs--bg {
	width: 100%;
	height: 40px;
	border-radius: 2px;
	padding: 5px 10px;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.breadcrumbs--bg:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
}
.breadcrumbs .dropdown__item:hover,
.breadcrumbs .dropdown__item--active {
	background-color: #191c27;
}
.h-submenu__dropdown.dropdown--active .dropdown__toggle {
	border-bottom-color: transparent;
	border-radius: 12px 12px 0 0;
}
.h-submenu .lang {
	display: flex;
	align-items: center;
}
.h-submenu__dropdown {
	position: relative;
	z-index: 0;
}
.h-submenu__item {
	margin-right: 17px;
}
.h-submenu__dropdown .dropdown__toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 24px;
	min-width: 120px;
	padding: 2px 20px 2px 10px;
	border-radius: 12px;
	border: 1px solid #383a4a;
	padding-left: 15px;
}
.dropdown__toggle {
	padding-right: 10px;
	position: relative;
	cursor: pointer;
}
.h-submenu__dropdown .dropdown__toggle:after {
	right: 8px;
	color: #9a9b9f;
	font-size: 12px;
}
.h-submenu .lang__img,
.h-submenu .lang__country {
	width: 16px;
	height: 14px;
}
.h-submenu .lang .dropdown__menu {
	left: 0;
	min-width: 0;
}
.h-submenu__dropdown .dropdown__menu {
	right: 0;
	left: 0;
	border-radius: 0 0 12px 12px;
	border-left: 1px solid #383a4a;
	border-right: 1px solid #383a4a;
	border-bottom: 1px solid #383a4a;
	overflow: hidden;
}
.langs-overlay {
	z-index: 60;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	display: none;
	width: 100%;
}
.langs-overlay.active {
	display: block;
}

.ips-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	background-color: #fff;
}
.ips-table .td {
	position: relative;
	padding: 0 5px;
}
.ips-table .td:nth-child(1) {
	width: 10%;
}
.ips-table .td:nth-child(2) {
	width: 25%;
}
.ips-table .td:nth-child(3) {
	width: 45%;
}
.ips-table .td:nth-child(4) {
	width: 10%;
}
.ips-table .td:nth-child(5) {
	width: 10%;
}
.ips-table .tr {
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	height: 30px;
}
.ips-table .tr--title {
	background-color: #f1f3f7;
	height: 30px;
}
.ips-table .c-icon {
	margin: 0 auto;
}

.ips-control-bar {
	width: 100%;
	height: 45px;
	background-color: #fff;
	overflow: hidden;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ips-control-bar__item {
	width: 158px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	flex-shrink: 0;
}
.ips-control-bar__item:after {
	width: 1px;
	height: 16px;
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	border-right: 1px dashed rgba(194, 211, 212, 0.5);
}
.ips-control-bar__item:before {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.ips-control-bar__item:hover,
.ips-control-bar__item--active {
	cursor: pointer;
}
.ips-control-bar__item:hover *,
.ips-control-bar__item--active * {
	color: #6c90fc;
}
.ips-control-bar__item:hover:before,
.ips-control-bar__item--active:before {
	width: 100%;
}

.ip-control-bar {
	height: 34px;
	background-color: #1a1e30;
	border-radius: 2px;
}
.ip-control-bar__wrap {
	padding: 0 10px;
}
.ip-control-bar__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin-right: 20px;
	position: relative;
	color: rgba(108, 144, 252, 0.5);
}
.ip-control-bar__link:after {
	width: 0;
	height: 4px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.ip-control-bar__link:hover,
.ip-control-bar__link--active {
	color: #6c90fc;
}
.ip-control-bar__link:hover:after,
.ip-control-bar__link--active:after {
	width: 100%;
}
.ipn-control-left {
    left: 0px;
}
.ipn-control-left::before{
    content: '\f104';
}
.ipn-control-right {
    right: 0px;
}
.ipn-control-right::before{
    content: '\f105';
}
.ipn-control-btn {
    display: none;
    position: absolute;
    z-index: 10;
    top: 0px;
    height: 100%;
    width: 20px;
    background: #6C90FC;
    border:none;
    outline: none;
}
.ipn-control-btn::before {
    font-family: 'Font Awesome 5 Pro';
    font-size: 15px;
    color: #ffffff;
}
.ips-control-bar:hover .ipn-control-btn {
    display: block;
}
.ips-control-bar__wrap {
    transition: transform .4s ease-in-out;
}
.ips-table-empty {
    min-height: calc(80vh - 160px);
    background: #f1f3f7;
    width: 100%;
}

.ip-classification-bar-sport {
    width: 100%;
    height: 44px;
    background-color: #1A1E30;
    border-radius: 2px;
    overflow: hidden;
}

.ip-classification-bar-other {
    width: 100%;
    height: 70px;
    background-color: #1A1E30;
    border-radius: 2px;
    overflow: hidden;
}
.ip-classification-bar-sport:hover .ip-classification-prev{
    display: flex;
}
.ip-classification-bar-sport:hover .ip-classification-next {
    display: flex;
}
.ip-classification-bar-other:hover .ip-classification-prev {
    display: flex;
}
.ip-classification-bar-other:hover .ip-classification-next {
    display: flex;
}
.ip-classification-bar__wrap {
    width: 100%;
    padding: 10px;
    transition: transform 1s;
}
.ip-classification-bar__wrap::-webkit-scrollbar {
    display: none;
}
.ip-classification-bar__item {
    width: 90px;
    height: 100%;
    cursor: pointer;
    flex-shrink: 0;
    box-shadow: 0px 0px 6px rgba(56, 71, 89, 0.2);
    border-radius: 2px;
    background-color: #2F3346;
    margin-right: 5px;
    padding-top: 5px;
}
.ip-classification-bar__item:hover .ip-classification-bar__text, .ip-classification-bar__item--active .ip-classification-bar__text {
    color: #F1CB62;
}
.ip-classification-bar__item:hover .ip-classification-bar__icon svg g,
.ip-classification-bar__item:hover .ip-classification-bar__icon svg rect,
.ip-classification-bar__item:hover .ip-classification-bar__icon svg path, .ip-classification-bar__item--active .ip-classification-bar__icon svg g,
.ip-classification-bar__item--active .ip-classification-bar__icon svg rect,
.ip-classification-bar__item--active .ip-classification-bar__icon svg path {
    opacity: 1;
    fill: #F1CB62;
}
.ip-classification-bar__block {
    height: 50%; }
.ip-classification-bar__text {
    color: #8C8E97; }
.ip-classification-bar__icon {
    width: 16px;
    height: 16px;
    overflow: hidden; }
.ip-classification-bar__icon svg g,
.ip-classification-bar__icon svg rect,
.ip-classification-bar__icon svg path {
    opacity: 0.5;
    transition: all 0.3s linear; }

.ip-classification-header {
    width: 100%;
    height: 38px;
    background-color: #1A1E30;
}
.ip-classification-header--american_football {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #629D5B 0%, #629D5B 100%); }
.ip-classification-header--bandy {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #4ED7FF 0%, #4ED7FF 100%); }
.ip-classification-header--baseball {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FF8E00 0%, #FF8E00 100%); }
.ip-classification-header--basketball {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FF845C 0%, #FF845C 100%); }
.ip-classification-header--beach_volleyball {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FFAF3F 0%, #FFAF3F 100%); }
.ip-classification-header--bowls {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #6488A7 0%, #6488A7 100%); }
.ip-classification-header--boxing {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FF1515 0%, #FF1515 100%); }
.ip-classification-header--cricket {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #D86308 0%, #D86308 100%); }
.ip-classification-header--cycling {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #79B35C 0%, #79B35C 100%); }
.ip-classification-header--darts {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #ED4830 0%, #ED4830 100%); }
.ip-classification-header--e-sports {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #C364C5 0%, #C364C5 100%); }
.ip-classification-header--floorball {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #985970 0%, #985970 100%); }
.ip-classification-header--formula_1 {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #578986 0%, #578986 100%); }
.ip-classification-header--futsal {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #CABB76 0%, #CABB76 100%); }
.ip-classification-header--golf {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #70C448 0%, #70C448 100%); }
.ip-classification-header--handball {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #D37831 0%, #D37831 100%); }
.ip-classification-header--ice_hockey {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #00B6EC 0%, #00B6EC 100%); }
.ip-classification-header--motorbikes {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #BADBAD 0%, #BADBAD 100%); }
.ip-classification-header--pool {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #B0D437 0%, #B0D437 100%); }
.ip-classification-header--rugby_league {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #D79A7E 0%, #D79A7E 100%); }
.ip-classification-header--snooker {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #E5191A 0%, #E5191A 100%); }
.ip-classification-header--soccer {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #30A01A 0%, #30A01A 100%); }
.ip-classification-header--speedway {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #37B0D4 0%, #37B0D4 100%); }
.ip-classification-header--table_tennis {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #5E9FDE 0%, #5E9FDE 100%); }
.ip-classification-header--tennis {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #E1E31C 0%, #E1E31C 100%); }
.ip-classification-header--volleyball {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #FFAF3D 0%, #FFAF3D 100%); }
.ip-classification-header--water_polo {
    background: linear-gradient(0deg, rgba(56, 58, 74, 0.3), rgba(56, 58, 74, 0.3)), linear-gradient(90deg, #A6CAF0 0%, #A6CAF0 100%); }
.ip-classification-header--favourite {
    background-color: #2f3346;
    height: 30px;
}
.ip-classification-header--active {
    height: auto;
}
.ip-classification-header--active .ip-classification-header__dropdown {
    display: block;
}
.ip-classification-header--active .ip-classification-header__icon {
    color: #f1cb62;
}
.ip-classification-header--active .ip-classification-header__league .font {
    color: #F1CB62;
}
.ip-classification-header__wrap {
    width: 100%;
    padding: 10px;
}
.ip-classification-header__clear:hover, .ip-classification-header__clear--active {
    color: #F1CB62;
}
.ip-classification-header__league {
    margin-left: 60px;
    margin-right: auto;
    cursor: pointer;
}
.ip-classification-header__league:hover .font, .ip-classification-header__league--active .font {
    color: #F1CB62; }
.ip-classification-header__icon {
    width: 16px;
    height: 16px; }
.ip-classification-header__streaming {
    margin-left: 20px;
}
.ip-classification-header__streaming:hover label, .ip-classification-header__streaming--active label {
    color: #fff; }
.ip-classification-header__dropdown {
    background-color: #232737;
    padding: 20px 5%;
    display: none; }
.ip-classification-header__hr {
    border: 0;
    background-color: #383A4A;
    height: 1px;
    margin: 20px 0; }
.ip-classification-header__checkbox {
    max-width: 25%;
    width: 100%;
    padding-right: 10px; }
.ip-classification-header__text {
    color: #fff; }
.ip-classification-header__btn {
    width: 80px !important;
    margin: 0 5px; }
.ip-classification-header__btn.btn--outline {
    border-color: #383A4A; }
.ip-classification-header__btn.btn--outline:hover {
    border-color: #fff; }
.ip-classification-header__btn.btn--outline:hover > * {
    color: #fff; }
.ip-classification-header .dropdown {
    z-index: 3;
}
.ip-classification-header .dropdown:hover .dropdown__toggle, .ip-classification-header .dropdown--active .dropdown__toggle {
    color: #fff;
}
.ip-classification-header .dropdown__menu {
    min-width: 200px;
    right: 0;
    left: auto;
    background-color: #fff;
}
.ip-classification-header .dropdown__item {
    border-bottom: 1px solid rgba(194, 211, 212, 0.5); }
.ip-classification-header .dropdown__item:last-child {
    border-bottom: 0; }
.ip-classification-header .dropdown__item:hover, .ip-classification-header .dropdown__item--active {
    background-color: rgba(194, 211, 212, 0.5);
}
.ip-classification-prev, .ip-classification-next {
    position: absolute;
    top: 0px;
    z-index: 2;
    cursor: pointer;
    border: none;
    height: 100%;
    padding: 0px 15px;
    background: #1a1e3080;
    color: #8c8e97;
    display: none;
}
.ip-classification-prev {
    left: 0px;
}
.ip-classification-next {
    right: 0px;
}
.ip-classification-prev:hover, .ip-classification-next:hover {
    color: #f1cb62;
    background: #131625a6;
}
.ip-classification-prev::before, .ip-classification-next::before {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 300;
}
.ip-classification-prev::before {
    content: '\f104';
}
.ip-classification-next::before {
    content: '\f105';
}


.slides-prev, .slides-next {
    position: absolute;
    top: 0px;
    z-index: 2;
    cursor: pointer;
    border: none;
    height: 100%;
    width: 20px;
    padding: 0px 15px;
    background: #1a1e3080;
    color: #8c8e97;
    display: flex;
    opacity: 0;
    transition: opacity .5s;
}
.wn-banner-slider:hover .slides-next, .wn-banner-slider:hover .slides-prev  {
    opacity: 0.64;
}
.slides-prev {
    left: 0px;
}
.slides-next {
    right: 0px;
}
.slides-prev:hover, .slides-next:hover {
    color: #f1cb62;
    background: #131625a6;
}
.slides-prev::before, .slides-next::before {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 300;
}
.slides-prev::before {
    content: '\f104';
}
.slides-next::before {
    content: '\f105';
}
.ip-competition {
	width: 100%;
	background: #ffffff;
	min-height: 88vh;
}
.ip-competition-empty {
	width: 100%;
	min-height: 55px;
	background: #ffffff;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ip-competition__table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	background-color: #fff;
}
.ip-competition__table .td {
	position: relative;
	padding: 0 5px;
}
.ip-competition__table .td--border:before {
	width: 1px;
	height: 28px;
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	background-color: rgba(194, 211, 212, 0.5);
}
.ip-competition__table .tr {
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ip-competition__table .tr--title:after,
.ip-competition__table .tr--subtitle:after {
	content: '';
	display: table-cell;
	width: 65px;
}
.ip-competition__table .tr--title .td,
.ip-competition__table .tr--subtitle .td {
	height: 38px;
	width: 20%;
}
.ip-competition__table .tr--title .td:first-child,
.ip-competition__table .tr--subtitle .td:first-child {
	width: auto;
	flex-grow: 1;
}
.ip-competition__table .tr--title {
	background-color: #f6f8ff;
}
.ip-competition__table .tr--title .td {
	height: 38px;
}
.ip-competition__table .tr--subtitle .td {
	height: 28px;
}
.ip-competition__table .tr--body .td {
	height: 75px;
	/*width: 20%; */
}
.ip-competition__table .tr--body .td:nth-child(2) {
	width: auto;
	flex-grow: 1;
}
.ip-competition__table .tr--body .td:first-child,
.ip-competition__table .tr--body .td:last-child {
	width: 75px;
}
.tournament--action-cell {
	width: 100px !important;
}
.ip-competition__header {
	display: flex;
	align-items: center;
	padding: 0 10px;
	height: 38px;
	background-color: #f6f8ff;
}
.ip-competition__body {
	background-color: #fff;
}
.ip-competition__tab {
	width: 100%;
	height: 38px;
	padding: 0 10px;
	background-color: #fff;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.ip-competition__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: relative;
	margin-right: 20px;
}
.ip-competition__link:after {
	width: 0;
	height: 4px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.ip-competition__link:hover,
.ip-competition__link--active {
	color: #6c90fc;
}
.ip-competition__link:hover:after,
.ip-competition__link--active:after {
	width: 100%;
}
.ip-competition__info {
	width: 40%;
	flex: 1 1;
	margin: 0 2px;
	border-radius: 2px;
	border: 1px solid #e7e7e7;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
}
.ip-competition__info:first-child {
	margin-left: 0;
}
.ip-competition__info:last-child {
	margin-right: 0;
}
.ip-competition__info .ip-competition__coff {
	border-radius: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 0;
}
.ip-competition__info .ip-competition__coff:first-child {
	border-left: 0;
}
.ip-competition__goal {
	border-radius: 1px;
	border: 1px solid #f1cb62;
	box-shadow: 0px 2px 4px rgba(241, 203, 98, 0.4);
	background-color: rgba(241, 203, 98, 0.5);
	padding: 0 5px;
	margin-left: 10px;
	margin-right: auto;
}
.ip-competition__action--right {
	margin-right: 0;
	margin-left: auto;
}
.ip-competition__action--left {
	margin-left: 0;
	margin-right: auto;
}
.ip-competition__action {
	margin: 0px 5px;
}
.ip-competition__favourite {
	color: rgba(151, 151, 151, 0.3);
	font-size: 14px;
	cursor: pointer;
	text-align: center;
}
.ip-competition__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
}
.ip-competition__favourite:hover:after,
.ip-competition__favourite--active:after {
	content: '\f005';
	font-weight: 900;
	color: #f1cb62;
	font-family: 'Font Awesome 5 Pro';
}
.ip-competition__coff {
  display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1;
	flex-wrap: wrap;
	min-height: 28px;
}
.ip-competition__coff > * {
	white-space: nowrap;
	margin: 0 3px;
	line-height: 1;
	/* -webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end; */
}
.ip-competition__coff--big {
	flex: 2 1;
}
.nowrap {
	white-space: nowrap;
	max-width: 100%;
}
.ip-competition__coff--big span.font--default-alpha-05 {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: break-word;
	white-space: nowrap;
}
.ip-competition__coff.active {
	background-color: #6c90fc !important;
}
.ip-competition__coff.active * {
	color: #ffffff !important;
}
.ip-competition__coff--outline {
	border: 1px solid rgba(108, 144, 252, 0.55) !important;
	background-color: #f6f8ff !important;
}
.ip-competition__coff--disabled {
	position: relative;
	pointer-events: none;
}
.ip-competition__coff--disabled i {
	font-size: 12px;
	color: rgba(35, 39, 55, 0.5);
}
.ip-competition__dropdown {
	position: relative;
	z-index: 1;
}
.ip-competition__dropdown .dropdown__toggle {
	display: inline-block;
	margin-top: 4px;
}
.ip-competition__dropdown .dropdown__menu {
	background-color: #f6f8ff;
}
.ip-competition__dropdown .dropdown__item {
	border-bottom: 1px solid #e7e7e7;
}
.ip-competition__dropdown .dropdown__item:hover,
.ip-competition__dropdown .dropdown__item--active {
	background-color: #dde4ff;
}
.ip-competition__drop {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10px;
	position: relative;
	flex-shrink: 0;
}
/*.ip-competition__drop:hover .dropdown, .ip-competition__drop--active .dropdown {*/
/*    display: block;*/
/*}*/
.ip-competition__drop .dropdown {
	position: absolute;
	right: 0;
	top: 100%;
	min-width: 200px;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	padding: 5px 20px 10px 5px;
	z-index: 3;
}
.ip-competition__drop .dropdown__item {
	padding: 0;
	margin: 5px 0;
	border: 1px solid #e7e7e7;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.01);
	border-radius: 2px;
}
.ip-competition__drop .dropdown .c-icon {
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}
.ip-competition__drop .dropdown .btn--coff {
	box-shadow: none;
	border-top: 0;
	border-bottom: 0;
	border-left: 0;
}
.ip-competition__drop .dropdown .btn--coff:last-child {
	border-right: 0;
}
.ip-competition .text-ellipsis {
	max-width: 100%;
}
.event-content {
	min-height: 400px;
}
.c-icon.not-active {
	-webkit-filter: /* grayscale(70%) */ invert(50%);
	        filter: /* grayscale(70%) */ invert(50%);
}
.flex-1-1 {
	flex: 1 1;
}
.l-h-32 {
	line-height: 52px;
}
.h-search .h-search__icon:after {
	position: relative;
	right: 36px;
}
.h-search--leagues {
	display: inline-flex;
	height: 32px;
	padding: 0 8px;
	transition: all 0.5s;
	position: relative;
	z-index: 10;
	width: 21rem;
}
.h-search__form {
	height: 32px;
	position: relative;
	background-color: #383a4a;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #1b1d2c;
	width: 150%;
}
.h-search__icon {
	color: rgba(255, 255, 255, 0.5);
	font-size: 12px;
	position: absolute;
	left: 10px;
}
.fal.fa-search:after {
	content: '\f002';
}
.ip-classification-header .h-search__field {
	padding-left: 30px;
}
.h-search__field--leagues {
	display: block;
	width: 100%;
	height: 100%;
	color: #fff !important;
	font-size: 13px;
	padding-left: 32px !important;
	padding-right: 32px !important;
}
.h-search__reset {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	color: rgba(255, 255, 255, 0.5);
	display: none;
}

.footer {
    padding: 0 25px;
    background-color: #1F2133; }
.footer__content {
    padding: 0 10px; }
.footer__info {
    display: flex;
    padding: 25px 0; }
.footer__list {
    padding-right: 5px;
    flex: 1 1; }
.footer__list--empty {
    padding-top: 35px; }
.footer__list:not(.footer__list--empty) .footer__item:first-child {
    margin-bottom: 20px; }
.footer__item {
    margin-bottom: 10px; }
.footer__link:hover, .footer__link--active {
    color: #fff; }
.footer__partners {
    height: 58px;
    border-top: 1px solid #545763;
    border-bottom: 1px solid #545763; }
.footer__partner {
    min-width: 100px;
    height: 80%;
    background-color: #f2f2f2; }
.footer__copyright {
    display: flex;
    align-items: center;
    height: 42px; }
.ip-scoreboard {
	width: 100%;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	position: relative;
}
@media screen and (min-width: 1400px) {
    .ip-scoreboard{
        background-size: cover;
    }
}
.ip-scoreboard:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 0;
}
.ip-scoreboard__header {
    position: relative;
    z-index: 1;
    height: 40px;
}
.ip-scoreboard__option {
	position: absolute;
    right: 10px;
    top: 10px;

}
.ip-scoreboard-teams {
    position: absolute;
		left: 36%;
    right: 32%;
    top: 6px;

}
.ip-scoreboard-teams-enter {
    opacity: 0;
}
.ip-scoreboard-teams-enter-active {
    opacity: 1;
    transition: opacity .3s 200ms;
}
.ip-scoreboard-teams-exit {
    opacity: 1;
}
.ip-scoreboard-teams-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
.ip-scoreboard-team-name:first-child::after {
    content: 'vs';
    margin-left: 10px;
}
.ip-scoreboard__favourite:after {
	content: '\f005';
	font-size: 14px;
	font-weight: 300;
	font-family: 'Font Awesome 5 Pro';
	color: #f1cb62;
}
.ip-scoreboard__favourite:hover:after,
.ip-scoreboard__favourite--active:after {
	content: '\f005';
	font-weight: 900;
}
.ip-scoreboard__hide {
	border-color: rgba(255, 255, 255, 0.5) !important;
    width: 100px !important;
    padding: 0px !important;
    border-radius: 2px;
}
.ip-scoreboard__hide:hover,
.ip-scoreboard__hide--active {
	border-color: #fff;
}
.ip-scoreboard__hide:hover > *,
.ip-scoreboard__hide--active > * {
	color: #fff;
}
.ip-scoreboard__body--wrapper {
	width: 100%;
	max-width: 900px;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.ip-scoreboard__body--wrapper-enter {
	max-height: 0px;
}
.ip-scoreboard__body--wrapper-enter-active {
	max-height: 165px;
	transition: max-height 0.2s linear;
}
.ip-scoreboard__body--wrapper-exit {
	max-height: 165px;
}
.ip-scoreboard__body--wrapper-exit-active {
    max-height: 0px;
    transition: max-height 0.2s linear;
}

.ip-scoreboard__body--wrapper-enter .ip-scoreboard__body{
    transform: translate(0, -120%);
    opacity: 0;
}
.ip-scoreboard__body--wrapper-enter-active .ip-scoreboard__body{
    transform: translate(0, 0);
    opacity: 1;
    transition: transform 0.2s cubic-bezier(.11,.71,.3,.71), opacity .1s 0.2s cubic-bezier(.11,.71,.3,.71);
}
.ip-scoreboard__body--wrapper-exit .ip-scoreboard__body{
    transform: translate(0, 0);
    opacity: 1;
}
.ip-scoreboard__body--wrapper-exit-active .ip-scoreboard__body{
    transform: translate(0, -120%);
    opacity: 0;
    transition: opacity;
}
.ip-scoreboard__body {
	padding-top: 30px;
	padding-bottom: 30px;
}
.ip-scoreboard__score {
	width: 100%;
}
.ip-scoreboard__stats {
	width: 14px;
	height: 12px;
}
.ip-scoreboard__wrap {
	flex: 1 1 auto;
	width: 33%;
}
.ip-scoreboard__wrap--small {
	width: 60px;
}
.ip-scoreboard__team {
	width: 12px;
	height: 12px;
}
.ip-scoreboard__cursor {
	width: 6px;
	height: 6px;
	background-color: rgba(255, 255, 255, 0.5);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 50%;
}
.ip-scoreboard__dots {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	position: relative;
	z-index: 0;
}
.ip-scoreboard__dots span {
	width: 6px;
	height: 6px;
	display: block;
	cursor: pointer;
	margin: 0 2px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.5);
}
.ip-scoreboard__dots span.active,
.ip-scoreboard__dots span:hover {
	background-color: #fff;
}

.ip-scoreboard__table {
	overflow: hidden;
}

.ip-scoreboard__table,
.ip-scoreboard__events {
	position: relative;
	z-index: 1;
	margin: 15px 40px 0 40px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}

/*.scoreboard-fade-enter  {*/
/*    opacity: 0;*/
/*}*/
/*.scoreboard-fade-enter-active {*/
/*    opacity: 1;*/
/*    transition: opacity 100ms;*/
/*}*/
/*.scoreboard-fade-exit {*/
/*    opacity: 1;*/
/*}*/
/*.scoreboard-fade-exit-active {*/
/*    opacity: 0;*/
/*    transition: opacity 100ms;*/
/*}*/

.ip-scoreboard__events {
	padding: 5px 0;
	height: 72px;
	overflow: hidden;
	background-color: rgba(56, 58, 74, 0.95);
}

/*.ev-enter-active,.ev-enter-done,.ev-exit, .ev-exit-active{*/
/*    overflow: visible;*/
/*}*/
/*.ev-enter .ip-scoreboard__block{*/
/*    max-height: 72px;*/
/*    transition: max-height .3s linear;*/
/*}*/
/*.ev-enter-done .ip-scoreboard__block , .ev-enter-active .ip-scoreboard__block , .ev-exit .ip-scoreboard__block {*/
/*    max-height: 225px;*/
/*    transition: max-height .3s linear;*/
/*}*/
/*.ev-exit-active .ip-scoreboard__block{*/
/*    max-height: 72px;*/
/*    transition: max-height .3s linear;*/
/*}*/

/*.ev-enter-active .ip-scoreboard__more ,*/
/*.ev-enter-done .ip-scoreboard__more ,*/
/*.ev-exit .ip-scoreboard__more,*/
/*.ev-exit-active .ip-scoreboard__more{*/
/*    position: static;*/
/*}*/
/*.ev-exit-done .ip-scoreboard__more {*/
/*    position: absolute;*/
/*}*/
/*.ip-scoreboard__more:after {*/
/*    transform: rotate(0);*/
/*    transition: transform .3s linear;*/
/*}*/
/*.ev-enter-active .ip-scoreboard__more:after ,*/
/*.ev-enter-done .ip-scoreboard__more:after ,*/
/*.ev-exit .ip-scoreboard__more:after,*/
/*.ev-exit-active .ip-scoreboard__more:after {*/
/*    transform: rotate(-180deg);*/
/*    transition: transform .3s linear;*/
/*}*/
/*.ev-enter .scroll, .ev-enter-active .scroll, .ev-exit .scroll, .ev-exit-active .scroll {*/
/*    overflow-x: hidden;*/
/*    overflow-y: hidden;*/
/*}*/

.ip-scoreboard__event {
	padding: 2px 5px;
}
.ip-scoreboard__event:first-child {
	margin-top: 0;
}
.ip-scoreboard__event:last-child {
	margin-bottom: 0;
}
.ip-scoreboard__block {
	position: relative;
	z-index: 1;
	background-color: rgba(56, 58, 74, 0.95);
}
.ip-scoreboard__more {
	position: absolute;
	left: 0;
	right: 0;
	top: 62px;
	height: 13px;
	background-color: rgba(56, 58, 74, 0.95);
	color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	border-radius: 0 0 5px 5px;
	z-index: 1;
}
.ip-scoreboard__more:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}
.ip-scoreboard__points {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	min-width: 15px;
	max-width: 15px;
	height: 19px;
	border-radius: 2px;
	flex-shrink: 0;
}
.ip-scoreboard__points--blue {
	background-color: #6c90fc;
}
.ip-scoreboard__points--gold {
	background-color: #f1cb62;
}
.ip-scoreboard__points--green {
	background-color: #15b93a;
}
.ip-scoreboard__field {
	position: absolute;
	top: 50%;
	left: 10px;
	max-height: 35px;
	max-width: 50px;
	transform: translateY(-50%);
}
.ip-scoreboard__table {
	border-radius: 5px;
	position: relative;
	background-color: rgba(56, 58, 74, 0.5);
	padding: 0px 20px;
}
.ip-scoreboard__table .table {
	display: flex;
	width: 100%;
}
.ip-scoreboard__table .column {
	display: flex;
	flex-direction: column;
	flex: 1 1;
}
.ip-scoreboard__table .column:first-child {
	overflow: hidden;
	max-width: 50%;
}
.ip-scoreboard__table .column:first-child .th {
	justify-content: flex-start;
}
.ip-scoreboard__table .column:last-child {
	border-bottom: 0;
}
.ip-scoreboard__table .th {
	display: flex;
	align-items: center;
	height: 24px;
	padding: 0 5px;
	border-bottom: 1px solid rgba(216, 216, 216, 0.2);
}
.ip-scoreboard__table .th:last-child {
	border-bottom: 0;
}
.ip-scoreboard__table .th > * {
	width: 100%;
	text-align: center;
	margin: 0 auto;
}
.ip-scoreboard__table .th--big {
	height: 48px;
	padding-left: 60px;
	position: relative;
}
.ip-scoreboard__table .th--right > * {
	text-align: right;
}
.ip-scoreboard__table .th--left > * {
	text-align: left;
}
.ip-scoreboard__table .th .text-ellipsis {
	max-width: 100%;
}
.ip-scoreboard__table .th .dropdown {
	position: static;
}
.ip-scoreboard__table .th .dropdown__toggle {
	width: 10px;
	height: 10px;
}
.ip-scoreboard__table .th .dropdown__menu {
	right: 0;
	left: 0;
	background-color: #f1f3f7;
	border-radius: 2px;
	overflow: hidden;
	z-index: 3;
}
.ip-scoreboard__table .th .dropdown .title {
	background-color: #e1e5ee;
	height: 33px;
	padding: 0 10px;
}
.ip-scoreboard__table .th .dropdown .table--market .column:last-child .th {
	border-right: 0;
}
.ip-scoreboard__table .th .dropdown .table--market .th {
	border-bottom: 1px solid #e1e5ee;
	border-right: 1px solid #e1e5ee;
}
.ip-scoreboard__table .th .dropdown .table .th {
	height: 38px;
	border: 0;
}
.ip-scoreboard__table .th .dropdown .table .th:first-child {
	background-color: #e1e5ee;
}
.ip-scoreboard__table .th .dropdown .table .btn {
	border: 0;
	justify-content: space-between;
	padding: 0 10px;
	background-color: transparent;
}
.ip-scoreboard__table .th .dropdown .table .btn:hover,
.ip-scoreboard__table .th .dropdown .table .btn--active {
	background-color: #e1e5ee;
}

.unmount {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.2s cubic-bezier(1, 0.03, 0.97, 0.92),
		height 0.2s cubic-bezier(1, 0.03, 0.97, 0.92);
}

.none {
	overflow: hidden !important;
	opacity: 0 !important;
}
.gll-market-group {
	transition: all cubic-bezier(0.65, 0.09, 0.34, 0.93);
}
.gll-markets-wrapper,
.gll-markets-wrapper--favourites {
	display: flex;
	flex-direction: column;
	position: relative;
}

.gll-market-group-enter {
	opacity: 0;
	max-height: 0px;
	transform: scale(0.5);
	transition: opacity 0.7s linear, transform 0.7s linear;
}
.gll-market-group-enter-active {
	opacity: 1;
	max-height: 1000px;
	transform: scale(1);
	transition: opacity 0.8s 0.3s cubic-bezier(0.8, 0.21, 0.84, 0.48),
		max-height 0.9s 0.3s cubic-bezier(0.8, 0.21, 0.84, 0.48),
		transform 0.8s 0.3s cubic-bezier(0.8, 0.21, 0.84, 0.48);
}

.gll-market-group-exit {
	opacity: 1;
	max-height: 1000px;
	transform: scale(0.9);
	transition: opacity 0.6s linear,
		max-height 0.5s cubic-bezier(0.21, 0.73, 0.35, 0.83), transform 0.6s linear;
}
.gll-market-group-exit-active {
	opacity: 0;
	max-height: 0px;
	transform: scale(0.7);
	transition: opacity 0.5s linear, max-height 0.5s linear, transform 0.5s linear;
}

.gll-market-group--closed .gll-market-group__header {
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
}
.gll-market-group--closed .gll-market-group__header:after {
	content: '\f107';
}

.gll-market-group__body {
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}
.gll-market-group__body-enter {
	max-height: 0px;
}
.gll-market-group__body-enter-active {
	max-height: 1000px;
	transition: max-height 0.3s cubic-bezier(0.54, 0.1, 0.81, 0.27);
}
.gll-market-group__body-exit {
	max-height: 1000px;
}
.gll-market-group__body-exit-active {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(0.54, 0.77, 0.04, 1.05);
}
.gll-market-group__header {
	width: 100%;
	height: 28px;
	display: flex;
	align-items: center;
	padding: 0 40px 0 10px;
	background-color: #f1f3f7;
	position: relative;
	cursor: pointer;
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
	transition: all 0.2s linear;
}
.gll-market-group__header:after {
	content: '\f106';
	font-family: 'Font Awesome 5 Pro';
	font-size: 14px;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.gll-market-group__name {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 10px;
	width: 100%;
	text-align: center;
	position: absolute;
}

.gll-market-group__favourite {
	font-size: 12px;
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
}
.gll-market-group__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
}

.gll-market-group__favourite--active:after,
.gll-market-group__favourite:hover:after {
	content: '\f005';
	font-weight: 900;
	color: #f1cb62;
}

.gll-market-group__tab {
	display: flex;
	justify-content: flex-end;
	padding: 0 10px;
	height: 100%;
	flex: 1 1;
}

.gll-market-group__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 5px;
	position: relative;
}
.gll-market-group__link:after {
	width: 0;
	height: 3px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.gll-market-group__link--active > *,
.gll-market-group__link:hover > * {
	color: #6c90fc;
}
.gll-market-group__link--active:after,
.gll-market-group__link:hover:after {
	width: 100%;
}
.gll-market-group__link:first-child {
	margin-left: 0;
}
.gll-market-group__link:last-child {
	margin-right: 0;
}

.gll-market-group__offers {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	padding: 0 6px;
	border-radius: 2px;
	border: 1px solid #788485;
	flex-shrink: 0;
	margin-right: 10px;
	margin-left: auto;
	cursor: pointer;
}

.gll-market-group__row {
	display: flex;
	align-items: stretch;
	background-color: #fff;
}
.gll-market-group__row.col {
	flex-direction: column;
}
.gll-market-group__column {
	flex: 1 1;
	border-right: 1px solid rgba(223, 223, 223, 0.8);
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
	background-color: #f2f2f2;
}
.gll-market-group__column:last-child {
	border-right: 0;
}

.gll-market-group__cell {
	flex: 1 1;
	overflow: hidden;
	border-right: 1px solid rgba(223, 223, 223, 0.8);
	height: 40px;
	border-bottom: 1px solid rgba(223, 223, 223, 0.8);
}

.gll-market-group__btn {
	width: 100%;
	height: 100%;
	border: 0 !important;
	border-radius: 0;
	padding: 0 10px;
	justify-content: center !important;
	align-items: center;
}
.gll-market-group__btn--active {
	background-color: #6c90fc !important;
}
.gll-market-group__btn--active span{
	color: #ffffff !important;
}
.gll-market-group__btn--active > .font {
	color: #fff;
}
.gll-market-group__btn--center {
	width: 100%;
	height: 100%;
	border: 0 !important;
	border-radius: 0;
	padding: 0 10px;
	justify-content: center !important;
}

.gll-market-group__coff {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	background-color: #fff;
}
.gll-market-group__coff--center {
	justify-content: center;
}

.gll-market-group__title {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 28px;
	padding: 0 10px;
	background-color: #e1e5ee;
}

.gll-market-group__title-border {
	border-bottom: 1px solid #d5d5d5;
}
.gll-market-group__column-border {
	border-right: 1px solid #d5d5d5;
}

.gll-market-group__range-group {
	flex: 2 1;
	padding: 0 20px;
}

.gll-market-group__btn-group {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 144px;
	height: 32px;
	flex: 1 1;
	border-radius: 2px;
	padding: 2px;
	border: 1px solid #e7e7e7;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.0824558);
	align-self: center;
}

.gll-market-group__count {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1;
	height: 100%;
	border-radius: inherit;
	cursor: pointer;
}
.gll-market-group__count--active {
	background-color: #6c90fc;
}
.gll-market-group__count--active > * {
	color: #fff !important;
}

.tooltip {
	position: relative;
	margin: 0 5px;
}
.tooltip--active .tooltip__dropdown,
.tooltip:hover .tooltip__dropdown {
	display: block;
}
.tooltip__item {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #f1cb62;
	font-size: 8px;
	font-style: italic;
	font-weight: 700;
	line-height: 1.8;
	text-align: center;
	color: #fff;
}
.tooltip__dropdown {
	display: none;
	position: absolute;
	top: calc(100% + 10px);
	left: -5px;
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	background-color: #fff;
	z-index: 1;
	min-width: 220px;
	padding: 10px;
}

.cb-upload__header {
	height: 28px;
}

.cb-upload__body {
	padding: 20px 0;
}

.cb-upload__item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
	flex: 1 1;
	position: relative;
}
.cb-upload__item:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2px;
	width: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.cb-upload__item--active:after,
.cb-upload__item:hover:after {
	width: 100%;
}
.cb-upload__item--active .cb-upload__text,
.cb-upload__item--active .cb-upload__icon,
.cb-upload__item:hover .cb-upload__text,
.cb-upload__item:hover .cb-upload__icon {
	color: #6c90fc;
}

.cb-upload__icon {
	width: 22px;
	height: 14px;
	margin-right: 6px;
	color: #9a9b9f;
}

.cb-upload__drop {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px dashed rgba(35, 39, 55, 0.5);
	border-radius: 2px;
}

.cb-upload__upload {
	display: none;
}

.cb-message {
	padding: 10px;
}
.cb-message__icon {
	width: 42px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 15px auto;
	font-family: 'Font Awesome 5 Pro';
	font-size: 32px;
}
.cb-message--success .cb-message__icon:after {
	content: '\f118';
}
.cb-message--success .cb-message__title,
.cb-message--success .cb-message__icon {
	color: #15b93a;
}
.cb-message--error .cb-message__icon:after {
	content: '\f119';
}
.cb-message--error .cb-message__title,
.cb-message--error .cb-message__icon {
	color: #fc3164;
}

.cb-alert {
	width: 100%;
	height: 30px;
	border-radius: 2px;
	margin: 10px 0;
}
.cb-alert__wrap {
	padding: 0 10px;
}
.cb-alert__text {
	max-width: 80%;
}
.cb-alert--default,
.cb-alert--confirmed,
.cb-alert--failed,
.cb-alert--uploading {
	background-color: #f7fafd;
	border: 1px solid #d2dcf1;
}
.cb-alert--default .cb-alert__text {
	color: rgba(35, 39, 55, 0.5);
}
.cb-alert--default .cb-alert__times {
	cursor: pointer;
	font-size: 16px;
}
.cb-alert--confirmed .cb-alert__message {
	color: #15b93a;
}
.cb-alert--confirmed .cb-alert__text {
	color: rgba(35, 39, 55, 0.5);
}
.cb-alert--bad {
	background-color: #e1e5ee;
}
.cb-alert--warning {
	background-color: rgba(252, 49, 100, 0.08);
	border: 1px solid rgba(252, 49, 100, 0.5);
}
.cb-alert--warning .cb-alert__message {
	color: #fc3164;
}
.cb-alert--warning .cb-alert__text {
	color: #fc3164;
}
.cb-alert--failed .cb-alert__text {
	color: #fc3164;
}
.cb-alert--failed .cb-alert__reload {
	cursor: pointer;
}
.cb-alert--failed .cb-alert__reload .fal {
	color: #15b93a;
	font-size: 14px;
}
.cb-alert--uploading {
	height: auto;
	padding-top: 5px;
	padding-bottom: 10px;
}
.cb-alert--uploading .cb-alert__text {
	color: rgba(35, 39, 55, 0.5);
}
.cb-alert--uploading .cb-alert__separate {
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #232737;
	margin: 0 5px;
}
.cb-alert--uploading .cb-alert__progress {
	width: 100%;
	height: 2px;
	background-color: #d8d8d8;
}
.cb-alert--uploading .cb-alert__progress > div {
	height: 100%;
	background-color: #6c90fc;
}
.cb-alert--uploading .cb-alert__buttons {
	margin-right: 0;
	margin-left: auto;
}
.cb-alert--uploading .cb-alert__button {
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	border-radius: 50%;
	background-color: transparent;
	overflow: hidden;
}
.cb-alert--uploading .cb-alert__button--stop {
	background-color: rgba(252, 49, 100, 0.3);
	position: relative;
}
.cb-alert--uploading .cb-alert__button--stop:hover {
	background-color: rgba(252, 49, 100, 0.5);
}
.cb-alert--uploading .cb-alert__button--stop i {
	text-align: center;
}
.cb-alert--uploading .cb-alert__button--stop i:before,
.cb-alert--uploading .cb-alert__button--stop i:after {
	width: 1px;
	height: 7px;
	background-color: rgba(252, 49, 100, 0.5);
	position: absolute;
	top: 50%;
	left: 50%;
	content: '';
}
.cb-alert--uploading .cb-alert__button--stop i:before {
	transform: translate(-50%, -50%) rotate(45deg);
}
.cb-alert--uploading .cb-alert__button--stop i:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}
.cb-alert--uploading .cb-alert__button--pause {
	background-color: #d0ddea;
}
.cb-alert--uploading .cb-alert__button--pause:hover {
	background-color: #9a9b9f;
}
.cb-alert--uploading .cb-alert__button--resume:hover {
	background-color: #9a9b9f;
}
.cb-alert--uploading .cb-alert__button--pause i {
	display: flex;
	justify-content: center;
	align-items: center;
}
.cb-alert--uploading .cb-alert__button--resume i {
	border-style: solid;
	border-width: 37px;
	border-color: #202020;
}
.cb-alert--uploading .cb-alert__button--pause i:before,
.cb-alert--uploading .cb-alert__button--pause i:after {
	width: 2px;
	height: 6px;
	background-color: rgba(35, 39, 55, 0.5);
	margin: 0 1px;
	content: '';
}

.pause {
	background-color: #d0ddea;
}
.pause:hover {
	background-color: #9a9b9f;
}

.cb-alert--uploading .cb-alert__button--resume i:before,
.cb-alert--uploading .cb-alert__button--resume i:after {
	width: 2px;
	height: 6px;
	content: '\f04b';
}

.cb-popup {
	display: none;
	max-width: 400px;
	width: 100%;
	position: absolute;
	padding: 20px;
	left: 50%;
	top: 20px;
	z-index: 1;
	transform: translateX(-50%);
	background-color: #fff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	overflow: hidden;
}
.cb-popup--center {
	top: 50%;
	transform: translate(-50%, -50%);
}
.cb-popup__wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.cb-popup__close {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	background-color: #f6f8ff;
	border-radius: 50%;
	cursor: pointer;
}
.cb-popup__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate(160%, -70%);
	color: #383a4a;
	font-size: 14px;
}
.cb-popup__notification {
	width: 100%;
	height: 38px;
	background-color: #f1f3f7;
	margin-top: 24px;
}
.cb-popup--block {
	display: block;
}

.cb-table {
	border-collapse: collapse;
	border-spacing: 0 10px;
	width: 100%;
}
.cb-table--custom {
	table-layout: fixed;
}
.cb-table__th {
	height: 25px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__th:first-child {
	border-left: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__th:last-child {
	border-right: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__td {
	position: relative;
	height: 40px;
	border: 1px solid rgba(194, 211, 212, 0.5);
}
.cb-table__td--border {
	border-right: 0 !important;
	border-left: 0 !important;
}
.cb-table__td--border:after {
	content: '';
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 0;
	width: 1px;
	background-color: rgba(194, 211, 212, 0.5);
}
.cb-table__th,
.cb-table__td {
	padding: 5px;
}
.cb-table__tr {
	display: table-row;
}
.cb-table__tr--hide {
	display: none;
}
.cb-table__tr--show {
	display: table-row;
	background-color: #f6f8ff;
}
.cb-table__tr--title {
	height: 26px;
	background-color: #f6f8ff;
}

.cb-tab {
	display: flex;
	align-items: stretch;
	height: 100%;
}
.cb-tab__item {
	display: flex;
	align-items: center;
	position: relative;
	margin-right: 20px;
	cursor: pointer;
}
.cb-tab__item:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	height: 4px;
	width: 0;
	transition: all 0.3s linear;
}
.cb-tab__item:hover:after,
.cb-tab__item--active:after {
	width: 100%;
}
.cb-navbar {
	border-radius: 2px;
	overflow: none;
}
.cb-navbar__item {
	position: relative;
	margin-bottom: 1px;
}
.cb-navbar__item > .cb-navbar {
	display: none;
	border-radius: 0;
	margin-top: 1px;
}
.cb-navbar__item:last-child {
	margin-bottom: 0;
}
/*.cb-navbar__item:hover > .cb-navbar,*/
.cb-navbar__item--active > .cb-navbar {
	display: block;
}
.cb-navbar__item:hover > .cb-navbar__link,
.cb-navbar__item--active > .cb-navbar__link {
	background-color: #2d334c;
}
.cb-navbar__item:hover > .cb-navbar__link:after,
.cb-navbar__item--active > .cb-navbar__link:after {
	transform: translateY(-50%) rotate(180deg);
}
.cb-navbar__item:hover > .cb-navbar .cb-navbar__link,
.cb-navbar__item--active > .cb-navbar .cb-navbar__link {
	padding-left: 30px;
	padding-right: 40px;
	color: #9a9b9f;
	font-weight: 400;
}
.cb-navbar__item:hover > .cb-navbar .cb-navbar__link:hover,
.cb-navbar__item:hover > .cb-navbar .cb-navbar__link--active,
.cb-navbar__item--active > .cb-navbar .cb-navbar__link:hover,
.cb-navbar__item--active > .cb-navbar .cb-navbar__link--active {
	color: #f1cb62;
}
.cb-navbar__item--dropdown:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	color: inherit;
	position: absolute;
	top: 15px;
	right: 15px;
	color: #fff;
	transition: all 0.3s linear;
}
.cb-navbar__item--active.cb-navbar__item--dropdown:after {
	transform: rotate(180deg);
}
.cb-navbar__item--notification .cb-navbar__notification {
	display: block;
}
.cb-navbar__link {
	display: flex;
	align-items: center;
	min-height: 39px;
	padding: 0 30px 0 10px;
	position: relative;
	background-color: #1a1e30;
}
.cb-navbar__notification {
	width: 5px;
	height: 5px;
	display: none;
	border-radius: 50%;
	background-color: #f1cb62;
	position: absolute;
	top: 20px;
	right: 40px;
	z-index: 1;
}
.cb-navbar__count {
	width: 27px;
	height: 17px;
	border-radius: 8px;
	border: 1px solid #f1cb62;
	text-align: center;
	position: absolute;
	right: 30px;
}

.cb-account {
	border-radius: 2px;
	overflow: hidden;
	position: relative;
	padding: 10px;
	margin-bottom: 10px;
	background-color: #1a1e30;
}
.cb-account__cell {
	flex: 1 1;
}
.cb-account__identify {
	margin-bottom: 10px;
}
.cb-account__date {
	padding-left: 40px;
}
.cb-account__img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 10px;
}
.cb-account__img img {
	display: block;
}
.cb-account__hr {
	width: 100%;
	height: 1px;
	margin: 10px 0;
	border: 0;
	background-color: #2d334c;
}
.cb-account__progress {
	width: 100%;
	height: 3px;
	margin: 10px 0 15px 0;
	border-radius: 2px;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.2);
}
.cb-account__progress > * {
	height: 100%;
	background-color: #6c90fc;
}

.cb-replenishment {
	width: 376px;
	height: 400px;
	display: none;
	position: absolute;
	left: 50%;
	top: 50%;
	overflow: hidden;
	border-radius: 5px;
	background-color: #434559;
	transform: translate(-50%, -50%);
}
.cb-replenishment__wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.cb-replenishment__header {
	height: 46px;
	background-color: rgba(45, 51, 76, 0.68);
}
.cb-replenishment__body {
	flex: 1 1;
	padding: 20px 16px;
}
.cb-replenishment__img {
	max-width: 100%;
	height: auto;
}
.cb-replenishment__qr {
	width: 100px;
	height: 100px;
	background-color: #fff;
	margin: 10px auto;
}
.cb-replenishment__wallet {
	position: relative;
}
.cb-replenishment__wallet .field {
	width: 190px;
	height: 28px;
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 2px;
	background-color: #fff;
	color: rgba(35, 39, 55, 0.5);
	padding-right: 30px;
}
.cb-replenishment__wallet .copy {
	position: absolute;
	top: 50%;
	right: 5px;
	color: rgba(35, 39, 55, 0.5);
	transform: translateY(-50%);
}
.cb-replenishment__wallet .copy:hover,
.cb-replenishment__wallet .copy--active {
	color: #6c90fc;
}
.cb-replenishment__amount {
	margin-bottom: 10px;
}
.cb-replenishment__amount .field {
	width: 140px;
	height: 28px;
	border-radius: 2px;
	background-color: #fff;
	color: rgba(35, 39, 55, 0.5);
	text-align: center;
	font-weight: 500;
}
.cb-replenishment__amount .message {
	display: none;
	background-color: rgba(252, 49, 100, 0.3);
	border-radius: 2px;
	margin-top: 10px;
}
.cb-replenishment__amount .font {
	color: #fff;
}
.cb-replenishment__amount--error .field {
	color: #fc3164;
}
.cb-replenishment__amount--error .font {
	color: #fc3164;
}
.cb-replenishment__amount--error .message {
	display: flex;
	justify-content: center;
	align-items: center;
}
.cb-replenishment__footer {
	padding: 10px 16px;
	position: relative;
}
.cb-replenishment__footer:before {
	content: '';
	position: absolute;
	height: 1px;
	left: 10px;
	right: 10px;
	top: 0;
	background-color: #2d334c;
}
.cb-replenishment--hide {
	display: none;
}
.cb-replenishment--block {
	display: block;
}

.cabinet .balance {
	position: relative;
}
.cabinet .balance .copy {
	color: #6c90fc;
	cursor: pointer;
}
.cabinet .balance .hash {
	max-width: 90%;
}
.cabinet .balance .external-link {
	color: #6c90fc;
}
.cabinet .balance .external-link * {
	font-size: 14px;
}
.cabinet .balance .cancel {
	border: 1px solid rgba(35, 39, 55, 0.5);
	border-radius: 2px;
	padding: 0 10px;
}
.cabinet .balance .img {
	max-width: 100%;
	height: auto;
	display: block;
}

.cabinet .history {
	position: relative;
}
.cabinet .history .t-subcategory {
	height: 30px;
	background-color: #f6f8ff;
}
.cabinet .history .t-subcategory__left {
	width: 10%;
	padding: 0 5px 0 10px;
}
.cabinet .history .t-subcategory__right {
	width: 90%;
	padding: 0 10px 0 5px;
}
.cabinet .history .table .text-ellipsis {
	margin-right: 5px;
	max-width: 100%;
}
.cabinet .history .score {
	margin-left: 3px;
}

.cabinet .message {
	overflow: hidden;
}
.cabinet .message__content {
	padding: 10px;
}
.cabinet .message__item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 38px;
	padding: 5px;
	background-color: #f1f3f7;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	cursor: pointer;
}
.cabinet .message__item div {
	overflow: hidden;
	padding: 0 2px;
}
.cabinet .message__item div:nth-child(1) {
	width: 5%;
}
.cabinet .message__item div:nth-child(2) {
	width: 25%;
}
.cabinet .message__item div:nth-child(3) {
	width: 60%;
}
.cabinet .message__item div:nth-child(4) {
	width: 10%;
	text-align: right;
}
.cabinet .message__item:hover {
	background-color: #f1f3f7;
}
.cabinet .message__item--checked {
	background-color: #d1d7e4;
}
.cabinet .message__item--new {
	background-color: #fff;
}
.cabinet .message__item--new .font {
	font-weight: 700;
}

.cabinet .account {
	display: flex;
	position: relative;
	padding: 30px 5%;
}
.cabinet .account .left,
.cabinet .account .right {
	flex: 1 1;
}
.cabinet .account .left {
	margin-right: 2.5%;
}
.cabinet .account .right {
	margin-left: 2.5%;
}
.cabinet .account .wrap {
	margin-bottom: 40px;
	position: relative;
}
.cabinet .account .wrap:last-child {
	margin-bottom: 0;
}
.cabinet .account .btn {
	max-width: 280px;
}
.cabinet .account .select {
	flex: 1 1;
	margin: 0 10px;
}
.cabinet .account .select:first-child {
	margin-left: 0;
}
.cabinet .account .select:last-child {
	margin-right: 0;
}
.cabinet .account .title-group {
	margin-bottom: 50px;
}
.cabinet .account .support {
	z-index: 2;
	margin-top: 200px;
	position: relative;
}
.cabinet .account .list__item {
	position: relative;
	padding-left: 10px;
}
.cabinet .account .list__item:before {
	width: 3px;
	height: 3px;
	position: absolute;
	left: 0;
	top: 5px;
	border-radius: 50%;
	background-color: #232737;
	content: '';
}

.cabinet .offers {
	position: relative;
	padding: 10px;
}
.cabinet .offers .offer {
	margin-bottom: 10px;
	border-radius: 2px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background: linear-gradient(90deg, #2c2e3b 0%, #383a4a 100%);
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.cabinet .offers .offer__wrap {
	min-height: 85px;
	padding: 8px 16px;
}
.cabinet .offers .offer__left,
.cabinet .offers .offer__right,
.cabinet .offers .offer__center {
	flex: 1 1;
}
.cabinet .offers .offer__center {
	padding: 0 10px;
}
.cabinet .offers .offer__btn {
	width: 100%;
}
.cabinet .offers .offer__btn--active {
	box-shadow: 0px 0px 8px #f1cb62;
	max-width: 60%;
}
.cabinet .offers .offer__btn--not-active {
	background-color: rgba(255, 255, 255, 0.5);
	border: 1px solid #979797;
	box-shadow: none;
}
.cabinet .offers .offer__btn--more {
	border-color: #f1cb62;
	max-width: 35%;
}
.cabinet .offers .offer__btn--not-more {
	border-color: rgba(255, 255, 255, 0.5);
}
.cabinet .offers .offer .progress__body {
	margin-top: 5px;
}
.cabinet .offers .offer .progress__body > * {
	height: 5px;
	flex: 1 1;
	margin: 0 2px;
	border-radius: 2px;
	border: 1px solid #f1cb62;
	cursor: pointer;
}
.cabinet .offers .offer .progress__body > *.active {
	background-color: #f1cb62;
}
.cabinet .offers .offer .progress__body > *:first-child {
	margin-left: 0;
}
.cabinet .offers .offer .progress__body > *:last-child {
	margin-right: 0;
}
.page.cabinet .content {
	max-height: calc(100vh - 120px);
}

.cabinet {
	width: 100%;
}

.cabinet .content {
	width: 100%;
	max-height: 100%;
}

.cabinet .container {
	background-color: #fff;
	border-radius: 2px;
	overflow: hidden;
	min-height: 700px;
	height: 100%;
	width: 100%;
}
.cabinet .container__head {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.cabinet .container__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e1e5ee;
	padding: 0 10px;
	height: 40px;
}
.cabinet .container__header .dropdown {
	margin-left: auto;
}
.cabinet .container__header .dropdown__menu {
	z-index: 2;
	background-color: #e1e5ee;
}
.cabinet .container__header .dropdown__item:hover,
.cabinet .container__header .dropdown__item--active {
	background-color: #d1d7e5;
}
.cabinet .container__header .hr {
	width: 1px;
	height: 20px;
	border: 0;
	margin: 0 20px;
	background-color: rgba(194, 211, 212, 0.5);
}
.cabinet .container__header .calendar {
	margin-right: 0;
}
.cabinet .container__header .calendar__item {
	color: #6c90fc;
	cursor: pointer;
}
.cabinet .container__header .action__item {
	color: #383a4a;
	margin-left: 10px;
	cursor: pointer;
}
.cabinet .container__header .action__item i {
	font-size: 12px;
}
.cabinet .container__header .action__item:hover,
.cabinet .container__header .action__item--active {
	color: #6c90fc;
}
.cabinet .container__header .action__item:hover .font,
.cabinet .container__header .action__item--active .font {
	color: #6c90fc;
}
.cabinet .container__header .action__item--back {
	margin-left: 0;
}
.cabinet .container__header .action__item--back i {
	font-size: 16px;
}
.timer--gold .timer__top > * {
	color: #f1cb62;
}
.timer__top > *:after {
	/* display: none; */
	content: ':';
	position: absolute;
	top: 50%;
	right: -50%;
	transform: translate(50%, -50%);
}
.timer__top > *:last-child::after {
	display: none;
}
.timer__top > * {
	font-size: 18px !important;
	font-weight: 700 !important;
}
.timer__top > *,
.timer__bottom > * {
	position: relative;
	width: 40px;
	text-align: center;
}
.timer--gold .timer__bottom > * {
	color: rgba(241, 203, 98, 0.5);
}
.timer__bottom > * {
	font-size: 8px;
	color: rgba(35, 39, 55, 0.5);
}
.cb-navbar__item--notification .cb-navbar__notification {
	display: block;
}
.dropdown__menu.active {
	display: block;
	cursor: pointer;
}
.details span {
	cursor: pointer;
}
.b-vs {
	border-radius: 2px;
	background-color: #1a1e30;
	width: 100%;
	cursor: pointer;
}
.b-vs__wrap {
	padding: 10px 12px;
}
.full-height {
	height: 100% !important;
}
.b-vs__title {
	max-width: 70%;
	margin: 0 auto 10px auto;
}
.font--15 {
	font-size: 15px;
}
.align-middle {
	align-items: center;
}
.b-vs__team {
	width: 30%;
	text-align: center;
	overflow: hidden;
}
.b-vs__img {
	width: 36px;
	height: 36px;
}
.m-b-10 {
	margin-bottom: 10px;
}
img {
	max-width: 100%;
	height: auto;
}
.font--semibold {
	font-weight: 700;
}
.font--white {
	color: #fff;
}
.text-ellipsis {
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.b-vs__center {
	width: 40%;
	position: relative;
}
.b-vs__team {
	width: 30%;
	text-align: center;
	overflow: hidden;
}
.b-vs__placeholder {
	z-index: 0;
	font-size: 80px !important;
	font-weight: 700;
	color: rgba(39, 44, 70, 0.55);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -70%);
}
.b-vs__time {
	position: relative;
	z-index: 1;
}
.text-center {
	text-align: center;
}
.b-vs__img {
	width: 36px;
	height: 36px;
}
.b-vs__footer {
	margin-top: 28px;
}
.b-vs__coff {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1;
	height: 33px;
	background-color: #fff;
	border-radius: 2px;
	cursor: pointer;
}
.b-vs__coff:hover {
	background-color: #f2f2f2;
}
.b-vs__coff.active {
	background-color: #f1cb62 !important;
	font-weight: 800 !important;
}
.b-vs__bet {
	flex: 2 1;
	transform: scale(1.02);
}
.btn--blue {
	background-color: #6c90fc;
}
.btn--h-42 {
	height: 42px;
}
.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	padding: 5px 10px;
	font-size: 11px;
	font-weight: 700;
	line-height: 13px;
	border: 0;
	border-radius: 2px;
	padding: 0 2px;
	cursor: pointer;
}
.btn > * {
	transition: all 0.3s linear;
}

.wn-banner {
	width: 296px;
	height: 170px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-direction: column;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	border-radius: 2px;
	margin-right: 10px;
	cursor: pointer;
}
.wn-banner--shadow:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(11, 29, 91, 0.1);
}
.wn-banner__wrap--down {
	justify-content: flex-end;
}
.wn-banner__wrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 15px;
	flex-grow: 1;
}
.wn-banner__wrap,
.wn-banner__footer {
	position: relative;
	z-index: 1;
}
.wn-banner__footer {
	padding: 15px;
	display: flex;
	justify-content: center;
}
.full-width {
	width: 100% !important;
}
.font--16 {
	font-size: 16px;
}
.font--17 {
	font-size: 17px;
}
.h1,
h1 {
	font-size: 55px;
}
.m-r-5 {
	margin-right: 5px;
}

.calendar-wrapper {
	position: absolute;
	display: block;
	width: 350px;
	top: 80px;
	right: 0;
	z-index: 10;
}
.calendar-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 9;
}
.wn-banner-slider {
	position: relative;
	overflow: hidden;
	padding-bottom: 24px;
}
.wn-banner-slider__dots {
	height: 24px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}
.wn-banner-slider__dots span {
	width: 16px;
	height: 4px;
	border-radius: 2px;
	background-color: rgba(108, 144, 252, 0.5);
	margin: 0 5px;
	cursor: pointer;
}
.wn-banner-slider__dots span:hover,
.wn-banner-slider__dots span.active {
	box-shadow: 0px 0px 4px #a4baff;
	background-color: #6c90fc;
}
.wn-banner-slider__wrap {
	/* overflow: hidden; */
	transition: transform 1s;
}

.wn-banner {
	width: 296px;
	height: 170px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-direction: column;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	border-radius: 2px;
	margin-right: 10px;
}
.wn-banner__wrap,
.wn-banner__footer {
	position: relative;
	z-index: 1;
}
.wn-banner__wrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 24px;
	flex-grow: 1;
}
.wn-banner__btn {
	border-color: #f1cb62 !important;
	width: auto !important;
	padding: 0 10px;
	margin: 5px auto 0 0;
}
.wn-banner__btn:hover,
.wn-banner__btn--active {
	background-color: #f1cb62;
}
.wn-banner__btn:hover > *,
.wn-banner__btn--active > * {
	color: #0c1022;
	font-weight: bold;
	transition: none !important;
}
.wn-banner__coff {
	flex: 1 1;
	background-color: #fff;
	border-radius: 0;
	padding: 5px 10px;
	max-width: 76px;
	margin-right: 10px;
	justify-content: space-between;
	border-right: 1px solid #c2d3d4;
}
.wn-banner__coff:last-child {
	border-right: 0;
}
.wn-banner__coff:hover,
.wn-banner__coff--active {
	background-color: #e6e6e6;
}
.wn-banner--shadow:hover::before {
	background: rgba(11, 29, 91, 0.1);
}
.wn-banner--shadow:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(11, 29, 91, 0.2);
}

.wn-nav {
	border-radius: 2px;
	overflow: hidden;
	background-color: #0c1022;
}

.wn-popular {
	background-color: #0c1022;
	border-radius: 2px;
	overflow: hidden;
}
.wn-popular__title {
	display: flex;
	align-items: center;
	width: 100%;
	height: 24px;
	padding: 5px 10px;
	background-color: #2f3346;
	margin-bottom: 1px;
}
.wn-popular__item {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px 10px;
	margin-bottom: 1px;
	background-color: #1a1e30;
}
.wn-popular__item:hover > .font,
.wn-popular__item--active > .font {
	color: #fff;
}

.wn-top-matches {
	background-color: #f1f3f7;
}
.wn-top-matches__title {
	padding: 10px;
}
.wn-top-matches__slider {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px;
	overflow: hidden;
}
.wn-top-matches__item {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
	width: 270px;
	height: 160px;
	background-color: #fff;
	box-shadow: 0px 0px 6px rgba(56, 71, 89, 0.19);
	border-radius: 2px;
	flex-shrink: 0;
	margin-right: 15px;
}
.wn-top-matches__body {
	padding: 15px 10px 10px;
}
.wn-top-matches__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.wn-top-matches__cell {
	flex: 1 1;
	margin: 0 5px;
}
.wn-top-matches__team {
	width: 36px;
	height: 28px;
	margin: 0 auto 5px auto;
}
.wn-top-matches__team img {
	display: block;
	height: 100%;
	margin: 0 auto;
}
.wn-top-matches__btn {
	min-width: 56px;
	margin-top: 10px;
	justify-content: space-between;
	padding: 5px 10px;
}
.wn-top-matches__footer {
	width: 100%;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid rgba(194, 211, 212, 0.5);
}

.wn-classification__tab {
	width: 100%;
	height: 38px;
	margin-bottom: 1px;
}

.wn-classification__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex: 1 1;
	margin-right: 1px;
	background-color: #1a1e30;
	position: relative;
}
.wn-classification__link:after {
	width: 0;
	height: 1px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f1cb62;
	transition: all 0.3s linear;
}
.wn-classification__link:last-child {
	margin-right: 0;
}
.wn-classification__link:hover,
.wn-classification__link--active {
	color: #f1cb62;
}
.wn-classification__link:hover:after,
.wn-classification__link--active:after {
	width: 100%;
}
.wn-classification__link i {
	font-size: 14px;
}

.wn-classification__body {
	background-color: #0c1022;
}

.wn-classification-sport__header {
	width: 100%;
	height: 39px;
	position: relative;
	cursor: pointer;
	padding: 5px 30px 5px 12px;
	background-color: #1a1e30;
	margin-bottom: 1px;
}
/* .wn-classification-sport__header:before {
    content: '';
    width: 6px;
    height: 10px;
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/icon/dots.svg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
} */
.wn-classification-sport__header.to_live::after {
	display: none;
}
.wn-classification-sport {
	z-index: 20;
}
/*.wn-classification-sport__header .drag-handle {*/
/*	width: 6px;*/
/*	height: 10px;*/
/*	content: '';*/
/*	position: absolute;*/
/*	left: 10px;*/
/*	top: 50%;*/
/*	transform: translateY(-50%);*/
/*	background-image: url(../img/icon/dots.svg);*/
/*	background-size: cover;*/
/*	background-position: 50% 50%;*/
/*	background-repeat: no-repeat;*/
/*}*/
/* .wn-classification-sport__header:before {
	width: 6px;
	height: 10px;
	content: '';
	position: absolute;
	left: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-image: url('../img/icon/dots.svg');
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
} */
.wn-classification-sport__header:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-size: 16px;
	/* color: #9a9b9f; */
	color: #fff;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}
.wn-classification-sport__header .count {
	margin-left: auto;
	/* color: rgba(154, 155, 159, 0.5); */
	color: #fff;
}
.wn-classification-sport__header .text {
	margin: 0 5px;
}
.wn-classification-sport__header:hover {
	background-color: #232737;
}
.wn-classification-sport__header:hover .text {
	color: #f1cb62;
}

.wn-classification-sport__body {
	display: none;
}

.wn-classification-sport--active .wn-classification-sport__header {
	background-color: #232737;
}
.wn-classification-sport--active .wn-classification-sport__header:after {
	content: '\f106';
}
.wn-classification-sport--active .wn-classification-sport__header .text {
	color: #f1cb62;
}

.wn-classification-sport--active .wn-classification-sport__body {
	display: block;
}
.wn-classification-sport__body .ip-competition__country {
	width: 20px;
	height: 20px;
}
.wn-classification-league {
	margin-bottom: 1px;
	display: block;
}
.wn-classification-league__header {
	width: 100%;
	height: 39px;
	padding: 5px 10px 5px 22px;
	position: relative;
	cursor: pointer;
	background-color: #15192c;
}
.wn-classification-league__header.active .text {
	color: #f1cb62;
}
.wn-classification-league__header .count {
	margin-left: auto;
	color: rgba(154, 155, 159, 0.5);
}
.wn-classification-league__header .text {
	margin-right: 10px;
}
.wn-classification-league__header:hover .text {
	color: #f1cb62;
}
.wn-classification-league__header:hover .wn-classification-league__favourite {
	display: block;
}
.wn-classification-league__favourite {
	position: absolute;
	top: 0;
	right: 24px;
	cursor: pointer;
	transform: translateY(-60%);
	display: none;
}
.wn-classification-favourites {
	position: absolute;
	top: 12px;
	left: 8px;
	cursor: pointer;
	/* -webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%); */
	display: block;
}
.wn-classification-favourites:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 12px;
	color: #383a4a;
}
.wn-classification__link:hover .wn-classification-favourites:after {
	color: #f1cb62;
}
.wn-classification-league__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 12px;
	color: #383a4a;
}
.wn-classification-league__favourite--active:after,
.wn-classification-league__favourite:hover:after {
	color: #f1cb62;
}
.wn-classification-league--active .text {
	color: #f1cb62;
}

.wn-classification-region {
	margin-bottom: 1px;
}
.wn-classification-region__header {
	width: 100%;
	height: 39px;
	padding: 5px 10px;
	background-color: #1a1e30;
	position: relative;
	cursor: pointer;
	margin-bottom: 1px;
}
.wn-classification-region__header .count {
	margin-left: auto;
	color: rgba(154, 155, 159, 0.5);
}
.wn-classification-region__header .text {
	margin-right: 10px;
}
.wn-classification-region__header:hover .text {
	color: #fff;
}
.wn-classification-region__body {
	display: none;
}
.wn-classification-region__header .text-ellipsis {
	max-width: 80% !important;
}
.wn-classification-region--active .wn-classification-region__header .text {
	color: #fff;
}
.wn-classification-region--active .wn-classification-region__body {
	display: block;
}

.wn-classification-bar {
	width: 100%;
	height: 44px;
	background-color: #232737;
	margin-top: 1px;
	overflow: hidden;
}
.wn-classification-bar:hover .wn-classification-prev {
	display: flex;
}
.wn-classification-bar:hover .wn-classification-next {
	display: flex;
}
.wn-classification-bar__wrap {
	width: 100%;
	padding: 8px 12px;
	transition: transform 1s;
}
.wn-classification-bar__wrap::-webkit-scrollbar {
	display: none;
}
.wn-classification-bar__item {
	width: auto;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-shrink: 0;
	border-radius: 15px;
	margin-right: 10px;
	padding: 0 10px;
	background-color: #2f3346;
}
.wn-classification-bar__item:hover,
.wn-classification-bar__item--active {
	box-shadow: 0px 0px 6px rgba(9, 13, 18, 0.4);
}
.wn-classification-bar__item:hover .wn-classification-bar__text,
.wn-classification-bar__item--active .wn-classification-bar__text {
	color: #f1cb62;
}
.wn-classification-bar__item:hover .wn-classification-bar__icon svg g,
.wn-classification-bar__item:hover .wn-classification-bar__icon svg rect,
.wn-classification-bar__item:hover .wn-classification-bar__icon svg path,
.wn-classification-bar__item--active .wn-classification-bar__icon svg g,
.wn-classification-bar__item--active .wn-classification-bar__icon svg rect,
.wn-classification-bar__item--active .wn-classification-bar__icon svg path {
	opacity: 1;
	fill: #f1cb62;
}
.wn-classification-bar__text {
	color: #8c8e97;
}
.wn-classification-bar__icon {
	width: 16px;
	height: 16px;
	overflow: hidden;
	margin-right: 5px;
}
.wn-classification-bar__icon svg g,
.wn-classification-bar__icon svg rect,
.wn-classification-bar__icon svg path {
	opacity: 0.5;
	transition: all 0.3s linear;
}

.wn-classification-prev,
.wn-classification-next {
	position: absolute;
	top: 0px;
	z-index: 2;
	cursor: pointer;
	border: none;
	height: 100%;
	padding: 0px 10px;
	background: #1a1e3080;
	color: #8c8e97;
	display: none;
}
.wn-classification-prev {
	left: 0px;
}
.wn-classification-next {
	right: 0px;
}
.wn-classification-prev:hover,
.wn-classification-next:hover {
	color: #f1cb62;
	background: #131625a6;
}
.wn-classification-prev::before,
.wn-classification-next::before {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 25px;
	font-weight: 300;
}
.wn-classification-prev::before {
	content: '\f104';
}
.wn-classification-next::before {
	content: '\f105';
}
.wn-control-bar {
	background: #ffffff;
	border-radius: 0px;
	border-bottom: 1px solid #e1e1e1;
}
.wn-control-bar .ip-control-bar__link span {
	color: #6c90fc;
	font-size: 13px;
	font-weight: 500;
}

.ip-bonus {
	height: 34px;
	background-color: #1a1e30;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}
.ip-bonus__wrap {
	padding: 0 15px 0 5px;
}
.ip-bonus__placeholder {
	padding: 2px 5px;
	background-color: #15b93a;
	margin-right: 8px;
}
.ip-bonus__hr {
	width: 1px;
	height: 16px;
	background-color: #15b93a;
	border: 0;
	margin: 0 8px;
}
.ip-bonus__close {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(45%, -45%);
	background-color: rgba(154, 155, 159, 0.5);
	border-radius: 50%;
	cursor: pointer;
}
.ip-bonus__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate(80%, -10%);
	color: #9a9b9f;
	font-size: 10px;
}

.ipn-control-bar {
	height: 34px;
	border-radius: 2px 2px 0px 0px;
	background-color: #2d334c;
}
.ipn-control-bar__item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 2 1;
	position: relative;
	cursor: pointer;
	height: 100%;
	transition: opacity 0.1s linear 0.2s;
}
.ipn-control-bar__item > * {
	color: #9a9b9f !important;
}
.ipn-control-bar__item > i {
	font-size: 12px;
	color: #9a9b9f;
}
.ipn-control-bar__item:after {
	width: 1px;
	height: 20px;
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background-color: #0c1022;
}
.ipn-control-bar__item:before {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f1cb62;
	transition: all 0.3s linear;
}
.ipn-control-bar__item:last-child {
	flex: 1 1;
}
.ipn-control-bar__item:last-child:after {
	display: none;
}
.ipn-control-bar__item--active > *,
.ipn-control-bar__item:hover > * {
	color: #f1cb62;
}
.ipn-control-bar__item--active:before,
.ipn-control-bar__item:hover:before {
	width: 100%;
}
.ipn-control-bar__item--menu i {
	font-weight: 300;
	font-style: normal;
	font-family: 'Font Awesome 5 Pro';
	transition: all 0.3s linear;
}
.ipn-control-bar__item--menu i:after {
	content: '\f323';
}
.ipn-classification {
	position: relative;
}
.ipn-classification-favourites-wrapper {
}
/*.ipn-favourites-item__header {*/
/*    width: 100%;*/
/*    height: 39px;*/
/*    border-bottom: 1px solid #0C1022;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*    background-color: rgba(45, 51, 76, 0.41);*/
/*    padding: 5px 30px 5px 15px;*/
/*}*/

.ipn-classification__header,
.ipn-favourites-item__header {
	width: 100%;
	height: 39px;
	border-bottom: 1px solid #0c1022;
	position: relative;
	cursor: pointer;
	background-color: rgba(45, 51, 76, 0.41);
	padding: 5px 30px 5px 15px;
	opacity: 1;
}

.ipn-classification__header:hover .ipn-classification__favourite--icon {
	display: block;
}
.ipn-classification__favourite--icon.active {
	display: block;
}
.ipn-favourites-item__header .ipn-classification__favourite--icon {
	display: block;
	top: 20px;
	right: 30px;
}
.ipn-favourites-item__header .ipn-classification__favourite--icon::after {
	color: #f1cb62;
}
.ipn-classification__favourite--icon.active:after,
.ipn-classification__favourite--icon:hover:after {
	color: #f1cb62 !important;
}
.ipn-classification__header:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 5px;
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%);
}
.ipn-classification__header:after,
.ipn-favourites-item__header:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-size: 14px;
	color: #fff;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%) rotate(0deg);
	transition: transform 0.3s linear;
}
.wn-classification-sport--active .ipn-classification__header,.ipn-classification__header:hover {
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%) !important;
}
.ipn-classification__body {
	position: relative;
	background-color: #15192c;
}
.ipn-classification__body.appear,
.ipn-classification-favourites-wrapper__body.appear,
.ipn-classification__body.appear-active,
.ipn-classification-favourites-wrapper__body.appear-active,
.ipn-classification__body.appear-done,
.ipn-classification-favourites-wrapper__body.appear-done {
	max-height: 2000px;
}
.ipn-classification__body.enter,
.ipn-classification-favourites-wrapper__body.enter {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
	overflow: hidden;
}
.ipn-classification__body.enter-active,
.ipn-classification-favourites-wrapper__body.enter-active {
	max-height: 2000px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
	overflow: hidden;
}
.ipn-classification__body.enter-done,
.ipn-classification-favourites-wrapper__body.enter-done {
	max-height: 2000px;
	overflow: visible;
}
.ipn-classification__body.exit,
.ipn-classification-favourites-wrapper__body.exit {
	max-height: 2000px;
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
}
.ipn-classification__body.exit-active,
.ipn-classification-favourites-wrapper__body.exit-active {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
	overflow: hidden;
}
.ipn-classification__favourite--icon {
	position: absolute;
	top: 12px;
	right: 28px;
	cursor: pointer;
	/* -webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%); */
	display: none;
}
.ipn-classification__favourite--icon:after {
	height: 16px;
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 16px;
	color: #383a4a;
}
.aside--small .ipn-classification__favourite--icon:hover:after {
	display: none;
}
.ipn-classification--down .ipn-classification__icon svg {
	fill: #fff !important;
	stroke: #fff !important;
}
.ipn-classification__icon {
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.wn-icon-big {
	width: 24px;
	height: 24px;
}
.ip-classification-sport-icon {
	margin-right: 10px;
}
.ipn-classification__text {
	margin-right: 10px;
}
.ipn-classification__count {
	margin-left: auto;
}
.ipn-classification__hidden {
	display: none;
}
.ipn-classification--down .ipn-classification__header,
.favourites-wrapper__down .ipn-classification__header {
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%);
}
.arrow-up .ipn-classification__header:after,
.favourites-wrapper__down .ipn-classification__header:after {
	transform: translateY(-50%) rotate(180deg);
}
.ipn-classification--down .ipn-classification__icon svg {
	fill: #fff !important;
	stroke: #fff !important;
}
/*.ipn-classification--down .ipn-classification__body {*/
/*    max-height: 1000px;*/
/*    transition: max-height .3s linear;*/
/*}*/
.ipn-classification-favourites-wrapper {
	color: #fdff01;
}
.ipn-classification--american_football {
	color: #629d5b;
}
.ipn-classification--bandy {
	color: #4ed7ff;
}
.ipn-classification--baseball {
	color: #ff8e00;
}
.ipn-classification--basketball {
	color: #ff845c;
}
.ipn-classification--beach_volleyball {
	color: #ffaf3f;
}
.ipn-classification--bowls {
	color: #6488a7;
}
.ipn-classification--boxing {
	color: #ff1515;
}
.ipn-classification--cricket {
	color: #d86308;
}
.ipn-classification--cycling {
	color: #79b35c;
}
.ipn-classification--darts {
	color: #ed4830;
}
.ipn-classification--e-sports {
	color: #c364c5;
}
.ipn-classification--floorball {
	color: #985970;
}
.ipn-classification--formula_1 {
	color: #578986;
}
.ipn-classification--futsal {
	color: #cabb76;
}
.ipn-classification--golf {
	color: #70c448;
}
.ipn-classification--handball {
	color: #d37831;
}
.ipn-classification--ice_hockey {
	color: #00b6ec;
}
.ipn-classification--motorbikes {
	color: #badbad;
}
.ipn-classification--pool {
	color: #b0d437;
}
.ipn-classification--rugby_league {
	color: #d79a7e;
}
.ipn-classification--snooker {
	color: #e5191a;
}
.ipn-classification--soccer {
	color: #30a01a;
}
.ipn-classification--speedway {
	color: #37b0d4;
}
.ipn-classification--table_tennis {
	color: #5e9fde;
}
.ipn-classification--tennis {
	color: #e1e31c;
}
.ipn-classification--volleyball {
	color: #ffaf3d;
}
.ipn-classification--water_polo {
	color: #a6caf0;
}
.aside--small .ipn-classification:hover .ipn-classification__header {
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%) !important;
}

.aside--small .ipn-classification:hover .ipn-classification__icon svg {
	fill: #fff !important;
	stroke: #fff !important;
}

.aside--small .ipn-classification__header {
	justify-content: center;
	padding: 0;
}
.aside--small .ipn-classification__header:after,
.aside--small .ipn-classification__header:before {
	display: none;
}

.aside--small .ipn-classification__icon {
	margin-right: 0;
}
.aside--small .ipn-classification__icon svg {
	fill: currentColor;
	stroke: currentColor;
}

.aside--small .ipn-classification__text,
.aside--small .ipn-classification__count {
	display: none;
}
.aside--small .ipn-classification__body,
.aside--small .ipn-classification-favourites-wrapper__body {
	display: none;
}

.ipn-classification__body--small {
	width: 440px;
	position: absolute;
	left: 10px;
	z-index: 61;
	background: #1a1e30;
	/* max-height: 280px; */
}
.ipn-classification__body--small .ipn-classification__body-inner {
	max-height: 250px;
}
.ipn-classification__body--small .ipn-classification__hidden {
	display: flex;
	align-items: center;
	height: 39px;
	padding: 0 10px;
	background-color: #232737;
	border-bottom: 1px solid #383a4a;
}
.ipn-classification__body--small .ipn-competition__favourite {
	top: 20px;
	right: 25px;
}
.aside--small .ipn-control-bar__item {
	max-width: 0px;
	min-width: 0px;
	opacity: 0;
	overflow: hidden;
}
.aside--small .ipn-control-bar__item--menu {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 100%;
	opacity: 1;
}
.aside--small .ipn-control-bar__item--menu i {
	transform: rotateZ(180deg);
}
.aside--small .ipn-fixture__header {
	height: 32px;
	background-color: rgba(67, 69, 89, 0.3);
	padding: 5px 10px;
	border-bottom: 1px solid #383a4a;
}
.aside--small .ipn-fixture__header:after {
	display: none;
}

.aside--small .ipn-fixture__time {
	display: flex;
	align-items: center;
	border-right: 1px solid #c2d3d4;
}

.aside--small .ipn-fixture__team {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	padding-left: 10px;
	padding-right: 5px;
}

.aside--small .ipn-fixture__score {
	margin-left: auto;
	margin-right: 0;
}
.aside--small .ipn-fixture__score .text-right {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.aside--small .ipn-fixture__score table {
	border-collapse: collapse;
}
.aside--small .ipn-fixture__score table td {
	padding-left: 2px;
}

.aside--small .ipn-fixture__separate {
	display: block;
}

/*.ipn-classification__body--small .ipn-fixture__favourite {*/
/*    display: block;*/
/*    position: relative;*/
/*    -webkit-transform: none;*/
/*    -ms-transform: none;*/
/*    transform: none;*/
/*    margin-left: 10px;*/
/*}*/
/*.aside--small .ipn-fixture__favourite:after {*/
/*    content: '\f005';*/
/*    font-weight: 300;*/
/*    font-size: 14px;*/
/*}*/
/*.aside--small .ipn-fixture__favourite:hover:after, .aside--small .ipn-fixture__favourite--active:after {*/
/*    font-weight: 900;*/
/*}*/

.ipn-competition-enter {
	opacity: 0;
}
.ipn-competition-enter-active {
	opacity: 1;
	transition: opacity 0.1s linear;
}

.ipn-competition-exit {
	opacity: 1;
	max-height: 1000px;
	transition: opacity 0.2s linear,
		max-height 0.3s cubic-bezier(0.15, 0.59, 0.36, 0.93);
}
.ipn-competition-exit-active {
	opacity: 0;
	max-height: 0px;
	transition: opacity 0.2s linear,
		max-height 0.3s cubic-bezier(0.15, 0.59, 0.36, 0.93);
}

.ipn-competition__header {
	width: 100%;
	height: 39px;
	border-bottom: 1px solid #0c1022;
	position: relative;
	cursor: pointer;
	background-color: rgba(45, 51, 76, 0.41);
	padding: 5px 30px 5px 15px;
}
.ipn-competition__header:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-size: 14px;
	color: #9a9b9f;
	position: absolute;
	right: 10px;
	top: 55%;
	transform: translateY(-50%) rotate(0deg);
	transition: transform 0.3s linear;
}
.ipn-competition__header:hover .font {
	color: #fff;
}
.wn-classification-sport__header:hover .ipn-competition__favourite,
.wn-classification-region__header:hover .ipn-competition__favourite,
.wn-classification-league__header:hover .ipn-competition__favourite,
.ipn-competition:hover .ipn-competition__favourite,
.ipn-competition__favourite.active{
	display: block;
}
.simplebar-content>.ipn-competition:hover .ipn-competition__favourite:after{
	position: absolute;
	right: 7px;
	top: -7px;
}
.ipn-competition__body {
	max-height: 0px;
	overflow: hidden;
}
.ipn-competition__body.appear,
.ipn-competition__body.appear-active,
.ipn-competition__body.appear-done {
	max-height: 2000px;
	overflow: visible;
}
.ipn-competition__body.enter {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
}
.ipn-competition__body.enter-active {
	max-height: 2000px;
	transition: max-height 0.3s cubic-bezier(1, 0.09, 0.93, 0.61);
}
.ipn-competition__body.enter-done {
	max-height: 21000px;
	overflow: visible;
}
.ipn-competition__body.exit {
	max-height: 2000px;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
}
.ipn-competition__body.exit-active {
	max-height: 0px;
	transition: max-height 0.3s cubic-bezier(0.08, 0.92, 0.76, 0.9);
}

.ipn-competition__favourite {
	position: absolute;
	top: 12px;
	right: 28px;
	cursor: pointer;
	/* -webkit-transform: translateY(-60%);
	-ms-transform: translateY(-60%);
	transform: translateY(-60%); */
	display: none;
}
.ipn-competition__favourite:after {
	content: '\f005';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	font-size: 14px;
	color: #383a4a;
}
.ipn-competition__favourite--active:after,
.ipn-competition__favourite:hover:after,
.ipn-competition__favourite.active:after {
	color: #f1cb62;
}

.ipn-competition--down .ipn-competition__header:after {
	transform: translateY(-50%) rotate(180deg);
}

/*.ipn-competition--down .ipn-competition__body {*/
/*    max-height: 1000px; */
/*}*/

.ipn-competition__icon {
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	margin-right: 10px;
}

.ipn-competition__text {
	margin-right: 10px;
}
.ipn-competition__empty-message {
	padding: 5px 30px 5px 15px;
	min-height: 35px;
	text-align: center;
}
.ipn-fixture {
	display: block;
}
.ipn-fixture-enter {
	opacity: 0;
}
.ipn-fixture-enter-active {
	opacity: 1;
	transition: opacity 200ms;
}
.ipn-fixture-exit {
	opacity: 1;
}
.ipn-fixture-exit-active {
	opacity: 0;
	transition: opacity 200ms;
}
.ipn-fixture:hover .ipn-fixture__header .font,
.ipn-fixture.active .ipn-fixture__header .font {
	color: #fff;
}

/*.ipn-fixture:hover .ipn-fixture__favourite {*/
/*    display: block;*/
/*}*/

.ipn-fixture__header {
	width: 100%;
	height: 54px;
	border-bottom: 1px solid #0c1022;
	position: relative;
	cursor: pointer;
	padding: 5px 10px;
}

.ipn-fixture__body {
	display: none;
	border-bottom: 1px solid #0c1022;
}

/*.ipn-fixture__favourite {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 5px;*/
/*    cursor: pointer;*/
/*    -webkit-transform: translateY(-60%);*/
/*    -ms-transform: translateY(-60%);*/
/*    transform: translateY(-60%);*/
/*    display: none; }*/
/*.ipn-fixture__favourite:after {*/
/*    content: '\f005';*/
/*    font-family: "Font Awesome 5 Pro";*/
/*    font-weight: 900;*/
/*    font-size: 16px;*/
/*    color: #383A4A; }*/
/*.ipn-fixture__favourite--active:after, .ipn-fixture__favourite:hover:after {*/
/*    color: #F1CB62; }*/

.ipn-fixture__separate {
	display: none;
}

.ipn-fixture__score,
.ipn-fixture__team,
.ipn-fixture__time {
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex-shrink: 0;
	height: 100%;
}

.ipn-fixture__score {
	flex: 1 0 auto;
}

.ipn-fixture__team {
	flex: 1 1 auto;
	padding: 0 2px;
	overflow: hidden;
}

.ipn-fixture__time {
	max-width: 10%;
	width: 100%;
}
.ipn-fixture__time-light {
	font-size: 20px;
	position: absolute;
	right: 0px;
	top: 0px;
	-webkit-animation: time-light 1s infinite;
	        animation: time-light 1s infinite;
}
@-webkit-keyframes time-light {
	0% {
		color: #d7dede;
	}
	100% {
		color: #888888;
	}
}
@keyframes time-light {
	0% {
		color: #d7dede;
	}
	100% {
		color: #888888;
	}
}

.ipn-fixture__row {
	border-bottom: 1px solid #0c1022;
}
.ipn-fixture__row:last-child {
	border-bottom: 0;
}

.ipn-fixture__cell {
	flex: 1 1;
	position: relative;
}
.ipn-fixture__cell:after {
	width: 1px;
	height: 20px;
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background-color: #0c1022;
}
.ipn-fixture__cell:last-child:after {
	display: none;
}

.ipn-fixture__btn {
	background-color: #191e32;
	justify-content: space-between !important;
	padding: 0 8px !important;
}
.ipn-fixture__btn:hover,
.ipn-fixture__btn--active {
	background-color: #222843;
}
.ipn-fixture__btn-lock {
	justify-content: center !important;
	padding: 0 8px !important;
	background-color: #191e32;
	cursor: auto;
}

.ipn-fixture__btn-lock i {
	font-size: 13px;
}

.ipn-fixture__table {
	width: auto;
	margin-right: 0;
	margin-left: auto;
}

.ipe-header {
	width: 100%;
	height: 40px;
	background: #fff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
	position: relative;
	padding: 0 10px;
}
.ipe-header__link {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	height: 100%;
	position: relative;
}
.ipe-header__link:after {
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #6c90fc;
	transition: all 0.3s linear;
}
.ipe-header__link:hover:after,
.ipe-header__link--active:after {
	width: 100%;
}
.ipe-header__icon {
	margin-left: 10px;
	cursor: pointer;
	position: relative;
}
.ipe-header__icon > i {
	font-size: 12px;
}
.ipe-header__icon:hover > .ipe-header__dropdown,
.ipe-header__icon--active > .ipe-header__dropdown {
	display: block;
}
.ipe-header__dropdown {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	width: 260px;
	z-index: 1;
	background-color: #fff;
	border: 1px solid rgba(203, 201, 201, 0.59);
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.125738);
	border-radius: 5px;
	transform: translateY(10px);
}
.ipe-header__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	min-height: 35px;
	border-bottom: 1px solid rgba(154, 155, 159, 0.5);
}
.ipe-header__item:last-child {
	border-bottom: 0;
}
.ipe-header__item.dropdown {
	width: 100%;
	padding: 0;
	flex-direction: column;
	align-items: stretch;
}
.ipe-header__item.dropdown .dropdown__toggle {
	display: flex;
	align-items: center;
	min-height: 35px;
	padding: 0 10px;
}
.ipe-header__item.dropdown .dropdown__toggle:after {
	right: 14px;
	font-size: 18px;
	color: #232737;
}
.ipe-header__item.dropdown .dropdown__menu {
	padding: 0 10px;
	position: static;
	background-color: transparent;
	border-top: 1px solid rgba(154, 155, 159, 0.5);
}
.ipe-header__item.dropdown .dropdown__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 35px;
	padding: 0;
	border-bottom: 1px solid rgba(154, 155, 159, 0.5);
}
.ipe-header__item.dropdown .dropdown__item:last-child {
	border-bottom: 0;
}
.ipe-header__table {
	border-collapse: collapse;
}
.ipe-header__table tr:first-child {
	background-color: #f4f4f4;
}
.ipe-header__table td {
	text-align: center;
	padding: 2px 4px;
}

.market-line {
	cursor: pointer;
	padding: 9px 7px;
	background: #162238;
	border-left: 1px solid darkgoldenrod;
}
.market-line:hover {
	border-radius: 5%;
	border-left: 3px solid darkgoldenrod;
	border-right: 3px solid darkgoldenrod;
}
.market-line:hover .font--grey-300 {
	color: white;
}
/* .wn-classification-sport__header:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 5px;
	background: linear-gradient(
			0deg,
			rgba(56, 58, 74, 0.3),
			rgba(56, 58, 74, 0.3)
		),
		linear-gradient(90deg, currentColor 0%, currentColor 100%);
}
 */

.mybets__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__table {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 10px;
}
.betslip__tr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 5px;
}
.betslip__tr--title:after {
  content: '';
  position: absolute;
  right: 5px;
  top: 50%;
  width: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  font-style: normal;
  font-family: "Font Awesome 5 Pro";
}
.betslip__td {
  display: flex;
  align-items: center;
  padding: 0 5px;
  flex: 1 1;
}
.betslip__tr--title {
  position: relative;
  padding: 0 20px 0 0;
  height: 30px;
  background-color: #F6F8FF;
}
.betslip__id {
  border-radius: 2px;
  margin-right: 10px;
  background-color: #6C90FC;
  padding: 2px 5px;
  color: #fff;
}
.betslip__tr--border {
  position: relative;
}
.betslip__td--big {
  justify-content: space-between;
  width: 100%;
}
.betslip__td--small {
  width: 20%;
}
.betslip__td--grow {
  width: 80%;
}
.betslip__td--big, .betslip__td--small, .betslip__td--grow {
  flex: auto;
}
.betslip__table--down .betslip__tr--title:after {
  content: '\f106';
}
.betslip__table--up .betslip__tr--title:after {
  content: '\f107';
}
.betslip__tr--border:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 5px;
  left: 5px;
  height: 1px;
  background-color: rgba(194, 211, 212, 0.5);
}
.cashout__message.enter-done, .cashout__message.exit {
	height: 40px;
}
.cashout__message {
	display: flex;
	margin: 5px 10px;
	padding: 10px;
	background-color: rgba(241, 203, 98, 0.5);
	z-index: 5;
	height: 0;
	font-size: 15px;
	color: #000;
	align-items: center;
	justify-content: center;
}
.cashout__error {
	background-color: rgba(194, 211, 212, 0.5);
}
.cashout__message.enter {
	height: 0;
	overflow-y: hidden;
}
.cashout__message.enter-active {
	height: 40px;
	transition: height 250ms ease;
}
.cashout__message.enter-done {
	height: 40px;
}
.cashout__message.exit {
	height: 40px;
}
.cashout__message .exit-active {
	height: 0;
	transition: height 300ms ease;
}
.container__wrap .t-block {
	margin-bottom: 5px;
	border-radius: 2px;
	overflow: hidden;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
}
.container__wrap .t-block:last-child {
	margin-bottom: 0;
}
.container__wrap .t-block--down .t-subcategory {
	background-color: #7a7f94;
}
.container__wrap .t-block--down .t-subcategory:after {
	transform: translateY(-50%) rotateZ(180deg);
	color: #fff;
}
.container__wrap .t-block--down .t-subcategory__right .font {
	color: rgba(255, 255, 255, 0.5);
}
.container__wrap .t-block--down .t-table {
	display: block;
}
.container__wrap .t-subcategory {
	height: 40px;
	background-color: #c4cada;
	position: relative;
	padding-right: 20px;
}
.container__wrap .t-subcategory:after {
	content: '\f107';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	color: inherit;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	transition: all 0.3s linear;
}
.container__wrap .t-subcategory__left {
	padding: 0 5px 0 10px;
}
.container__wrap .t-subcategory__right {
	display: flex;
	align-items: center;
	padding: 0 20px 0 5px;
}
.container__wrap .t-subcategory__right .font {
	color: #232737;
}
.container__wrap .t-subcategory__icon {
	position: absolute;
	right: 10px;
}
.container__wrap .t-table {
	display: none;
}
.container__wrap .t-table__tr {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.container__wrap .t-table__tr--sm {
	min-height: 35px;
}
.container__wrap .t-table__tr--md {
	min-height: 52px;
}
/*.container__wrap .   {*/
/*	display: -webkit-box;*/
/*	display: -webkit-flex;*/
/*	display: -ms-flexbox;*/
/*	display: flex;*/
/*	-webkit-box-align: center;*/
/*	-webkit-align-items: center;*/
/*	-ms-flex-align: center;*/
/*	align-items: center;*/
/*	-webkit-box-flex: 1;*/
/*	-webkit-flex: 1;*/
/*	-ms-flex: 1;*/
/*	flex: 1;*/
/*	position: relative;*/
/*	padding: 5px 10px;*/
/*	height: 100%;*/
/*}*/
.container__wrap .t-table__td {
	display: flex;
	align-items: center;
	flex: 1 1;
	position: relative;
	padding: 5px 10px;
	height: 100%;
}
.page .container__wrap .t-table__td--sm {
  flex: 0 1;
  min-width: 60px;
}
.page .container__wrap .t-table__option {
  width: 40px;
  height: 40px;
  background-color: #e1e5ee;
  border: 1px solid #c4cada;
  border-radius: 2px;
}
.container__wrap .t-table__td:after {
	content: '';
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 0;
	width: 1px;
	background-color: rgba(194, 211, 212, 0.5);
}
.container__wrap .t-table__td:last-child:after {
	display: none;
}
.container__wrap .t-table__td--column {
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
	max-width: 98vw;
}
.container__wrap .t-table__button {
	margin: 0 auto;
}
.container__wrap .t-table__button > * {
	margin: 0 2px;
}
.container__wrap .t-table__button.disabled {
	background: #c4cada;
}
.container__wrap .spinner__outer {
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.page .container__wrap .t-table__button {
	background-color: rgba(241, 203, 98, 0.5);
	border-color: #f1cb62;
	margin: 0 auto;
}
.page .container__wrap .t-dropdown {
	display: none;
}
.page .container__wrap .t-dropdown--active {
	display: block;
}
.page .container__wrap .t-tab {
  width: 100%;
}
.page .container__wrap .t-tab__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  border-bottom: 1px solid #e7e7e7;  
}

.page .container__wrap .t-tab__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1 1;
  padding: 0 5px;
  position: relative;
  color: #383a4a;
}
.page .container__wrap .t-tab__link--active {
  color: #6c90fc;
}
.page .container__wrap .t-tab__link:after {
  width: 0;
  height: 2px;
  position: absolute;
  left: 25%;
  right: 0;
  bottom: 0;
  background-color: #6c90fc;
  content: "";
	transition: width .5s;
}
.page .container__wrap .t-tab__link--active:after {
  width: 64px;
}
.page .container__wrap .t-tab__link:hover:after {
  width: 64px;
}
.page .container__wrap .t-tab__body {
  padding: 10px;
}
.container__wrap .spinner {
	width: 35px;
	height: 35px;
}
.t-table .btn .spinner {
	width: 25px;
	height: 25px;
}

.bspa {
	padding: 2px 5px;
	border-radius: 2px;
}
.bspa__open {
	display: none;
	color: #fff;
	background-color: rgba(18, 60, 247, 0.5);
}
.bspa__win {
	border: 1px solid #15b93a;
	background-color: rgba(21, 185, 58, 0.5);
}
.bspa__loose {
	border: 1px solid #b95115;
	color: #b95115;
	/* background-color: rgba(211, 73, 91, 0.5); */
}
.bspa__canceled {
	border: 1px solid #383A4A;
	color: #383A4A;
	/*background-color: rgba(21, 185, 58, 0.5);*/
}
.bspa__cashedout {
	border: 1px solid #f1cb62;
	color: #383A4A;
	background-color: #f1cb62;
}
.bspa__push {
	border: 1px solid #383A4A;
	color: #383A4A;
}
.bspa__canceled {
	border: 1px solid #383a4a5b;
	color: #383A4A;
}
.bspa__halfwin {
	color: #000;
	border: 1px solid #383a4a;
}
.bspa__halfwin  span{
	color: #15b93a;
}
.bspa__halfloose {
	color: #000;
	border: 1px solid #383a4a;
}
.bspa__halfloose span{
	color: #b95115;
}
.lv-translation {
	border-radius: 2px;
	background-color: #232737;
}
.lv-translation__header {
	height: 32px;
	border-bottom: 1px solid #0c1022;
}
.lv-translation__body {
	/* min-height: 325px; */
}
.lv-translation--hide .lv-translation__body {
	display: none;
}
.lv-translation--show .lv-translation__body {
	display: block;
}
.lv-translation__placeholder {
	height: 265px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lv-translation__icon {
	width: 32px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-left: 1px solid #0c1022;
}
.lv-translation__icon .icon {
	font-size: 14px;
	color: #9a9b9f;
}
.lv-translation__icon:first-child {
	border-left: 0;
	border-right: 1px solid #0c1022;
}
.lv-translation__icon:hover .icon,
.lv-translation__icon--active .icon {
	color: #fff;
}
.lv-translation__icon--uncompress .icon:after {
	content: '\f424';
}
.lv-translation__icon--compress .icon:after {
	content: '\f422';
}
.lv-translation__link {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1;
	height: 100%;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.flex .lv-translation__link:nth-child(even) {
	border-right: 1px solid #0c1022;
}
.lv-translation__link:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	background-color: #f1cb62;
	width: 0;
	transition: all 0.3s linear;
}
.lv-translation__link--active,
.lv-translation__link:hover {
	color: #f1cb62;
}
.lv-translation__link--active:after,
.lv-translation__link:hover:after {
	width: 100%;
}
.lv-translation__hr {
	width: 1px;
	height: 16px;
	background-color: #0c1022;
	margin: 0;
	border: 0;
}
.lv-translation .volume--active {
	position: relative;
}
.lv-translation .volume--active .icon {
	color: #fff;
}
.lv-translation .volume__menu {
	/* display: none;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 240px;
    background-color: #fff;
    border-radius: 0 2px 2px 2px; */
	display: none;
	padding: 10px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 240px;
	background-color: #fff;
	border-radius: 0px 2px 2px 2px;
	z-index: 2;
}
/* .lv-translation .volume:hover .volume__menu, .lv-translation .volume--active .volume__menu {
    display: block;
} */
.lv-translation .volume:hover {
	position: relative;
}
.lv-translation .volume__icon {
	color: #9a9b9f;
	width: 24px;
}
.lv-translation .volume__icon:hover {
	color: #6c90fc;
}
.lv-translation .volume__icon--active:after {
	content: '\f6a9';
}
.lv-translation .drop--active .icon:after {
	content: '\f107';
}

.betslip {
	border-radius: 2px;
	background-color: #fff;
}
.betslip__header {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	height: 40px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__link {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 14px;
	height: 100%;
	position: relative;
}
.betslip__link:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: #6c90fc;
	width: 0;
	transition: all 0.5s;
}
.betslip__link:hover,
.betslip__link--active {
	color: #6c90fc;
}
.betslip__link:hover:after,
.betslip__link--active:after {
	width: 100%;
}
.betslip__quickbet {
	display: flex;
	align-items: center;
	padding: 0 10px;
	height: 36px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__quickbet-panel {
	margin-bottom: 10px;
	padding: 10px;
	background: #fff;
	border-radius: 2px;
	border: 1px solid #e7e7e7;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}
.betslip__quickbet-panel hr {
	width: 100%;
	height: 1px;
	display: block;
	border: 0;
	background-color: rgba(194, 211, 212, 0.5);
}
.betslip__quickbet-btn {
	max-width: 30%;
	margin: 5px 0;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}
.betslip__quickbet-btn:hover,
.betslip__quickbet-btn--active {
	background-color: rgba(0, 0, 0, 0.05);
}
.betslip__body {
	position: relative;	
	background-color: #f6f8ff;
	padding: 10px;
}
.betslip__item {
	padding: 14px 10px 10px;
	background: #fff;
	border-radius: 2px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.176546);
	margin: 10px 0;
	overflow: hidden;
	position: relative;
}
.betslip__item:first-child {
	margin-top: 0;
}
.betslip__item:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 2px;
}
.betslip__item--active:before {
	background-color: rgba(252, 49, 100, 0.7);
}
.betslip__item--disabled:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.4);
}
.betslip__item--disabled .betslip__amount:after {
	content: '\f30d';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	font-size: 14px;
	color: rgba(35, 39, 55, 0.5);
}
.betslip__item--disabled .betslip__amount:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: #f9f9f9;
	z-index: 1;
}
.betslip__close {
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	background-color: #f6f8ff;
	border-radius: 50%;
	cursor: pointer;
	z-index: 2;
}
.betslip__close:after {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate(160%, -70%);
	color: #383a4a;
	font-size: 14px;
}
.betslip__multiples {
	background: #fff;
	border: 1px solid #e7e7e7;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	border-radius: 2px;
}
.betslip__multiple {
	padding: 10px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__multiple:last-child {
	border-bottom: 0;
}
.betslip__multiple .betslip__amount {
	width: 90px;
}
.betslip__drop {
	cursor: pointer;
	padding: 0 10px;
	height: 28px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.4);
}
.betslip__drop .fal {
	color: rgba(35, 39, 55, 0.5);
}
.betslip__drop--down .fal:after {
	content: '\f107';
}
.betslip__drop--up .fal:after {
	content: '\f106';
}
.betslip__odds--up {
	color: #15b93a !important;
}
.betslip__odds--down {
	color: #fc3164 !important;
}
.betslip__amount {
	width: 144px;
	height: 28px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f9f9f9;
	border: 1px solid #e7e7e7;
	border-radius: 2px;
	position: relative;
	overflow: hidden;
}
.betslip__amount hr {
	width: 1px;
	height: 16px;
	display: block;
	position: absolute;
	top: 50%;
	right: 34px;
	border: 0;
	transform: translateY(-50%);
	margin: 0;
	background-color: #c2d3d4;
}
.betslip__amount input {
	width: 110px;
	height: 100%;
	text-align: center;
}
.betslip__amount input::-webkit-outer-spin-button,
.betslip__amount input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}
.betslip__amount input[type='number'] {
	-moz-appearance: textfield;
}
.betslip__amount div.button--max {
	cursor: pointer;
	width: 30px;
	text-align: center;
}
.betslip__posible {
	margin-top: 10px;
}
.betslip__accept {
	display: flex;
	align-items: center;
	padding: 8px;
	background: #fff;
	border-radius: 2px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.176546);
	margin: 10px 0;
}
.betslip__message {
	padding: 5px 10px;
}
.betslip__remove-all {
	padding: 5px 10px;
	text-align: left;
	text-decoration: underline;
	color: rgba(35, 39, 55, 0.5);
}
.betslip__remove-all:hover {
	cursor: pointer;
}
.betslip__placebet,
.betslip__apply {
	border-radius: 0px 0px 2px 2px;
}

.betslip__drop--down .fal.fa-angle-right {
	transform: rotate(90deg);
}
.betslip__drop--up .fal.fa-angle-right {
	transform: rotate(-90deg);
}
.betslip__quickbet-panel .betslip__quickbet-btn:hover {
	background-color: #202434;
	color: #fff;
	font-weight: bold;
}
.betslip__quickbet-panel .betslip__quickbet-btn:active {
	background-color: rgba(11, 29, 91, 0.6);
	font-weight: bold;
}
.betslip__quickbet-panel .betslip__quickbet-btn:hover span {
	color: #fff !important;
}
.betslip__quickbet-panel .betslip__quickbet-btn:active span {
	color: #ffff00 !important;
}
.betslip__empty {
	padding: 15px 10px 20px 10px;
}
.betslip__empty .font {
	margin-bottom: 15px;
}
.betslip__empty .font:last-child {
	margin-bottom: 0;
}
.longclick {
	color: rgba(35, 39, 55, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}
.longclick svg {
	color: rgba(35, 39, 55, 0.5);
	width: 50%;
	height: 50%;
}
.longclick svg path {
	fill: #c1cad3;
}
.wrap--authorize {
	display: inline-flex;
	width: 100%;
	margin-bottom: 20px;
	background-color: #f40034;
	border-radius: 3%;
	cursor: pointer;
}
.not-logon--icon {
	width: 30%;
}
.not-logon--icon svg {
	width: 50px;
}
.not-logon--icon svg path {
	fill: #fff;
}
.wrap--authorize .text {
	color: #fff;
	line-height: 50px;
	font-weight: bold;
	font-size: 1rem;
	width: 70%;
}
/* Settings */
.betslip__settings {
	cursor: pointer;
	margin-top: 10px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	border-radius: 2px;
}
.betslip__settings-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 32px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
	background-color: #f6f8ff;
	padding: 0 10px;
}
.betslip__settings-body {
	display: block;
	overflow-y: hidden;
	height: 0;
	background-color: #f1f3f7;
	transition: height 250ms ease-in-out;
}
.betslip__settings--active .betslip__settings-body {
	display: block;
}
.betslip__settings-icon {
	width: 16px;
	height: 16px;
	margin-left: 10px;
}
.betslip__settings-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 33px;
	padding: 0 10px;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}

.settings_slide-enter {
	height: 0;
	overflow-y: hidden;
}
.settings_slide-enter-active {
	height: 99px;
	transition: height 250ms ease;
}
.settings_slide-enter-done {
	height: 99px !important;
}
.settings_slide-exit {
	height: 99px;
}
.settings_slide-exit-active {
	height: 0 !important;
	transition: height 250ms ease-in;
}
.betslip__quickbet-panel {
	height: 0;
	overflow: hidden;
}
.qb_slide-enter {
	height: 0;
	overflow-y: hidden;
}
.qb_slide-enter-active {
	height: 82px;
	transition: height 250ms ease;
}
.qb_slide-enter-done {
	height: 100% !important;
}
.qb_slide-exit {
	height: 82px;
}
.qb_slide-exit-active {
	height: 0 !important;
	transition: height 250ms ease;
}
.b-accept__wrapper {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	background-color: #f6f8ff;
	padding: 10px;
	width: 100%;
}
.b-accept__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 33px;
	flex-shrink: 0;
	border-radius: 2px 2px 0 0;
	background-color: #15b93a;
	padding: 0 10px;
}
.b-accept__remove,
.b-accept__edit {
	color: #fff;
	font-size: 11px;
}
.b-accept__bets {
	border-radius: 0 0 2px 2px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
}
.b-accept__footer {
	margin-top: 5px;
}
.b-accept__bet {
	width: 100%;
	height: 108px;
	border-bottom: 1px dashed rgba(194, 211, 212, 0.5);
	background-color: #fff;
	padding: 10px;
	overflow: hidden;
}
.b-accept__wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.b-accept__cell {
	overflow: hidden;
}
.b-accept__cell--sm {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-left: 5px;
	flex-shrink: 0;
}
.b-accept__odds {
	font-size: 12px;
	color: #6c90fc;
	font-weight: 700;
}
.b-accept__field {
	width: 66px;
	height: 30px;
	-moz-appearance: textfield;
	border: 1px solid #e7e7e7;
	border-radius: 2px;
	background-color: #f9f9f9;
	/* padding: 0 10px; */
	text-align: center;
	color: rgba(56, 58, 74, 0.8);
	font-size: 13px;
	margin-left: 10px;
}
.b-accept__title {
	font-weight: 500;
	min-height: 18px;
}
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	margin: 4px 0;
}
.b-accept__money,
.b-accept__text,
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.b-accept__teams {
	color: #75757c;
	font-size: 11px;
}
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	margin: 4px 0;
}
.b-accept__label {
	font-size: 12px;
}
.b-accept__info {
	display: flex;
	justify-content: space-betwenn;
	align-items: center;
	width: 100%;
	height: 50px;
	position: relative;
	margin-bottom: 5px;
	border-radius: 2px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #fff;
}
.b-accept__info .b-accept__cell {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	flex: 1 1;
	padding: 0 10px;
}
.b-accept__info .b-accept__cell > * {
	margin: 2px 0 0 2px;
}
.b-accept__text {
	color: rgba(56, 58, 74, 0.65);
	font-size: 13px;
}
.b-accept__info .b-accept__cell > * {
	margin: 2px 0 0 2px;
}
.b-accept__info .b-accept__cell::after {
	width: 1px;
	height: 31px;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	background-color: rgba(194, 211, 212, 0.5);
	content: '';
}
.b-accept__money--green {
	color: #15b93a;
}
.b-accept__money {
	font-size: 13px;
}
.b-accept__market--blue {
	color: #6c90fc;
}
.b-accept__market {
	font-size: 12px;
}
.b-accept__title,
.b-accept__market,
.b-accept__teams {
	margin: 4px 0;
}
input,
textarea {
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
}
.b-accept__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 5px;
}
.wrrrap {
	height: 0;
	overflow: hidden;
}
.mult_slide-enter {
	height: 0;
	overflow-y: hidden;
}
/* .mult_slide-enter-done {
	height: 100%;
	overflow-y: hidden;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
} */
.mult_slide-enter-active {
	height: 48px;
	transition: height 250ms ease;
}
.mult_slide-enter-done {
	height: auto;
}
.mult_slide-exit {
	height: 48px;
}
.mult_slide-exit-active {
	overflow: hidden;
	height: 0;
	transition: height 300ms ease;
}
.mult_slide-exit-done {
	overflow: hidden;
	height: 0;
}
.betslip__settled {
	width: 100%;
	height: 36px;
	padding: 0 10px;
	cursor: pointer;
	border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}
.betslip__settled i {
	font-size: 12px;
	margin-left: 10px;
	position: relative;
}
.betslip__settled .myb-dropdown {
	border-radius: 0 0 0 0;
	border-left: 2px solid #b6b6b6;
	border-right: 1px solid #b6b6b6;
	background-color: #fbfbfb;
	overflow: hidden;
	display: none;
	position: absolute;
	left: 0;
	z-index: 4;
}

.betslip .myb-dropdown.dropdown--active {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 24px;
	width: 27%;
}
.myb-dropdown .myb-dropdown__dropitem {
	padding: 5px 5px;
	border-bottom: 1px solid #dadada;
	width: 100%;
}
.betslip__td--small {
	width: 20%;
	position: relative;
}
.betslip__td--border:after {
	content: '';
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 5px;
	width: 1px;
	background-color: rgba(194, 211, 212, 0.5);
}

.quickbet-panel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	/* margin: 0 20px; */
	/* background-color: #f1f3f7; */
	/* border-top: 1px dashed #000; */
}
.quickbet-panel p {
	margin: 0 10px;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.quickbet-panel .input-group {
	margin-right: 20px;
}
.input-group {
	display: flex;
	width: 200px;
	height: 40px;
	align-items: stretch;
	margin-right: 20px;
}
.plus-minus-input {
	align-items: center;
}
.plus-minus-input {
	align-items: center;
}

.plus-minus-input .input-group-field {
	text-align: center;
	/* margin-left: 0.5rem;
	margin-right: 0.5rem; */
	padding: 1rem;
}

.plus-minus-input .input-group-field::-webkit-inner-spin-button,
.plus-minus-input .input-group-field ::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
}

.fa-minus:before {
	content: '\f068';
	font-weight: 500;
	font-size: 18px;
}
.fa-plus:before {
	content: '\f067';
	font-weight: 500;
	font-size: 18px;
}

.button.hollow {
	/* background-color: #f9f9f9; */
	border: 1px solid rgba(0, 0, 0, 0.4) /*#1779ba*/;
	color: rgba(0, 0, 0, 1) /*#1779ba*/;
	border-radius: 2%;
}
/* .button.hollow[data-quantity='minus'] {
	border-radius: 8% 0 0 8%;
}
.button.hollow[data-quantity='plus'] {
	border-radius: 0 8% 8% 0;
} */
.button.hollow,
.button.hollow:hover,
.button.hollow:focus {
	background-color: transparent;
	background-color: #fff;
}
.input-group-label,
.input-group-field,
.input-group-button,
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
	margin: 0;
	white-space: nowrap;
	height: 30px;
	width: 45px;
}
.input-group-button:first-child {
	margin-right: 10px;
}
.input-group-button:last-child {
	margin-left: 10px;
}
.input-group-field {
	border-radius: 0;
	flex: 1 1;
	height: 40px;
	min-width: 0;
	box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.3);
}
.betslip__field__ext {
	width: 70px;
	height: 30px;
	text-align: right;
	color: rgba(0, 0, 0, 0.8);
	/* font-weight: 700; */
	border: 1px solid rgba(0, 0, 0, 0.4);
	/* background-color: #fff; */
}
.plus-minus-input .input-group-field {
	text-align: center;
	/* margin-left: 0.5rem;
	margin-right: 0.5rem; */
	padding: 1rem;
}

.betslip__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: calc(100vh - 241px);
	background-color: rgba(194, 211, 212, 0.3);
	z-index: 10;
}
.betslip__overlay .spinner__outer {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.betslip__overlay-spinner {
	width: 20px;
	height: 20px;
	margin: 0 auto;
	border: 1px solid #393b4f;
	border-left: 1px solid #ffffff;
	border-radius: 50%;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-regular-400.71f36b6c.eot);
  src: url(/static/media/fa-regular-400.71f36b6c.eot?#iefix) format('embedded-opentype'),
    url(/static/media/fa-regular-400.126e3505.woff2) format('woff2'),
    url(/static/media/fa-regular-400.7b842c95.woff) format('woff'),
    url(/static/media/fa-regular-400.6d74740a.ttf) format('truetype'),
    url(/static/media/fa-regular-400.3e1a5f90.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(/static/media/fa-light-300.8389adab.eot);
  src: url(/static/media/fa-light-300.8389adab.eot?#iefix) format('embedded-opentype'),
    url(/static/media/fa-light-300.9ccb6577.woff2) format('woff2'),
    url(/static/media/fa-light-300.d8f8190b.woff) format('woff'),
    url(/static/media/fa-light-300.d58496a4.ttf) format('truetype'),
    url(/static/media/fa-light-300.8919f8ad.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.228fa19b.eot);
  src: url(/static/media/fa-solid-900.228fa19b.eot?#iefix) format('embedded-opentype'),
    url(/static/media/fa-solid-900.dbe8505c.woff2) format('woff2'),
    url(/static/media/fa-solid-900.e4506c4b.woff) format('woff'),
    url(/static/media/fa-solid-900.ba0b120f.ttf) format('truetype'),
    url(/static/media/fa-solid-900.0e1470f2.svg#fontawesome) format('svg');
}

.fal {
  font-weight: 300;
}

.far {
  font-weight: 400;
}

.fal,
.far {
  font-style: normal;
  font-family: 'Font Awesome 5 Pro';
}

.fa,
.fas {
  font-style: normal;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.far {
  font-size: 18px;
  font-style: normal;
}
.far.fa-microphone-alt:after {
  content: '\f3c9';
}

.fas.fa-eye:after {
  content: '\f06e';
}
.fas.fa-sync-alt:after {
  content: '\f2f1';
}
.fas--active.fa-sync-alt:after {
  content: '\f2f1' !important;
  color: darkgreen;
}
.fas--active.fa-sync-alt:hover:after {
  content: '\f2f1' !important;
  color: darkslategrey;
}
.fas.fa-volume:after {
  content: '\f6a8';
}

.fas.fa-star:after {
  content: '\f005';
}

.fal {
  font-size: 18px;
  font-style: normal;
}
.fal.fa-envelope:after {
  content: '\f0e0';
}
.fal.fa-angle-left:after {
  content: '\f104';
}
.fal.fa-angle-right:after {
  content: '\f105';
}
.fal.fa-angle-up:after {
  content: '\f106';
}
.fal.fa-angle-down:after {
  content: '\f107';
}
.fal.fa-search:after {
  content: '\f002';
}
.fal.fa-eye:after {
  content: '\f06e';
}
.fal.fa-redo-alt:after {
  content: '\f2f9';
}
.fal.fa-times:after {
  content: '\f00d';
}
.fal.fa-address-card:after {
  content: '\f2bb';
}
.fal.fa-trash-alt:after {
  content: '\f2ed';
}
.fal.fa-envelope-open:after {
  content: '\f2b6';
}
.fal.fa-copy:after {
  content: '\f0c5';
}
.fal.fa-calendar-alt:after {
  content: '\f073';
}
.fal.fa-external-link-alt:after {
  content: '\f35d';
}
.lv-translation__icon.pinned .fal.fa-thumbtack {
  background-image: url(/static/media/pin_active.fa914ca8.svg);
}
.lv-translation__icon .fal.fa-thumbtack {
  background-image: url(/static/media/pin_not_active.4f874ade.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  /* transform: scale(0.75, 1) rotate(45deg); */
}
.fal.fa-thumbtack:after {
  /*content: '\f08d';*/
}
.fal.fa-play:after {
  content: '\f04b';
}
.fal.fa-play:after {
  content: '\f04b';
  font-size: 11px;
  font-weight: bolder;
}

.fal.fa-trophy:after {
  content: '\f091';
}
.fal.fa-bell:after {
  content: '\f0f3';
}
.fal.fa-tshirt:after {
  content: '\f553';
}
.fal.fa-lock-alt:after {
  content: '\f30d';
}

.c-icon {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex-shrink: 0;
  border: 1px solid transparent;
}
.c-icon--playing {
  width: 15px;
  height: 12px;
  background-image: url(/static/media/nostream.77649f5f.svg);
}
.c-icon--rejected {
  position: absolute;
  margin-left: 2px;
  width: 16px;
  height: 10px;
  background-image: url(/static/media/rejected.a11d2514.svg);
}
.c-icon--no-play {
  width: 18px;
  height: 14px;
  margin: 0 2px;
  background-image: url(/static/media/no-play.7b66e89c.svg);
}
.c-icon--play {
  width: 15px;
  height: 12px;
  background-image: url(/static/media/play.558cf57a.svg);
}

.c-icon--no-anim {
  opacity: 0.5;
}
.c-icon--video, .c-icon--video:hover {
  height: 17px;
  width: 20px;
  margin: 0px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='18' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23189970' stroke='%23fff'/%3E%3Cpath d='M9 10V3l6 3.5z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--video.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='%23B5B5B5'%3E%3Cpath d='M22.788.238H.212v12.524h22.576V.238zm-.762.761V12H.974V.999h21.052z'/%3E%3Cpath d='M8.625 10V3l6.708 3.501z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--1, .c-icon--1:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23347a37' stroke='%23fff' stroke-linecap='square'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M11 1h1.001v3.5H11zM11 8.5h1.001V12H11zM22.029 10h-3.003V3h3.003v1h-2.001v5h2.001zM1.045 2.983l3.003.034-.087 7-3.003-.034.017-1L2.976 9l.054-5-2.002-.017z' opacity='.8'/%3E%3C/g%3E%3Cpath d='M9.5 6.5c0-1.167.885-2 2-2 1.147 0 2 .833 2 2 0 1.042-.885 2-2 2-1.147 0-2-.958-2-2z' stroke='%23d3e2d3'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--1.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23B5B5B5' d='M22.5.5v12H.5V.5z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M11.014 1h1.001v3.5h-1.001zm0 7.5h1.001V12h-1.001zM22.029 10h-3.003V3h3.003v1h-2.001v5h2.001zM1.045 2.983l3.003.034-.087 7-3.003-.034.017-1L2.976 9l.054-5-2.002-.017z'/%3E%3Cpath stroke='%23B5B5B5' d='M9.5 6.5c0-1.167.885-2 2-2 1.147 0 2 .833 2 2 0 1.042-.885 2-2 2-1.147 0-2-.958-2-2z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--5, .c-icon--5:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%235c8026' stroke='%23fff' stroke-linecap='square'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M7.014 6h9v1h-9z' opacity='.5'/%3E%3Cpath d='M11.014 1h1.001v11h-1.001z' opacity='.9'/%3E%3Cpath d='M6.014 1h1.001v11H6.014zM16.014 1h1.001v11h-1.001z' opacity='.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--5.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='square' d='M.5.5h22v12H.5z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M7.014 6h9v1h-9z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M11.014 1h1.001v11h-1.001zm-5 0h1.001v11H6.014zm10 0h1.001v11h-1.001z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--2, .c-icon--2:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23c18c00' stroke='%23fff' stroke-linecap='square'/%3E%3Cpath d='M11 1h1.001v4H11zM11 8h1.001v4H11z' fill='%23fff' opacity='.8'/%3E%3Cg stroke='%23fff'%3E%3Ccircle cx='11.5' cy='6.5' opacity='.9' r='2'/%3E%3Cpath d='M.5 3l2.377.04S6 3.04 6 6.5 2.964 9.942 2.964 9.942L.5 10M22.5 3l-2.377.04S17 3.04 17 6.5s3.036 3.442 3.036 3.442L22.5 10' opacity='.8'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--2.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='square' d='M.5.5h22v12H.5z'/%3E%3Cpath fill='%23B5B5B5' fill-rule='nonzero' d='M11 1h1.001v4H11zm0 7h1.001v4H11z'/%3E%3Cg stroke='%23B5B5B5' transform='translate(0 3)'%3E%3Ccircle cx='11.5' cy='3.5' r='2'/%3E%3Cpath d='M.5 0l2.377.04S6 .04 6 3.5 2.964 6.942 2.964 6.942L.5 7m22-7l-2.377.04S17 .04 17 3.5s3.036 3.442 3.036 3.442L22.5 7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--3, .c-icon--3:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='19' viewBox='0 0 19 19' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5 9.5l9-9.192 9 9.192-9 9.192z' fill='%235e733f'/%3E%3Cg fill='%23eb9a67'%3E%3Cpath d='M3.306 12.499A3.498 3.498 0 0 0 5 9.5a3.498 3.498 0 0 0-1.694-2.999L.308 9.5zM13.992 9.5c0-1.272.678-2.386 1.693-2.999l3 2.999-3 2.999h.316c-1.015-.613-2.01-1.727-2.01-2.999zM9.488 5.05c-1.272 0-2.161-.44-2.774-1.454l2.774-3 2.77 3C11.645 4.61 10.76 5.05 9.488 5.05zM9.487 13.969c-1.272 0-2.162.44-2.775 1.455l2.775 3 2.77-3c-.613-1.016-1.498-1.455-2.77-1.455z'/%3E%3C/g%3E%3Cpath d='M.5 9.5l9-9.192 9 9.192-9 9.192z' stroke='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--3.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='1 0 19 19'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M1.308 9.5L10.5.308 19.692 9.5 10.5 18.692z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M4.306 12.499A3.498 3.498 0 0 0 6 9.5a3.498 3.498 0 0 0-1.694-2.999L1.308 9.5l2.998 2.999zM14.992 9.5c0-1.272.678-2.386 1.693-2.999l3 2.999-3 2.999h.316c-1.015-.613-2.01-1.727-2.01-2.999zm-4.504-4.45c-1.272 0-2.161-.44-2.774-1.454l2.774-3 2.77 3c-.613 1.015-1.498 1.454-2.77 1.454zm-.001 8.919c-1.272 0-2.162.44-2.775 1.455l2.775 3 2.77-3c-.613-1.016-1.498-1.455-2.77-1.455z' opacity='.1'/%3E%3Cpath stroke='%23B5B5B5' d='M1.5 9.5l9-9.192 9 9.192-9 9.192z'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--waterpollo, .c-icon--waterpollo:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke-linecap='square'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%230ea8e6' stroke='%23fff'/%3E%3Cpath d='M11.5 1.5v10' opacity='.9' stroke='%23fff'/%3E%3Cg stroke-dasharray='2'%3E%3Cpath d='M14.5 1.5v10m-6-10v10' opacity='.4' stroke='%23ffe200'/%3E%3Cpath d='M5.5 1.5v10m12-10v10' opacity='.25' stroke='red'/%3E%3C/g%3E%3C/g%3E%3Cg stroke='%23fff'%3E%3Cpath d='M0 4.5h2.5v4H0M23 4.5h-2.5v4H23' opacity='.9'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--waterpollo.not-active {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke-linecap='square'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%230ea8e6' stroke='%23fff'/%3E%3Cpath d='M11.5 1.5v10' opacity='.9' stroke='%23fff'/%3E%3Cg stroke-dasharray='2'%3E%3Cpath d='M14.5 1.5v10m-6-10v10' opacity='.4' stroke='%23ffe200'/%3E%3Cpath d='M5.5 1.5v10m12-10v10' opacity='.25' stroke='red'/%3E%3C/g%3E%3C/g%3E%3Cg stroke='%23fff'%3E%3Cpath d='M0 4.5h2.5v4H0M23 4.5h-2.5v4H23' opacity='.9'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--4, .c-icon--4:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Crect fill='%23aedee6' height='12' rx='3' stroke='%23fff' width='22' x='.5' y='.5'/%3E%3Cg stroke='%23cc8392'%3E%3Ccircle cx='5' cy='4' r='1.5'/%3E%3Ccircle cx='5' cy='9' r='1.5'/%3E%3Ccircle cx='18' cy='4' r='1.5'/%3E%3Ccircle cx='18' cy='9' r='1.5'/%3E%3Cpath d='M11.5 1.5v3m0 4v3'/%3E%3C/g%3E%3Ccircle cx='11.5' cy='6.5' fill='%23aedee6' r='2' stroke='%237697c2'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--4.not-active{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13' viewBox='0 0 23 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23B5B5B5'%3E%3Crect width='22' height='12' x='.5' y='.5' rx='3'/%3E%3Cg transform='translate(3 1)'%3E%3Ccircle cx='2' cy='3' r='1.5'/%3E%3Ccircle cx='2' cy='8' r='1.5'/%3E%3Ccircle cx='15' cy='3' r='1.5'/%3E%3Ccircle cx='15' cy='8' r='1.5'/%3E%3Cpath d='M8.5.5v3m0 4v3'/%3E%3Ccircle cx='8.5' cy='5.5' r='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--23, .c-icon--23:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M.5.5h22v12H.5z' fill='%23b36e60' stroke='%23fff' stroke-linecap='square'/%3E%3Cg fill='%23fff'%3E%3Cpath d='M11.014 1h1.001v11h-1.001z' opacity='.9'/%3E%3Cpath d='M6.014 1h1.001v11H6.014zM16.014 1h1.001v11h-1.001z' opacity='.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--23.not-active{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='13' viewBox='0 0 23 13' width='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M22.5 12.5H.5V.5h22z' stroke='%23b5b5b5'/%3E%3Cpath d='M16 1h1v11h-1zm-5 0h1v11h-1zM6 1h1v11H6z' fill='%23b5b5b5' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--22, .c-icon--22:hover {
  height: 19px;
  width: 21px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='19' viewBox='0 0 19 19' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' fill='%23fff'%3E%3Cpath d='M8.573 8.677l7.071 4.95-2.121 2.12zm0 0l-7.07-4.95 2.12-2.121zm0 0l-4.95 7.07-2.12-2.12zm7.08-5.135L8.5 8.5l5.032-7.08zM8.5 8.5l1.51 8.5h-3zm0 0l8.536-1.35v3zm0 0L7 0h3zm0 0L0 10V7z' fill-rule='evenodd'/%3E%3C/mask%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-3 -3)'%3E%3Ccircle cx='12.5' cy='12.5' fill='%23333' r='9' stroke='%23fff'/%3E%3Ccircle cx='12.5' cy='12.5' r='5' stroke='%23aa212e'/%3E%3Cpath d='M12.5 12.5L14 21h-3zm0 0L11 4h3zm0 0L4 14v-3zm0 0L21 11v3z' fill='%23d5bfaf'/%3E%3Cpath d='M12.622 12.71l4.877-6.967 2.122 2.121-6.962 4.873 6.985 4.89-2.121 2.12-4.893-6.99-.08.057.06-.086-.037-.051zm-.049-.033l-7.07-4.95 2.12-2.121zm0 0l-4.95 7.07-2.12-2.12z' fill='%23d5bfaf'/%3E%3Ccircle cx='12.5' cy='12.5' fill='%23aa212e' r='1' stroke='%23aa212e'/%3E%3Ccircle cx='8.5' cy='8.5' mask='url(%23a)' r='5' stroke='%2307804a' transform='translate(4 4)'/%3E%3Ccircle cx='12.5' cy='12.5' r='8' stroke='%23333'/%3E%3C/g%3E%3C/svg%3E");
}
.c-icon--22.not-active{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-3 -3)'%3E%3Ccircle cx='12.5' cy='12.5' r='9' stroke='%23B5B5B5'/%3E%3Ccircle cx='12.5' cy='12.5' r='5' stroke='%23B5B5B5' opacity='.1'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M12.5 12.5L14 21h-3l1.5-8.5zm0 0L11 4h3l-1.5 8.5zm0 0L4 14v-3l8.5 1.5zm0 0L21 11v3l-8.5-1.5z' opacity='.12'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M12.573 12.677l7.07 4.95-2.12 2.12-4.95-7.07zm0 0l-7.071-4.95 2.121-2.121 4.95 7.07zm0 0l-4.95 7.07-2.121-2.12 7.07-4.95zm-.028-.07l4.95-7.07 2.12 2.121-7.07 4.95z' opacity='.12'/%3E%3Ccircle cx='12.5' cy='12.5' r='1' stroke='%23B5B5B5'/%3E%3C/g%3E%3C/svg%3E");
}

.c-icon-glow:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1a1e30;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}
.c-icon-glow:hover:before {
  transform: scale(1.1);
  box-shadow: 1px 1px 15px #1a1e30;
}
.c-icon-glow {
  color: #cea850;
  box-shadow: 1px 1px 5px #f1cb62; /*#1A1E30; */
  /* background-color: #CEA850; */
}
.c-icon--field {
  width: 17px;
  height: 12px;
  background-image: url(/static/media/field.ab1cd389.svg);
}
.c-icon--dollar {
  width: 18px;
  height: 12px;
  background-image: url(/static/media/dollar.0d47876a.svg);
}
.c-icon--stats {
  width: 12px;
  height: 12px;
  background-image: url(/static/media/stats.b979db93.svg);
}
.c-icon--sort {
  width: 6px;
  height: 12px;
  background-image: url(/static/media/sort.6a71fcc4.svg);
}
.c-icon--free-kick {
  width: 12px;
  height: 12px;
  background-image: url(/static/media/free-kick.06a2c827.svg);
}
.c-icon--goal {
  width: 13px;
  height: 13px;
  background-image: url(/static/media/goal.b7250116.svg);
}
.c-icon--y-card {
  width: 13px;
  height: 13px;
  background-image: url(/static/media/y-card.591fbc86.svg);
}
.c-icon--r-card {
  width: 13px;
  height: 13px;
  background-image: url(/static/media/r-card.dc5e4c6a.svg);
}
.c-icon--corner {
  width: 12px;
  height: 12px;
  background-image: url(/static/media/corner.cd18139c.svg);
}
.c-icon--throw-in {
}
.c-icon--goal-kick {
}
.c-icon--penalty {
  width: 12px;
  height: 12px;
  background-image: url(/static/media/penalty.6bc3e033.svg);
}
.c-icon--substitution {
  width: 12px;
  height: 12px;
  background-image: url(/static/media/substitution.85ab532d.svg);
}
body {
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: none;
  color: #232737;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 13px;
  background-color: #0c1022;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*body::-webkit-scrollbar {*/
/*	width: 8px;*/
/*}*/

/*body::-webkit-scrollbar-corner {*/
/*	background-color: #474747;*/
/*}*/
/*body::-webkit-scrollbar-thumb {*/
/*	-webkit-border-radius: 19px;*/
/*	border-radius: 19px;*/
/*	border: 2px solid transparent;*/
/*	background-color: #888;*/
/*	background-clip: content-box;*/
/*}*/
/*body::-webkit-scrollbar-track {*/
/*	background-color: #474747;*/
/*	border: 1px solid #373737;*/
/*}*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

marquee {
  width: 100%;
}

ul,
li {
  list-style: none;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

input,
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

input[type='button'],
input[type='submit'],
input[type='reset'],
button,
label,
a {
  outline: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
}

td,
tr,
form {
  margin: 0;
  padding: 0;
}

a {
  color: var(--font-color);
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
span,
div,
button {
  outline: none !important;
}

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-row {
  flex-wrap: wrap !important;
  flex-direction: row !important;
}
.gll-market-group__row.flex-row .flex-50:last-child {
  max-width: 50%;
}
.flex-normal {
  -ms-align-items: normal;
  align-items: normal;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-33 {
  flex: 1 1 33% !important;
}
.flex-50 {
  flex: 1 1 50% !important;
}
.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-middle {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.align-baseline {
  align-items: baseline;
}

.align-self-top {
  align-self: flex-start;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-self-middle {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-cursive {
  font-style: italic;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  max-width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.border {
  border: 0 !important;
}

.padding {
  padding: 0 !important;
}
.margin {
  margin: 0 !important;
}

.relative {
  position: relative !important;
}

.full-height {
  height: 100% !important;
}

.half-height {
  height: 50% !important;
}

.height-auto {
  height: auto !important;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.width-auto {
  width: auto !important;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  width: 8px;
}
.scroll::-webkit-scrollbar-corner {
  background-color: #474747;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 19px;
  border: 2px solid transparent;
  background-color: #888;
  background-clip: content-box;
}
.scroll::-webkit-scrollbar-track {
  background-color: #474747;
  border: 1px solid #373737;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-a-5 {
  margin: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-a-10 {
  margin: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-a-15 {
  margin: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-a-20 {
  margin: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-a-30 {
  margin: 30px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-l-5 {
  padding-left: 5px;
}
.p-a-3 {
  padding: 3px;
}
.p-a-5 {
  padding: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-a-10 {
  padding: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-a-15 {
  padding: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-a-20 {
  padding: 20px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-a-30 {
  padding: 30px;
}

body {
  margin: 0 auto;
}

.page {
  width: 100%;
  min-width: 1200px;
}

.main {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.content {
  display: flex;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
  max-height: calc(100vh - 120px);
  overflow: hidden;
}

.live-wrapper {
  width: 100%;
}

/*.content.scheduleWrapper {*/
/*  max-width: calc(100% - 300px);*/
/*}*/
/*.content.overviewWrapper {*/
/*  max-width: calc(100% - 300px);*/
/*}*/

.center {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  min-height: 60vh;
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.overlay--main {
  z-index: 10;
  position: fixed;
  background-color: rgba(31, 33, 51, 0.5);
}
.overlay--additionally {
  z-index: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
}
.overlay--block {
  display: block;
}

.font {
  /*color: #232737;*/
  line-height: 15px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.font--green {
  color: #15b93a;
}
.font--red {
  color: #fc3164;
}
.font--gold {
  color: #f1cb62;
}
.font--blue {
  color: #6c90fc;
}
.font--white {
  color: #fff;
}
.font--grey-500 {
  color: #383a4a;
}
.font--grey-500-alpha-05 {
  color: rgba(56, 58, 74, 0.5);
}
.font--grey-500-alpha-08 {
  color: rgba(56, 58, 74, 0.8);
}
.font--grey-400 {
  color: #545763;
}
.font--grey-300 {
  color: #9a9b9f;
}
.font--default-alpha-05 {
  color: rgba(35, 39, 55, 0.5);
}
.font--white-alpha-05 {
  color: rgba(255, 255, 255, 0.5);
}
.font--medium {
  font-weight: 500;
}
.font--semibold {
  font-weight: 700;
}
.font--10 {
  font-size: 10px;
}
.font--11 {
  font-size: 11px;
}
.font--12 {
  font-size: 12px;
}
.font--14 {
  font-size: 14px;
}
.font--15 {
  font-size: 15px;
}
.font--16 {
  font-size: 16px;
}
.font--17 {
  font-size: 17px;
  line-height: 24px;
}
.font--19 {
  font-size: 19px;
}
.font--20 {
  font-size: 20px;
}
.font--21 {
  font-size: 21px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #232737;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
h1--green,
.h1--green,
h2--green,
.h2--green,
h3--green,
.h3--green,
h4--green,
.h4--green,
h5--green,
.h5--green,
h6--green,
.h6--green {
  color: #15b93a;
}
h1--red,
.h1--red,
h2--red,
.h2--red,
h3--red,
.h3--red,
h4--red,
.h4--red,
h5--red,
.h5--red,
h6--red,
.h6--red {
  color: #fc3164;
}
h1--gold,
.h1--gold,
h2--gold,
.h2--gold,
h3--gold,
.h3--gold,
h4--gold,
.h4--gold,
h5--gold,
.h5--gold,
h6--gold,
.h6--gold {
  color: #f1cb62;
}
h1--blue,
.h1--blue,
h2--blue,
.h2--blue,
h3--blue,
.h3--blue,
h4--blue,
.h4--blue,
h5--blue,
.h5--blue,
h6--blue,
.h6--blue {
  color: #6c90fc;
}
h1--white,
.h1--white,
h2--white,
.h2--white,
h3--white,
.h3--white,
h4--white,
.h4--white,
h5--white,
.h5--white,
h6--white,
.h6--white {
  color: #fff;
}
h1--medium,
.h1--medium,
h2--medium,
.h2--medium,
h3--medium,
.h3--medium,
h4--medium,
.h4--medium,
h5--medium,
.h5--medium,
h6--medium,
.h6--medium {
  font-weight: 500;
}
h1--semibold,
.h1--semibold,
h2--semibold,
.h2--semibold,
h3--semibold,
.h3--semibold,
h4--semibold,
.h4--semibold,
h5--semibold,
.h5--semibold,
h6--semibold,
.h6--semibold {
  font-weight: 700;
}

.h1,
h1 {
  font-size: 55px;
}

.h2,
h2 {
  font-size: 41px;
}

.h3,
h3 {
  font-size: 32px;
}

.h4,
h4 {
  font-size: 30px;
}

.h5,
h5 {
  font-size: 25px;
}

.h6,
h6 {
  font-size: 22px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  border: 0;
  border-radius: 2px;
}
.btn::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.btn::-moz-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.btn:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.btn--h-14 {
  height: 14px;
}
.btn--h-18 {
  height: 18px;
}
.btn--h-22 {
  height: 22px;
}
.btn--h-24 {
  height: 24px;
}
.btn--h-26 {
  height: 26px;
}
.btn--h-28 {
  height: 28px;
}
.btn--h-30 {
  height: 30px;
}
.btn--h-32 {
  height: 32px;
}
.btn--h-36 {
  height: 36px;
}
.btn--h-40 {
  height: 40px;
}
.btn--h-42 {
  height: 42px;
}
.btn--h-44 {
  height: 44px;
}
.btn--green {
  background-color: #15b93a;
}
.btn--green:hover {
  background-color: #1ce549;
}
.btn--red {
  background-color: #fc3164;
}
.btn--red:hover {
  background-color: #fd638a;
}
.btn--gold {
  background-color: #f1cb62;
}
.btn--gold:hover {
  background-color: #f5db91;
}
.btn--blue {
  background-color: #6c90fc;
}
.btn--blue:hover {
  background-color: #9eb6fd;
}
.btn--white {
  background-color: #fff;
}
.btn--white:hover {
  background-color: white;
}
.btn--grey-600 {
  background-color: #2f3346;
}
.btn--grey-600:hover {
  background-color: #434965;
}
.btn > * {
  transition: all 0.3s linear;
}
.btn--outline {
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
}
/* styling a snake */
:root {
  --bg: #3c465c;
  --primary: #78ffcd;
  --solid: #fff;
  --btn-w: 1em;
  --dot-w: calc(var(--btn-w) * 0.2 - 10px);
  --tr-X: 0;
  --tr-Y: 0;
  --half-h: calc(var(--tr-Y) / 2 - 15px);
}
.dot {
  content: '';
  position: absolute;
  top: -10px;
  left: -8px;
  bottom: -10px;
  right: -8px;
  width: calc(1em * 0.2 - 10px);
  width: var(--dot-w);
  height: 100%;
  border-radius: 0;
  transition: all 300ms ease;
  display: none;
  transform-origin: center center;
}
.dot:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0.4em;
  height: 0.9em;
  width: 0.9em;
  background: #6c90fc;
  border-radius: 1em;
  border: 0.25em solid #fff;
  border: 0.25em solid var(--solid);
  box-shadow: 0 0 0.7em #fff, 0 0 2em #78ffcd;
  box-shadow: 0 0 0.7em var(--solid), 0 0 2em var(--primary);
  z-index: 20;
}
.btn--coff.qb:hover .dot:after {
  -webkit-animation: atom 2s infinite linear;
          animation: atom 2s infinite linear;
}
.btn--coff.qb:hover .dot {
  display: block;
}
@-webkit-keyframes atom {
  0% {
    transform: translate(3px, 3px);
  }
  33% {
    transform: translate(0, 3px);
    transform: translate(var(--tr-X), 3px);
  }
  50% {
    transform: translate(0, 0);
    transform: translate(var(--tr-X), var(--tr-Y));
  }
  83% {
    transform: translate(3px, 0);
    transform: translate(3px, var(--tr-Y));
  }
  100% {
    transform: translate(3px, 3px);
  }
}
@keyframes atom {
  0% {
    transform: translate(3px, 3px);
  }
  33% {
    transform: translate(0, 3px);
    transform: translate(var(--tr-X), 3px);
  }
  50% {
    transform: translate(0, 0);
    transform: translate(var(--tr-X), var(--tr-Y));
  }
  83% {
    transform: translate(3px, 0);
    transform: translate(3px, var(--tr-Y));
  }
  100% {
    transform: translate(3px, 3px);
  }
}
/* Spinner */
.btn--coff .spinner {
  display: none;
  position: absolute;
  left: calc(0px - 0);
  left: calc(0px - var(--tr-X));
  top: calc(calc(0 / 2 - 15px) - 0);
  top: calc(var(--half-h) - var(--tr-Y));
  width: calc(0 * 3);
  width: calc(var(--tr-X) * 3);
  height: calc(0 * 3);
  height: calc(var(--tr-Y) * 3);
}
.btn--coff.gll-market-group__btn .spinner,
.btn--coff.gll-market-group__btn--center .spinner {
  left: calc(0px - 0 + 0.2rem);
  left: calc(0px - var(--tr-X) + 0.2rem);
  top: calc(calc(0 / 2 - 15px) - 0 - 0.35rem);
  top: calc(var(--half-h) - var(--tr-Y) - 0.35rem);
}
.gll-market-group__btn-group div.btn--coff {
  border: 0;
}
.btn--coff.qb .spinner {
  overflow: visible;
  display: block;
}
.btn--coff .spinner span {
  position: absolute;
  background-color: #6c90fc;
}
.btn--coff.active .spinner span {
  background-color: lightcyan;
}
.btn--coff .spinner .top-spinner {
  top: calc(0 + 1px);
  top: calc(var(--tr-Y) + 1px);
  left: -3px;
  height: 4px;
  width: 0;
  width: var(--tr-X);
  -webkit-animation: filling-top 2000ms;
          animation: filling-top 2000ms;
}
.btn--coff .spinner .right-spinner {
  top: 1px;
  left: calc(0 * 2 - 7px);
  left: calc(var(--tr-X) * 2 - 7px);
  height: 0;
  height: var(--tr-Y);
  width: 4px;
  -webkit-animation: filling-right 2000ms;
          animation: filling-right 2000ms;
}
.btn--coff .spinner .bottom-spinner {
  top: calc(0 * 2 - 2px);
  top: calc(var(--tr-Y) * 2 - 2px);
  right: 0px;
  height: 4px;
  width: 0;
  width: var(--tr-X);
  -webkit-animation: filling-bottom 2000ms;
          animation: filling-bottom 2000ms;
}
.btn--coff .spinner .left-spinner {
  top: calc(2 * 0 + 2px);
  top: calc(2 * var(--tr-Y) + 2px);
  left: calc(0 - 4px);
  left: calc(var(--tr-X) - 4px);
  height: 0;
  height: var(--tr-Y);
  width: 4px;
  -webkit-animation: filling-left 2000ms;
          animation: filling-left 2000ms;
}
@-webkit-keyframes filling-top {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
  50% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
  83% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
  100% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
}
@keyframes filling-top {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
  50% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
  83% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
  100% {
    transform: translateX(0);
    transform: translateX(var(--tr-X));
  }
}
@-webkit-keyframes filling-right {
  0% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
    transform: translateY(var(--tr-Y));
  }
  83% {
    transform: translateY(0);
    transform: translateY(var(--tr-Y));
  }
  100% {
    transform: translateY(0);
    transform: translateY(var(--tr-Y));
  }
}
@keyframes filling-right {
  0% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
    transform: translateY(var(--tr-Y));
  }
  83% {
    transform: translateY(0);
    transform: translateY(var(--tr-Y));
  }
  100% {
    transform: translateY(0);
    transform: translateY(var(--tr-Y));
  }
}
@-webkit-keyframes filling-bottom {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(0);
  }
  83% {
    transform: translateX(calc(0px - 0));
    transform: translateX(calc(0px - var(--tr-X)));
  }
  100% {
    transform: translateX(calc(0px - 0));
    transform: translateX(calc(0px - var(--tr-X)));
  }
}
@keyframes filling-bottom {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(0);
  }
  83% {
    transform: translateX(calc(0px - 0));
    transform: translateX(calc(0px - var(--tr-X)));
  }
  100% {
    transform: translateX(calc(0px - 0));
    transform: translateX(calc(0px - var(--tr-X)));
  }
}
@-webkit-keyframes filling-left {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  79% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(0px - 0));
    transform: translateY(calc(0px - var(--tr-Y)));
  }
}
@keyframes filling-left {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  79% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(0px - 0));
    transform: translateY(calc(0px - var(--tr-Y)));
  }
}
.btn--coff {
  position: relative;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
}
.gll-market-group__cell .btn--coff {
  border: 0;
  min-height: 40px;
}
.aside--small .ipn-classification__header:hover .ipn-classification__favourite--icon {
  display: none;
}
.btn--coff:hover {
  background-color: #f2f2f2;
}
.btn--up,
.btn--down {
  overflow: hidden;
  position: relative;
}
.btn--up:after,
.btn--down:after {
  width: 8px;
  height: 8px;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%) rotateZ(-45deg);
}
.btn--up:after {
  background-color: #15b93a;
}
.btn--down:after {
  background-color: #fc3164;
}
.btn--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.field {
  font-style: normal;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #232737;
  line-height: 15px;
  text-transform: none;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: 0;
}

.field--error {
  color: red;
}

.field--white {
  height: 28px;
  padding: 2px 0;
  color: #fff;
  border-bottom: 1px solid #b5b7c6;
}

.field::-webkit-input-placeholder {
  color: #b5b7c6;
}
.field::-moz-placeholder {
  color: #b5b7c6;
}
.field:-ms-input-placeholder {
  color: #b5b7c6;
}
.field--main {
  height: 28px;
  padding: 2px 0;
  color: #b5b7c6;
  /* color: #232737; */
  border-bottom: 1px solid #e7e7e7;
}
.field--main::-webkit-input-placeholder {
  color: #b5b7c6;
  /* color: rgba(35, 39, 55, 0.5); */
}
.field--main::-moz-placeholder {
  color: #b5b7c6;
  /* color: rgba(35, 39, 55, 0.5); */
}
.field--main:-ms-input-placeholder {
  color: #b5b7c6;
  /* color: rgba(35, 39, 55, 0.5); */
}

.select {
  font-style: normal;
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  color: var(--font-color);
  line-height: var(--line-height);
  text-transform: none;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(181,183,198,0.5)'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 8px;
  background-position: right center;
  background-repeat: no-repeat;
}
.select::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.select::-moz-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.select:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.select--main {
  height: 28px;
  padding: 2px 0;
  color: #232737;
  border-bottom: 1px solid #e7e7e7;
}
.select--main::-webkit-input-placeholder {
  color: rgba(35, 39, 55, 0.5);
}
.select--main::-moz-placeholder {
  color: rgba(35, 39, 55, 0.5);
}
.select--main:-ms-input-placeholder {
  color: rgba(35, 39, 55, 0.5);
}

.checkbox {
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: relative;
  color: #696a76;
}
.checkbox:before {
  width: 12px;
  height: 12px;
  content: '';
  position: absolute;
  top: 0;
  border: 0;
  background: transparent;
  border: 1px solid rgba(105, 106, 118, 0.5);
  border-radius: 2px;
}
.checkbox:after {
  content: '\2713';
  position: absolute;
  color: #000;
  font-weight: bold;
  font-size: 11px;
  opacity: 0;
  top: 5px;
  left: 5px;
  transform: translate(-50%, -50%);
}
.checkbox:checked:after {
  opacity: 1;
  color: #696a76;
}
.checkbox:focus,
.checkbox:hover {
  border: 0;
  outline: 0;
}
.checkbox--gold {
  color: #f1cb62;
}
.checkbox--gold:before {
  border: 1px solid #f1cb62;
}
.checkbox--gold:after {
  color: #f1cb62;
}
.checkbox--gold:checked:after {
  color: #f1cb62;
}
.checkbox--blue {
  color: #fff;
}
.checkbox--blue:checked:before {
  background-color: #6c90fc;
}
.checkbox--blue:checked:after {
  color: #fff;
}

.dropdown {
  position: relative;
}
.dropdown--active .dropdown__toggle:after {
  transform: translateY(-50%) rotate(180deg);
}
.dropdown--active .dropdown__menu {
  display: block;
}
.dropdown--active {
  position: relative;
  z-index: 4 !important;
}
.dropdown__toggle {
  padding-right: 10px;
  position: relative;
  cursor: pointer;
}
.dropdown__toggle:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: inherit;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}
.dropdown--center {
  right: -6px !important;
  top: 50% !important;
  transform: translate(0, -50%);
}
.dropdown__menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  z-index: 61;
  background-color: #232737;
  min-width: 150px;
}
.h-submenu__dropdown .dropdown__menu.odds {
  min-width: 250px;
}
.dropdown__item {
  display: flex;
  align-items: middle;
  padding: 5px 10px;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 16px;
}
.radio span {
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid rgba(194, 211, 212, 0.5);
}
.radio span:after {
  background-color: #6c90fc;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  display: none;
  content: '';
}
.radio input {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
  opacity: 0;
}
.radio input:checked ~ span:after {
  display: block;
}

.toggle {
  width: 20px;
  height: 12px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2px;
}
.toggle__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(105, 106, 118, 0.3);
}
.toggle__swipe {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 50%;
  border-radius: inherit;
  background-color: #fff;
  border: 1px solid rgba(105, 106, 118, 0.3);
  transition: all 0.3s linear;
}
.toggle__input {
  width: 0;
  height: 0;
  opacity: 0;
}
.toggle__input:checked + .toggle__swipe {
  transform: translateX(100%);
}
.toggle__input:checked ~ .toggle__bg {
  background-color: #6c90fc;
}
.toggle--grey-200 {
  background-color: rgba(216, 216, 216, 0.5);
}
.toggle--grey-200__swipe {
  border: 1px solid rgba(216, 216, 216, 0.5);
}

.range {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.range:focus {
  outline: none;
}
.range::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.range::-webkit-slider-thumb {
  width: 6px;
  height: 12px;
  -webkit-appearance: none;
  background: #fff;
  cursor: pointer;
  margin-top: -4px;
  border-radius: 2px;
  background-color: #6c90fc;
}
.range::-moz-range-thumb {
  width: 6px;
  height: 12px;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
}
.range::-ms-thumb {
  width: 6px;
  height: 12px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #6c90fc;
}
.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #f1f3f7;
  border-radius: 3px;
}
.range::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #f1f3f7;
  border-radius: 3px;
}
.range:focus::-webkit-slider-runnable-track {
  background: #e1e5ee;
}
.range::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.range::-ms-fill-lower,
.range::-ms-fill-upper {
  background: #6c90fc;
  border-radius: 3px;
}
.range:focus::-ms-fill-lower,
.range:focus::-ms-fill-upper {
  background: #6c90fc;
}
.range--big {
  height: 18px;
}
.range--big::-webkit-slider-thumb {
  width: 14px;
  height: 16px;
  margin-top: -6px;
  background-image: url(/static/media/range-block.dfc52938.svg);
  background-color: transparent;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.range--big::-moz-range-thumb {
  width: 14px;
  height: 16px;
  background-color: #fff;
}
.range--big::-ms-thumb {
  width: 14px;
  height: 16px;
  background-color: #fff;
}
.aside {
  max-height: 100%;
}
.aside--wrapper {
  position: relative;
  z-index: 3;
}
.aside--small {
  min-width: 58px !important;
  max-width: 58px !important;
  transition: max-width 0.1s, min-width 0.1s;
  margin-right: 8px;
}
.aside--left {
  min-width: 228px;
  max-width: 228px;
  transition: max-width 0.1s, min-width 0.1s;
  margin-right: 8px;
}

.aside--right {
  min-width: 288px;
  max-width: 288px;
  margin-left: 8px;
  transition-duration: 250ms;
}
.aside--wide {
  transition-duration: 250ms;
  min-width: 464px;
  max-width: 464px;
  margin-left: 8px;
}
.mx-h-100,
.cabinet {
  /* max-height: 100% !important; */
}
.mx-h-200 {
  max-height: 200px;
  overflow: hidden;
}
.mx-h-160 {
  max-height: 159px;
  overflow: hidden;
}
.show-more-btn {
  border-bottom: 1px solid #e7e7e7 !important;
  border-top: 0px;
  height: 35px;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 200;
  color: #727272;
}
.up::after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: inherit;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}
.down::after {
  content: '\f106';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: inherit;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  transition: all 0.3s linear;
}
.mx-w {
  max-width: 155px !important;
}

.height-scroll {
  max-height: calc(100vh - 160px);
}
.height-aside-scroll {
  max-height: calc(100vh - 120px);
}
.social__item {
  margin: 0 auto;
  cursor: pointer;
}
.social__item:first-child {
  margin-left: 0;
}
.social__item:last-child {
  margin-right: 0;
}
.social__link {
  width: 52px;
  height: 26px;
}
.social__link svg g {
  opacity: 0.5;
}
.social__link svg g,
.social__link svg rect,
.social__link svg path {
  transition: all 0.3s linear;
}
.social__link:hover svg g,
.social__link--active svg g {
  opacity: 1;
}
.social__link:hover svg rect,
.social__link--active svg rect {
  stroke: #6c90fc;
}
.social__link:hover svg path,
.social__link--active svg path {
  fill: #6c90fc;
}

.social--blue .social__link svg g {
  opacity: 1;
}

.social--blue .social__link svg rect {
  stroke: #6c90fc;
}

.social--blue .social__link svg path {
  fill: #6c90fc;
}

.social--blue .social__link:hover svg g,
.social--blue .social__link--active svg g {
  opacity: 1;
}

.social--blue .social__link:hover svg rect,
.social--blue .social__link--active svg rect {
  fill: #6c90fc;
}

.social--blue .social__link:hover svg path,
.social--blue .social__link--active svg path {
  fill: #fff;
}

.social--disabled .social__link {
  pointer-events: none;
}

.social--disabled .social__link svg g {
  opacity: 0.5;
}

.container {
  position: relative;
  background: #ffffff;
}
.loader-progress {
  position: absolute;
  z-index: 10;
  top: 1px;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background: #070a1a82;
}
.loader-progress-inner {
  height: 100%;
  background: #6c90fc;
  overflow: hidden;
  -webkit-animation: showProgress linear 0.5s;
          animation: showProgress linear 0.5s;
  transition: width 0.5s linear;
}

.loader-logo {
  background-color: rgba(3, 6, 19, 1);
}
.loader-logo_wrap {
  overflow: hidden;
  transform: scale(0);
  width: 50px;
}

/*Logo Container*/
.logo-loader-appear-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.logo-loader-exit {
  opacity: 1;
}
.logo-loader-appear-done {
  opacity: 1;
}
.logo-loader-exit-active {
  opacity: 0;
  transition: opacity 1000ms 500ms;
}

.logo-loader-appear .loader-logo_wrap {
  transform: scale(1);
  opacity: 1;
  transition: transform 1000ms linear, opacity 1000ms linear;
}
.logo-loader-appear-active .loader-logo_wrap {
  transform: scale(2);
  opacity: 1;
  transition: transform 1000ms linear, opacity 1000ms linear;
}
.logo-loader-appear-done .loader-logo_wrap {
  transform: scale(2);
  opacity: 1;
  width: 160px;
}
.logo-loader-exit-active .loader-logo_wrap {
  transform: scale(2);
  opacity: 0;
  width: 160px;
  transition: width 500ms linear, opacity 500ms 500ms linear;
}

.loader-logo_wrap {
  width: 50px;
  overflow: hidden;
  transform: scale(0);
  -webkit-animation: logoScale 3.5s cubic-bezier(0.48, 0.53, 0.51, 0.99);
          animation: logoScale 3.5s cubic-bezier(0.48, 0.53, 0.51, 0.99);
  transition: width 1s linear;
}
.lv-translation__streaming {
  width: 100% !important;
  height: 325px;
  /* height: 100% !important; */
}
.video-js {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  height: 100% !important;
}

@-webkit-keyframes showProgress {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

@keyframes showProgress {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
.f-33 {
  flex: 1 1 33.3333%;
  border-right: 0.5px solid gainsboro;
  background: #fff;
}
.f-33 .btn {
  border-radius: 0;
}
.ui-popup {
  display: block;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 180px;
  overflow: visible;
  z-index: 11;
}
.ui-popup .flex {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  margin: 0 auto;
}
.ui-popup .flex .btn {
  margin-top: 35px;
  width: 15rem;
}
.abso {
  position: absolute;
  top: 100%;
  right: 0;
  width: 270px;
  height: 320px;
  perspective: 600px;
  z-index: 65;
}
.cube {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  transform: translateZ(-160px);
}
.cube__face {
  position: absolute;
  width: 270px;
  height: 320px;
  background: #333545;
  transition-duration: 1000ms;
  box-shadow: 0px 0px 8px rgba(26, 30, 48, 0.5);
}
.cube__face .h-popup__wrapper {
  position: relative;
  padding: 40px 16px 20px;
}
.cube__face--front {
  transform: translateX(10px) translateY(25px) translateZ(160px) rotateY(0deg);
}
.cube__face--bottom {
  transform: translateX(10px) translateY(175px) translateZ(0px) rotateX(-90deg);
}
.cube__face--bottom h4.error-title {
  color: rgba(255, 255, 255, 0.5);
  margin: 15px auto;
  font-size: 1.4rem;
}
.cube__face--bottom p.error {
  color: white;
  margin: 20px;
  margin-left: 35px;
  font-size: 1.1rem;
}
.cube__face--bottom .btn {
  background: #b64438;
  width: 200px;
  height: 40px;
  color: #fff;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  margin: 30px auto;
  transition: all 200ms;
  text-shadow: 1px 0.5px 0.5px black;
}
.cube__face--bottom .btn:hover {
  box-shadow: 1px 1px black, -0.1em 0 0.4em black;
  text-shadow: none;
}
.cube.show-front {
  transform: translateX(-10px) translateY(-15px) translateZ(-160px) rotateY(0deg);
}
.cube.show-bottom {
  transform: translateX(-10px) translateY(20px) translateZ(-160px) rotateX(90deg);
}
.abso.slideDown {
  -webkit-animation: slideDown 0.5s ease-in-out;
          animation: slideDown 0.5s ease-in-out;
}
@-webkit-keyframes slideDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.highlight-up {
  color: green !important;
}
.highlight-down {
  color: red !important;
}
.simplebar-placeholder {
  max-width: 100%;
  width: auto !important;
}
.ip-competition__country {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.prematch-event {
  overflow-x: hidden;
}
.simplebar-placeholder {
  max-width: 100%;
  width: auto !important;
}

.l-game {
  width: 100%;
  height: 112px;
  display: flex;
  border-radius: 2px;
  background-color: #f6f8ff;
  overflow: hidden;
}

.l-game__left {
  width: 199px;
  height: 100%;
}

.l-game__right {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  flex-grow: 1;
}

.l-game__video {
  width: 100%;
  height: 100%;
  position: relative;
}

.l-game video {
  display: block;
  outline: none;
}

.l-game__play {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.l-game__head {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.l-game__body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 5px;
}

.l-game__icon {
  margin-right: 10px;
}

.l-game__row {
  padding: 2px 0;
}

.l-game__group {
  width: 100%;
  height: 28px;
  display: flex;
  border-radius: 2px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #e7e7e7;
  overflow: hidden;
}

.l-game__btn {
  justify-content: space-between;
  flex: 1 1;
  height: 100%;
  padding: 0 10px;
  border-radius: 0;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
}

.wn-banner--white {
  margin: 1px 0;
  background-color: #fff;
}

.top-league {
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  margin: 2px 0;
}

.top-league__header {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.top-league__headline {
  margin-right: 10px;
}

.top-league__wrap {
  margin-right: 0;
  margin-left: auto;
}

.top-league__icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.7;
  transition: all 0.3s linear;
}

.top-league__icon--active,
.top-league__icon:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}

.top-league__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  cursor: pointer;
  padding: 0 10px;
  background-color: #f6f8ff;
  border-bottom: 1px solid rgba(194, 211, 212, 0.5);
  transition: all 0.3s linear;
  overflow: hidden;
}

.top-league__flag {
  width: 26px;
  height: 16px;
  margin-right: 10px;
}

.top-league__count {
  margin-left: auto;
  margin-right: 0;
}

.s-block {
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  margin: 2px 0;
}

.s-block__header {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.ip-competition {
  width: 100%;
}

.ip-competition__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 57px;
  padding: 0 5px;
  background-color: #fff;
  border-bottom: 1px solid rgba(194, 211, 212, 0.5);
}

.ip-competition__row--header {
  height: 38px;
  background-color: #f1f3f7;
}

.ip-competition__row--header .ip-competition__cell:first-child,
.ip-competition__row--market .ip-competition__cell:first-child {
  justify-content: flex-start;
  margin-right: 5px;
}

.ip-competition--prematch .ip-competition__cell--info {
  max-width: 60%;
}

.ip-competition__cell--info {
  width: 100%;
  max-width: 30%;
  flex: auto;
  overflow: hidden;
  margin-right: 5px;
  padding-right: 10px;
}

.ip-competition__cell {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 3px;
}

.ip-competition__row--market {
  height: 28px;
  background-color: #f6f8ff;
}
.btn--scroll {
  opacity: 0;
  width: 42px;
  height: 42px;
  background-color: #6c90fc;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 8;
}
.btn--scroll.active {
  opacity: 1;
  -webkit-animation: fade 2s linear;
          animation: fade 2s linear;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.btn--scroll * {
  color: #fff;
}
.flipmove-placeholder {
	position: absolute;
	width: 100%;
	opacity: 0;
}
.fade-appear {
	opacity: 0;
}
.fade-appear.fade-appear-active {
	opacity: 1;
	transition: opacity 1000ms ease-in;
}
.fade-exit {
	opacity: 1;
}
.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 1000ms ease-in;
}
.fade-exit-done {
	opacity: 0;
}
.fade-enter {
	opacity: 0.01;
}
.fade-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 1000ms ease-in;
}

